<template>
  <div id="app">
   <!--  <v-map ref="map" /> -->
    <!-- <v-head /> -->
    <router-view class="view"/>
    <!-- <v-nav />
    <v-foot-tab v-if="$route.name=='Village'"/> -->
    <!-- <zCon></zCon> -->
    <!-- <z-main></z-main> -->
  </div>
</template>

<script>
/* import vMap from "./components/map.vue"; */
import vMap from "./components/map25d.vue";
import vHead from "./components/head";
import vNav from "./components/nav";
import vFootTab  from "./components/foor_tab";
import zCon from './views/zhenghe/index'
import zMain from './views/main/index'
export default {
  name: "App",
  components: {
    vMap,
    vHead,
    vNav,
    vFootTab,
    zCon,zMain
  },
};
</script>

<style lang='scss' scope>
html{
  background:#001025;
}
#app{
  width: 100vw;
  
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.hr20{
height: height(20);
}
.img_logo{
  width: 0.86rem;
}
.item_box1{
  width: 2rem;
  opacity: 1;
  background: rgba(96,255,204,0.30);
  border: 1px solid #60ffcc;
  border-radius: 0.1rem;
  padding: height(10) 0.25rem;
/*   font-size: 0.17rem; */
  line-height: height(28);
  .l{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    span{
      color: #ebc825;
      cursor: pointer;
    }
  }
}
.tt1:hover * {
  animation-play-state: paused;
  cursor: pointer;
}
body {
  font-size: 0.12rem;
  position: relative;
}
.pagex{
 position: relative;
}
.pagex .mainbg{
    content: "";
    width: 19.2rem;
    height: 100vh;
    background: url(./assets/bj111.png) no-repeat center/19.2rem 100%;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}
/* body::before {
  content: "";
  width: 2.38rem;
  height: 100vh;
  background: url(./assets/yy1.png) no-repeat center/2.38rem 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
} */
/* body::after {
  content: "";
  width: 2.38rem;
  height: 100vh;
  background: url(./assets/yy2.png) no-repeat center/2.38rem 100%;
  position: fixed;
  top: 0;
  z-index: 50;
  right: 0;
} */
*::-webkit-scrollbar {
  display: none;
}
html {
  font-size: calc(100vw / 1920 * 100);
  width: 19.2rem;
}
.view {
  /* width: 19.2rem;
  height: 10.8rem;*/
  width: 100vw;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 100;
}
.item_title{
    width: 100%;
    height: height(28);
    font-size: 0.16rem;
    font-weight: bold;
    color: #fff;
    padding-left: 0.32rem;
    line-height: height(28);
    border-radius: 5px;
    .look_more{
        font-size: 0.12rem;
        font-weight: normal;
        float: right;
        padding-right: 0.1rem;
        cursor: pointer;
        & span:nth-child(1){
            text-decoration: underline;
        }
    }
}
.goomap-div-icon{
  background: none !important;
  border:none!important;
  outline:none !important;
}
.zhuanquan:hover{
  transition: transform 200ms linear;
  transform: rotate(90deg);
}
</style>
