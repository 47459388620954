<template>
  <div class="header huan">
    <div v-if="$parent.type" @click="$parent.$parent.poptype=0" class="back"></div>
  </div>
</template>
<script>
import yiqunren from '../../../assets/header_top.png'
var css = document.createElement("style");
css.type = "text/css";
css.innerHTML = `
          .header.huan{
            background:url(${yiqunren}) top center/auto 9.81vh;
          }
`;
export default {
   created() {
     if(this.$parent.type){
       document.head.appendChild(css)
     }
   },
   beforeDestroy() {
     if(css.parentElement){
       document.head.removeChild(css)
     }
   },
}
</script>
<style scoped lang="scss">
.header {
  width: 100%;
  height: height(80);
  background: url(../../../assets/126.png) top center/auto height(106);
}
.back{
  cursor: pointer;
  margin-left: 0.2rem;
  width: 0.78rem;
  height: height(80);
  background: url(../../../assets/fanhui.png)no-repeat center center/auto height(30);
}
</style>