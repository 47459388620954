<template>
  <div class="fixed_modal">
    <div class="pop">
      <div class="title">
        预警信息
        <span>{{ info.warm }}</span>
      </div>
      <div class="img_box">
        <div class="map" id="container"></div>
        <div class="nav">
          <span> 健康信息 </span>
        </div>
        <div>
          <div class="txt">老人姓名：{{ info.name }}</div>
          <div class="txt">年龄：{{ info.age }}岁</div>
          <div class="txt">手环编号：{{ info.imei }}</div>
          <div class="txt">
            手机号码：{{ info.phone ? info.phone : "暂无" }}
          </div>
          <div class="txt">
            设备电量：{{ info.power.remaining_power }}%<span
              v-if="info.power.type == 3"
              class="red"
              >（注意：电量不足）</span
            >
          </div>
          <div class="txt">
            当前心率：{{ info.rate.heartrate }}次/分<span
              v-if="
                info.rate.heartrate >= info.rate.theshold_heartrate_h ||
                info.rate.heartrate <= info.rate.theshold_heartrate_l
              "
              class="red"
              >（注意：心率不稳，请立即前往查看）</span
            >
          </div>
          <div class="txt">
            当前血压：{{ info.blood.dbp }}/{{ info.blood.sbp
            }}<span
              v-if="
                info.blood.dbp_l >= info.blood.dbp ||
                info.blood.sbp_h <= info.blood.sbp
              "
              class="red"
              >（注意：血压偏{{
                info.blood.dbp_l >= info.blood.dbp ? "低" : "高"
              }}，请立即前往查看）</span
            >
          </div>
        </div>
      </div>
      <div class="close_btn" @click="$parent.$parent.pop_info = null"></div>
    </div>
  </div>
</template>
<script >
export default {
  props: ["info"],
  data() {
    return {
      option: {},
      map:null
    };
  },
  components: {},
  mounted() {
    this.mapinit(this.info.lon2, this.info.lat2);
  },
  methods: {
    mapinit(lon, lat) {
      this.map = new AMap.Map("container", {
        zoom: 18, //级别
        center: [lon, lat], //中心点坐标
      });
      var cic = this.setCircle(lon,lat)
      this.map.add(cic)
    },
    setCircle(lon, lat) {
      var circle = new AMap.Circle({
        center: new AMap.LngLat(lon, lat), // 圆心位置
        radius: 15, // 圆半径
        fillColor: "red", // 圆形填充颜色
        strokeColor: "#fff", // 描边颜色
        strokeWeight: 2, // 描边宽度
      });
      return circle;
    },
  },
};
</script>
<style lang="scss" scoped>
.fixed_modal {
  width: 4.5rem;
  height: height(548);
  border: solid 1px #142b5f;
  box-sizing: border-box;
  border-radius: 0.1rem;
  padding: 0.05rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 10000;
  .img_box {
    .map {
      width: 100%;
      height: height(161);
      border-radius: 0.1rem;
    }
    .nav {
      width: 100%;
      height: height(44);
      padding-top: height(8);
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      margin-bottom: height(12);
      span {
        line-height: height(34);
        font-size: 0.14rem;
        color: #fff;
        display: inline-block;
        border-bottom: 2px solid #fff;
      }
    }
    & > div {
      font-size: 0;
      video {
        background: chocolate;
        width: 4rem;
        height: height(225);
        border-radius: 0.1rem;
        margin-right: 0.2rem;
      }
      .txt {
        font-size: 0.12rem;
        line-height: height(36);
        color: #fff;
        white-space: nowrap;
        .red {
          color: #f34139;
        }
      }
    }
  }
  .pop {
    width: 100%;
    height: 100%;
    background: #001025;
    border-radius: 0.1rem;
    padding: 0 0.3rem;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.8),
      inset 0px 0px 30px 0px rgba(44, 87, 228, 0.5);
    .close_btn {
      width: 0.14rem;
      height: 0.14rem;
      background: url(../assets/close.png) no-repeat center center / auto
        0.14rem;
      position: absolute;
      top: height(15);
      right: 0.15rem;
    }

    .tag {
      font-size: 0.14rem;
      line-height: height(72);
      color: #fff;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-left: 0.1rem;
    }
    .title {
      font-size: 0.16rem;
      line-height: 1;
      text-align: center;
      color: #fff;
      padding-top: height(10);
      padding-bottom: height(25);
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      span {
        color: #f34139;
        font-size: 0.12rem;
        padding-left: 0.2rem;
        margin-left: 0.2rem;
      }
    }
  }
}
</style>