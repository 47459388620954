import Vue from 'vue'
import Router from 'vue-router'
import chart1 from '../views/charts/chart1'
import chart2 from '../views/charts/chart2'
import Index from '../views/index'
import Village from '../components/maincontent'
import Conformity from '../views/zhenghe/index'
import CMain from '../views/main/index'
Vue.use(Router)

export default new Router({
  routes: [
    /* {
      path: '/Main',
      name: 'Main',
      component: Index
    },{
      path:'/Village',
      name:'Village',
      component:Village
    },{
      path:'/',
      name:'chart1',
      component:chart1
    },{
      path:'/chart2',
      name:'chart2',
      component:chart2
    }, */{
      path:'/',
      name:'con',
      component:Conformity
    },
    {
      path:'/CMain',
      name:'CMain',
      component:CMain,
      props:(route)=>{
        return {
          type:route.query.type||false
        }
      }
    }
  ]
})
