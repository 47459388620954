<template>
  <div class="fixed_modal">
    <div class="pop">
      <div class="title">
        预警信息
        <span :class="info.status|colorc">{{info.status| statusc}}</span>
      </div>
      <div class="img_box">
        <div>
          <img class="imgxx" :src="info.images[0]" alt="">
        </div>
        <div>
          <div class="txt">预警信息：{{info.maincontent}}</div>
        </div>
      </div>
      <div class="close_btn" @click="$parent.$parent.pop_info=null"></div>
    </div>
  </div>
</template>
<script >
export default {
  props:['info'],
  data() {
    return {
      option: {},
    };
  },
  filters:{
    statusc(val){
      return Number(val)==1?'等待处理':Number(val)==2?'正在处理':'已处理'
    },
    colorc(val){
      return Number(val)==1?'warmstatus red':Number(val)==2?'warmstatus yellow':'warmstatus green'
    }
  },
  components: {},
};
</script>
<style lang="scss" scoped>
.fixed_modal {
  width:4.8rem;
  border: solid 1px #142b5f;
  box-sizing: content-box;
  border-radius: 0.1rem;
  padding: 0.05rem;
  position: fixed;
  top:50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 10000;
  .img_box {
    margin-top: height(20);
    align-items: center;
    & > div {
      font-size: 0;
      .imgxx {
        width: 4.1rem;
        height: height(225);
        border-radius: 0.1rem;
        margin-right: 0.2rem;
      }
      .txt {
        margin-top: height(20);
        font-size: 0.14rem;
        line-height: height(50);
        color: #fff;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        .red{
            color: #f34139;
        }
      }
    }
  }
  .pop {
    width: 100%;
    height: 100%;
    background: #001025;
    border-radius: 0.1rem;
    padding: 0 0.3rem;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.8),
      inset 0px 0px 30px 0px rgba(44, 87, 228, 0.5);
    .close_btn {
      width: 0.14rem;
      height: 0.14rem;
      background:  url(../assets/close.png) no-repeat center center /auto 0.14rem;
      position: absolute;
      top: height(15);
      right: 0.15rem;
    }
    .tag {
      font-size: 0.14rem;
      line-height: height(72);
      color: #fff;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-left: 0.1rem;
    }
    .title {
      font-size: 0.18rem;
      line-height: height(42);
      text-align: center;
      color: #fff;
      padding-top: height(10);
      text-align: left;
      .warmstatus {
        font-size: 0.12rem;
        padding-left: 0.2rem;
        margin-left: 0.2rem;
        &.red{
          background: url(../assets/warm.png) no-repeat left/0.16rem auto;
          color: #F34139;
        }
        &.yellow{
          color: yellow;
        }
        &.green{
          color: green;
        }
      }
    }
  }
}
</style>