<template>
  <div class="c_box4">
    <div class="item_title dianshang">
      电商年度销售额 (亿元)
      <span
        class="look_more"
        @click="
          $parent.poptype = 3;
          $parent.show = true;
        "
        ><span>查看详情</span>>></span>
    </div>
    <div class="content">
      <div class="item_c">
        <div class="item_c_title">
          2018年总销售额<span class="item_c_number">{{ data.lastbefore }}</span>
        </div>
      </div>
      <div class="item_c">
        <div class="item_c_title">
          2019年总销售额<span class="item_c_number">{{ data.last }}</span>
        </div>
      </div>
      <div class="item_c">
        <div class="item_c_title">
          2020年总销售额<span class="item_c_number">{{ data.moment }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
 <script>
export default {
  props: ["data"],
};
</script>
  <style lang="scss" scope>
.c_box4 {
  width: 100%;
  height: 100%;
  padding: height(10) 0.1rem;
  font-size: 0;
  .content {
    margin-top: height(20);
    padding: 0 0.1rem;
    white-space: normal;
    font-size: 0;
    .item_c {
      vertical-align: top;
      width: 1.46rem;
      height: height(70);
      display: inline-block;
      background: #001025;
      border: 1px solid #142b5f;
      border-radius: 7px;
      margin-right: 0.1rem;
      margin-bottom: height(8);
      padding: height(12) 0;
      .item_c_title {
        font-size: 0.12rem;
        color: #fff;
        text-align: center;
        font-weight: bold;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .item_c_number {
        font-weight: 700;
        font-size: 0.26rem;
        text-align: center;
        color: #66dc95;
      }
    }
    .item_c:nth-child(2n) {
      margin-right: 0;
    }
  }
}
.item_title.dianshang {
  background: url(../../assets/ds.png) no-repeat left 0.1rem center/14px auto,
    linear-gradient(to left, rgba(9, 33, 87, 0), rgba(9, 33, 87, 1));
}
</style>