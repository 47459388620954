<template>
  <div class="pagex" style="color: #fff">
    <div class="mainbg"></div>
    <div class="sidesM">
      <div class="left_top">
        <z-left-top :data="lefttop" :avgdata="avgincome"></z-left-top>
      </div>
      <div class="left_mid">
        <z-left-mid :data="leftmid"></z-left-mid>
      </div>
      <!-- <div class="left_bottom">
        <z-left-bottom></z-left-bottom>
      </div> -->
    </div>
    <div class="midM">
      <div class="mapx">
        <v-map></v-map>
      </div>
      <div class="newBottom">
        <div class="niuxiaotao" :class="animation_type==1&&'show'"></div>
        <div class="niuxiaotao on" :class="animation_type==2&&'show'"></div>

        <div class="newbtnbox">
          <z-btn
            :jk="jiankong"
            :jc="jiance"
            v-if="rightmid"
            :addrx="rightmid"
            :zz="zhengzhi"
            :sw="shuiweidata"
            :circle="circleList"
            ref="btnclaer"
          ></z-btn>
        </div>
      </div>
     <!--  <v-pop15></v-pop15> -->
      <!-- <div class="zbtnxxx">
        
      </div> -->
      <v-pop16
        v-if="poptype == 16"
        :data16="ageArray"
        :disArray="distributeArray"
        :houseArray="housearray"
        :partyArray="party"
        :zhiyuanArray="zhiyuan"
      ></v-pop16>
      <v-pop17
        v-if="poptype == 17"
        :rubbish="rubbishinfo"
        :edu="edulist"
        :build="buildlist"
        :rule="ruleinfo"
      ></v-pop17>
      <v-pop18
        v-if="poptype == 18"
        :avg="avglist"
        :honor="honorinfo"
        :income="incomelist"
      ></v-pop18>
      <v-pop13 v-if="poptype == 6" :info="circleList" />
      <v-pop19
        v-if="poptype == 19"
        :mapx="mapChinadata"
        :data="rightbottom_copy"
        :saleData="saleDataList"
        :category="categoryList"
        :eut="eutList"
      />
      <v-pop21
        v-if="poptype == 21"
        :mapx="mapChinadata"
        :data="rightbottom_copy"
        :saleData="saleDataList"
        :category="categoryList"
        :eut="eutList"
      />
       <v-pop20 v-if="poptype == 20" :type="true"></v-pop20>
    </div>
    <div class="sidesM">
      <div class="right_top">
        <z-right-top :data="righttop"></z-right-top>
      </div>
      <div class="right_mid">
        <z-right-mid :data="rightmid"></z-right-mid>
      </div>
      <div class="right_bottom">
        <z-right-bottom :data="rightbottom"></z-right-bottom>
      </div>
    </div>
    <z-pop :show.sync="show" model>
      <pop-first :sevendata="leftmid.datalist" v-if="poptype == 2"></pop-first>
      <pop-second
        :party="pop2_party"
        :partysort="pop2_partysort"
        :scoresort="pop2_scoresort"
        :echartdata="pop2_echart"
        :desc="bainiudesc"
        v-if="poptype == 1"
      ></pop-second>
      <pop-thrid
        v-if="poptype == 3"
        :data="rightbottom_copy"
        :hot="pop3_hotdata"
        :sale="pop3_saledata"
        :potential="pop3_potentialdata"
        :mapx="mapChinadata"
      ></pop-thrid>
     
    </z-pop>
  </div>
</template>

<script>
import vPop13 from "../../components/pop13.vue";
import vPop15 from "../../components/pop15.vue";
import vPop16 from "../../components/pop16.vue";
import vPop17 from "../../components/pop17.vue";
import vPop18 from "../../components/pop18.vue";
import vPop19 from "../../components/pop19.vue";
import vPop20 from "../main/index.vue";
import vPop21 from "../../components/pop21.vue";
import vMap from "../../components/map25dcopy.vue";
import zLeftTop from "./left_top";
import zLeftMid from "./left_mid";
import zLeftBottom from "./left_bottom";
import zRightTop from "./right_top";
import zRightMid from "./right_mid";
import zRightBottom from "./right_bottom";
import zBtn from "./btn";
import zPop from "../../components/pop_up";
import PopFirst from "./pop_1";
import PopSecond from "./pop_2";
import PopThrid from "./pop_3";
import {
  Survey,
  Digitpeople,
  Environment,
  Shop,
  get_type,
  Manage,
  culture,
  party_work,
  honor,
  circle,
  assets
} from "../../api/index";
export default {
  components: {
    vMap,
    zLeftTop,
    zLeftMid,
    zLeftBottom,
    zRightTop,
    zRightMid,
    zRightBottom,
    zBtn,
    zPop,
    PopFirst,
    PopSecond,
    PopThrid,
    vPop13,
    // vPop15,
    vPop16,
    vPop17,
    vPop18,
    vPop19,
    vPop20,
    vPop21
  },
  data() {
    return {
      bainiudesc: null,
      obj_1: [],
      obj_2: [],
      obj_3: [],
      obj_4: [],
      poptype: 0,
      mapdata: [],
      jiankong: [],
      jiance: [],
      zhengzhi: [],
      shuiweidata: [],
      ageArray: [],
      distributeArray: [],
      housearray: [],
      party: [],
      zhiyuan: [],
      rubbishinfo: {},
      edulist: [],
      buildlist: [],
      ruleinfo: [],
      avglist: [],
      honorinfo: {},
      incomelist: [],
      circleList: [],
      saleDataList: [],
      categoryList: [],
      eutList: [],
      rightbottomtype_copy: 1,
      show: false,
      avgincome: null,
      pop2_echart: {},
      pop2_party: {},
      pop2_partysort: [],
      pop2_scoresort: [],
      pop3_saledata: [],
      pop3_hotdata: [],
      pop3_potentialdata: [],
      mapChinadata: [],
      lefttop: {
        partymember: "",
        allperson: "",
        bainiuarea: "",
        ECperson: "",
      },
      leftmid: {
        temperature: "",
        humidity: "",
        PM25: "",
        now: "",
        weekday: "",
        datalist: [],
      },
      righttop: {
        lastbefore: "",
        last: "",
        moment: "",
      },
      rightmid: null,
      rightbottom: {},
      animation_type:1,
      rightbottom_copy: {},
    };
  },
  mounted() {
    this.getdata();
    setTimeout(()=>{
      this.animation_type=2;
    },1500)
  },
  watch: {
    rightbottomtype_copy() {
      console.log(this.rightbottomtype_copy);
      var rightbottomobj_copy = {
        zhonglei: this.obj_1.filter(
          (el) => el.type == this.rightbottomtype_copy
        )[0].lists,
        kouwei: this.obj_2.filter(
          (el) => el.type == this.rightbottomtype_copy
        )[0].lists,
        xiaofei: this.obj_3.filter(
          (el) => el.type == this.rightbottomtype_copy
        )[0].lists,
        resou: this.obj_4.filter(
          (el) => el.type == this.rightbottomtype_copy
        )[0].lists,
      };
      this.rightbottom_copy = rightbottomobj_copy;
    },
  },

  methods: {
    getdata() {
      assets({})
      circle({}).then((res) => {
        this.circleList = res.result;
      });
      culture({}).then((res) => {
        this.housearray = res.result.household;
        this.party = res.result.party;
        this.zhiyuan = res.result.zhiyuan;
      });
      party_work({}).then((res) => {
        this.rubbishinfo = res.result.rubbish;
        this.edulist = res.result.edu;
        this.buildlist = res.result.build;
        this.ruleinfo = res.result.rule;
      });
      honor({}).then((res) => {
        this.avglist = res.result.avg;
        this.incomelist = res.result.income;
        this.honorinfo = res.result.honor;
      });
      Survey({}).then((res) => {
        this.lefttop.bainiuarea = res.result.basic.total_area.split("00")[1];
        this.lefttop.allperson = res.result.basic.total_user;
        var arravg = [];
        arravg = res.result.avg_income;
        this.avgincome = arravg;
        var arrzhengzhi = res.result.courtyard.list;
        this.zhengzhi = arrzhengzhi;
        this.bainiudesc = res.result.video.title;
      });
      Digitpeople({}).then((res) => {
        this.lefttop.ECperson = res.result.basic.total_online;
        this.lefttop.partymember = res.result.basic.total_user_member;
        const arr1 = res.result.member;
        const arr2 = res.result.score;
        this.pop2_partysort = arr1;
        this.ageArray = res.result.age;
        this.distributeArray = res.result.distribute;
        this.pop2_scoresort = arr2;
        var pop2obj = {};
        const arrx = res.result.youth.map((el) => {
          return parseInt(el.date);
        });
        const arr3 = res.result.employ.list.map((el) => {
          return parseInt(el.value);
        });
        const arr4 = res.result.youth.map((el) => {
          return parseInt(el.value);
        });
        pop2obj = {
          arrx,
          arr3,
          arr4,
        };
        this.pop2_echart = pop2obj;
      });
      Shop({}).then((res) => {
        var obj = {
          lastbefore: res.result.year_data.before_last,
          last: res.result.year_data.last_year,
          moment: res.result.year_data.this_year,
        };
        this.righttop = obj;
        var numberarr = res.result.top_shop.filter(
          (el) => parseInt(el.turnover) != 0
        );
        this.saleDataList = res.result.sales_data;
        this.categoryList = res.result.category;
        this.eutList = res.result.output;
        this.rightmid = numberarr;
        console.log(this.rightmid, 99999999999);
        this.obj_1 = res.result.zhonglei;
        this.obj_2 = res.result.kouwei;
        this.obj_3 = res.result.xiaofei;
        this.obj_4 = res.result.resou;
        var rightbottomobj_copy = {
          zhonglei: res.result.zhonglei.filter(
            (el) => el.type == this.rightbottomtype_copy
          )[0].lists,
          kouwei: res.result.kouwei.filter(
            (el) => el.type == this.rightbottomtype_copy
          )[0].lists,
          xiaofei: res.result.xiaofei.filter(
            (el) => el.type == this.rightbottomtype_copy
          )[0].lists,
          resou: res.result.resou.filter(
            (el) => el.type == this.rightbottomtype_copy
          )[0].lists,
        };
        this.rightbottom_copy = rightbottomobj_copy;
        var rightbottomobj = {
          zhonglei: res.result.zhonglei.filter(
            (el) => el.type == this.rightbottomtype_copy
          )[0].lists,
          kouwei: res.result.kouwei.filter(
            (el) => el.type == this.rightbottomtype_copy
          )[0].lists,
          xiaofei: res.result.xiaofei.filter(
            (el) => el.type == this.rightbottomtype_copy
          )[0].lists,
          resou: res.result.resou.filter(
            (el) => el.type == this.rightbottomtype_copy
          )[0].lists,
        };
        this.rightbottom = rightbottomobj;
        var arrsale = res.result.sales_data.splice(0, 10);
        this.pop3_saledata = arrsale;
        var arrhot = res.result.rexiao;
        this.pop3_hotdata = arrhot;
        var arrpotential = res.result.qianli;
        this.pop3_potentialdata = arrpotential;
        var arrmapdata = res.result.province.map((el) => {
          el.value = parseFloat(el.value);
          return el;
        });
        // arrmapdata.push({name:'白牛村',value:30})
        this.mapChinadata = arrmapdata;
      });
      Environment({}).then((res) => {
        /* this.leftmid.temperature = res.result.info.temperature;
        this.leftmid.humidity = res.result.info.humidity;
        this.leftmid.PM25 = res.result.info.PM25;
        this.leftmid.now = new Date().getFullYear()+'.'+(new Date().getMonth()+1)+'.'+(new Date().getDate());
        this.leftmid.weekday = new Date().getDay();
        this.leftmid.datalist = res.result.lists;
        this.leftmid.datalist = res.result.lists; */
        var obj = {
          temperature: res.result.info.temperature,
          humidity: res.result.info.humidity,
          PM25: res.result.info.PM25,
          rain_type: res.result.info.rain_type,
          now:
            new Date().getFullYear() +
            "." +
            (new Date().getMonth() + 1) +
            "." +
            new Date().getDate(),
          weekday: new Date().getDay(),
          datalist: res.result.lists,
        };
        var arr = res.result.camera;
        this.jiankong = arr.map((item) => {
          if (item.id == 3) {
            item.url = encodeURIComponent(
              "rtsp://192.168.1.30:9090/dss/monitor/param?cameraid=1000006%240&substream=1"
            );
          }
          if (item.id == 6) {
            item.url = encodeURIComponent(
              "rtsp://192.168.1.30:9090/dss/monitor/param?cameraid=1000007%240&substream=1"
            );
          }
          if (item.id == 7) {
            item.url = encodeURIComponent(
              "rtsp://192.168.1.30:9090/dss/monitor/param?cameraid=1000003%240&substream=1 "
            );
          }
          if (item.id == 8) {
            item.url = encodeURIComponent(
              "rtsp://192.168.1.30:9090/dss/monitor/param?cameraid=1000008%240&substream=1"
            );
          }
          return item;
        });
        var arr2 = res.result.equip;
        this.jiance = arr2;
        var arr3 = res.result.shui;
        this.shuiweidata = arr3;
        this.leftmid = obj;
      });

      Manage({}).then((res) => {
        const obj = res.result.star;
        this.pop2_party = obj;
      });
    },
  },
};
</script>

<style lang="scss" scope>
.pagex {
  padding: height(80) 0.1rem 0.1rem 0.1rem;
  display: flex;
}
.niuxiaotao {
  width: 2.32rem;
  height: height(129);
  animation: a1 0.7s linear 400ms forwards;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  bottom:0;
  left: -0.32rem;
  transform: scale(0);
    z-index: 999;
  &.show{
  transform: scale(1);
  }
  &.on{
    animation: a2 0.5s linear infinite;
  }
}
@keyframes a1 {
  0% {
    background-image: url(./a/1.png);
  }
  16.6% {
    background-image: url(./a/2.png);
  }
  33.2% {
    background-image: url(./a/3.png);
  }
  50% {
    background-image: url(./a/4.png);
  }
  66.6% {
    background-image: url(./a/5.png);
  }
  83.2% {
    background-image: url(./a/6.png);
  }
  100% {
    background-image: url(./a/7.png);
  }
}
@keyframes a2 {
  0% {
    background-image: url(./b/1.png);
  }
  25% {
    background-image: url(./b/2.png);
  }
  50% {
    background-image: url(./b/3.png);
  }
  75% {
    background-image: url(./b/4.png);
  }
  100% {
    background-image: url(./b/5.png);
  }
}

.sidesM {
  width: 3.5rem;
  height: 50vh;
  z-index: 50;
}
.midM {
  flex: 1;
  z-index: 500;
  margin: 0 0.1rem;
  padding-top: height(48);
  position: relative;
}
.mapx {
  width: 100%;
  height: height(860);
  border-radius: 10px;
  border: 1px solid #142b5f;
  overflow: hidden;
}
.left_top {
  width: 100%;
  height: height(603);
  border: 1px solid #142b5f;
  border-radius: 11px;
  margin-bottom: height(10);
}
.left_mid {
  width: 100%;
  height: height(375);
  border: 1px solid #142b5f;
  border-radius: 11px;
  margin-bottom: height(10);
}
.left_bottom {
  width: 100%;
  height: height(368);
  border: 1px solid #142b5f;
  border-radius: 11px;
}
.right_top {
  width: 100%;
  height: height(226);
  border: 1px solid #142b5f;
  border-radius: 11px;
  margin-bottom: height(10);
}
.right_mid {
  width: 100%;
  height: height(250);
  border: 1px solid #142b5f;
  border-radius: 11px;
  margin-bottom: height(10);
}
.right_bottom {
  width: 100%;
  height: height(492);
  border: 1px solid #142b5f;
  border-radius: 11px;
  margin-bottom: height(10);
}
.zbtnxxx {
  width: 100%;
  height: height(40);
  position: absolute;
  bottom: height(30);
  left: 50%;
  transform: translateX(-50%);
  padding: 0 1.2rem;
  z-index: 9999999;
}
.newBottom {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  height: height(80);
  padding: height(10) 0;
  padding-bottom: 0;
  .newbtnbox {
    width: 10rem;
    height: height(70);
    background: url(../../assets/btnbj.png) no-repeat center/auto height(60);
  }
}
</style>