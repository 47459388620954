<template>
  <div id="a1" class="chars" :ageArray="data"></div>
</template>
<script>

var colorList = [
  "#fffb72",
  "#54edfe",
  "#4bf6b8",
  "#f3697b",
];
export default {
  props:['data'],
  data(){
    return{
      ageArray:[]
    }
  },
  computed: {
    option() { 
      return {
        series: [
          {
            type: "pie",
            top: "-8%",
            left: "20%",
            center: ["50%", "60%"],
            radius: ["35%", "55%"],
            roseType: "",
            itemStyle: {
              normal: {
                color: function (params) {
                  return colorList[params.dataIndex];
                },
              },
            },
            label: {
              show: true,
              position: "outside",
              formatter:function(params){
                  return '{a|'+params.value+'%}\n{b|'+params.name+'}'
              },
              rich:{
                a:{
                  fontSize:24,
                  fontWeight:'bold',
                },
                b:{
                  fontSize:14,
                  fontWeight:'bold',
                }
              }
            },
            labelLine: {
              length: 10,
              length2: 30,
              smooth: false,
            },
            data: this.ageArray,
          },
        ],
      };
    },
  },
  mounted() {
    this.myChart = echarts.init(document.getElementById("a1"));
    this.myChart.setOption(this.option);
    window.addEventListener("resize", () => {
      console.log("窗口发生变化");
      this.myChart.resize();
    });
   /*  this.datasetval(); */
  },
  updated() {
    this.ageArray = this.data;
    var sum =0;
    this.ageArray.forEach(el => {
      sum = el.value+sum;
    });
    this.ageArray.forEach(el => {
      el.value = (el.value/sum).toFixed(1)*100
    });
    this.myChart.setOption(this.option);
  },
   methods:{
    datasetval(){
      
      let  index =-1;
      var timer = setInterval(()=>{
        // 取消高亮指定的数据图形
    this.myChart.dispatchAction({
        type: 'downplay',
        seriesIndex: 0,
        dataIndex: index==3?index-1:index
    });
    // 高亮指定的数据图形
    this.myChart.dispatchAction({
        type: 'highlight',
        seriesIndex: 0,
        dataIndex: index+1==3?0:index+1
    });
    index++;
    if (index >= 3 ) {
        index = 0;
    }
      },2000)
    }
  },
};
</script>
<style  scoped>
.chars {
  width: 100%;
  height: 100%;
}
</style>
