<template>
  <div class="bg-box">
    <div class="form-box">
      <div class="delBtn zhuanquan" @click="$parent.$parent.show=false"></div>
      <div class="f-title">综合环境监测数据系统</div>
      <div class="f-charts">
        <div class="f-chart">
          <div class="c-title">水位近七日数据变化</div>
          <div class="c_echart">
            <v-echart :option="optionlevel"></v-echart>
          </div>
        </div>
        <div class="f-chart">
          <div class="c-title">PM2.5近七日数据变化</div>
          <div class="c_echart">
            <v-echart :option="optionpm"></v-echart>
          </div>
        </div>
        <div class="f-chart">
          <div class="c-title">温度近七日数据变化</div>
          <div class="c_echart">
            <v-echart :option="optiontemperature"></v-echart>
          </div>
        </div>
        <div class="f-chart">
          <div class="c-title">湿度近七日数据变化</div>
          <div class="c_echart">
            <v-echart :option="optionhumidity"></v-echart>
          </div>
        </div>
        <div class="f-chart">
          <div class="c-title">雨量近七日数据变化</div>
          <div class="c_echart">
            <v-echart :option="optionrain"></v-echart>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
var str = "";
export default {
  props: ["sevendata"],
  data() {
    return {
      optionlevel: null,
      optionpm: null,
      optiontemperature: null,
      optionhumidity: null,
      optionrain: null,
      xdata: [],
      ydata: [],
    };
  },
  mounted() {
    this.optionlevel = this.setoptionformat(1);
    this.optionpm = this.setoptionformat(2);
    this.optiontemperature = this.setoptionformat(3);
    this.optionhumidity = this.setoptionformat(4);
    this.optionrain = this.setoptionformat(5);
  },
  methods: {
    setxdata() {
      var arr = [];
      this.sevendata.map((el) => {
        arr.push(el.date);
      });
      this.xdata = arr;
    },
    setdataformat(x) {
      var arr = [];
      this.sevendata.map((el) => {
        if (x == 1) {
          arr.push(el.avg_level);
        }
        if (x == 2) {
          arr.push(el.avg_pm25);
        }
        if (x == 3) {
          arr.push(el.avg_temperature);
        }
        if (x == 4) {
          arr.push(el.avg_humidity);
        }
        if (x == 5) {
          arr.push(el.avg_rainfall);
        }
      });
      this.ydata = arr;
      console.log(this.ydata);
    },
    setoptionformat(v) {
      str =
        v == 1
          ? "m"
          : v == 2
          ? "μg/m³"
          : v == 3
          ? "℃"
          : v == 4
          ? "%"
          : "mm" ;
      this.setxdata();
      this.setdataformat(v);

      var op = {
        xAxis: {
          type: "category",
          splitLine: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              width: 1,
              color: "#3c4356",
            },
          },
          axisTick: {
            show: false,
            alignWithLabel: false,
            lineStyle: {
              width: 3,
            },
          },
          axisLabel: {
            textStyle: {
              color: "#fff",
              fontSize: 12,
              fontWeight: "bold",
            },
          },
          data: this.xdata,
        },
        grid: {
          top: "18%",
          left: "2%",
          right: "4%",
          bottom: "0%",
          containLabel: true,
        },
        yAxis: {
          type: "value",
          name:str,
          nameTextStyle: {
            color: "#fff",
          },
          splitLine: {
            show: false,
          },
          axisLine: {
            show: false,
            lineStyle: {
              width: 3,
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            /* formatter:(v)=> {
              console.log()
              var str = 
              return v +str; 
            }, */
            color: "#fff",
            fontWeight: "bold",
          },
        },
        series: [
          {
            type: "line",
            symbol: "circle",
            symbolSize: 10,
            smooth: true,
            animationDuration: 2000,
            itemStyle: {
              normal: {
                color: "#fff",
                borderColor: "#2165F9",
                borderWidth: 2,
              },
            },
            lineStyle: {
              normal: {
                color: "#2165F9",
                width: 3,
                shadowColor: "rgba(0,0,0,0.4)",
                shadowBlur: 10,
                shadowOffsetX: 4,
                shadowOffsetY: 10,
              },
            },
            data: this.ydata,
          },
        ],
      };
      return op;
    },
  },
};
</script>

<style lang="scss" scoped>
div {
  box-sizing: border-box;
  line-height: 1;
}
.bg-box {
  height: 100vh;
  background: rgba($color: #000000, $alpha: 0.8);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  .form-box {
    padding: 0.35rem 0.5rem 0 0.55rem;
    width: 13.1rem;
    height: 9.7rem;
    opacity: 1;
    border-radius: 0.11rem;
    position: relative;
    overflow: hidden;
    background: url(../../assets/jcbj.png) no-repeat center center / cover;
    .delBtn {
      position: absolute;
      width: 0.4rem;
      height: 0.4rem;
      background: url(../../assets/close.png) no-repeat center center /0.22rem 0.22rem;
      top: 0.15rem;
      right: 0.25rem;
      cursor: pointer;
    }
    .f-title {
      font-size: 0.24rem;
      text-align: center;
      color: #85a4e8;;
      margin-bottom: 0.59rem;
      font-weight: 500;
    }
    .c-title {
      font-size: 0.18rem;
      font-weight: 500;
      color: #ffffff;
      display: flex;
      align-items: center;
      &:before {
        display: block;
        content: "";
        width: 0.04rem;
        height: 0.16rem;
        background: #66dc95;
        border-radius: 2px;
        margin-right: 0.09rem;
      }
    }
    .c_echart {
      width: 100%;
      height: height(220);
    }
    .f-charts {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0 0.55rem;
      .f-chart {
        width: 5rem;
        height: 2.5rem;
        margin-bottom: 0.33rem;
      }
    }
  }
}
</style>
