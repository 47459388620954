<template>
  <div class="navbox">
    <div :class="['navitem',$route.name=='chart1'&&'on'] " @click="$router.push('/')">电商数据</div>
    <div :class="['navitem',$route.name=='Village'&&'on'] " @click="$router.push('/Village')">数字村民</div>
    <!-- <div :class="['navitem',$route.name=='chart2'&&'on'] "  @click="$router.push('/chart2')">旅游数据</div> -->
   <!--  <div :class="['navitem',$route.name=='Main'&&'on'] "  @click="$router.push('/Main')">数字资产</div> -->
  </div>
</template>

<script>
export default {
  created() {
    console.log(this.$route)
  },
};
</script>
<style lang="scss">
.navbox {
  width: 3.5rem;
  height: 0.8rem;
  background-color: #0b132d;
  border-radius: 10px;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0.3rem;
  display: flex;
  padding: 0.1rem;
  justify-content: space-around;
  align-items: center;
  z-index: 1000;
}
.navitem {
  font-size: 0.18rem;
  color: #fff;
  flex: 1;
  height: 0.6rem;
  line-height: 0.6rem;
  padding-left: 0.6rem;
  cursor: pointer;
  &:nth-child(1) {
    background: url(../assets/dssj.png) no-repeat left 0.3rem center/auto
      0.18rem;
  }
  &:nth-child(2) {
    background: url(../assets/szcm.png) no-repeat left 0.3rem center/auto
      0.18rem;
  }
  &:nth-child(3) {
    background: url(../assets/lysj.png) no-repeat left 0.3rem center/auto
      0.18rem;
  }
  &:nth-child(4) {
    background: url(../assets/szzc.png) no-repeat left 0.3rem center/auto
      0.18rem;
  }
  &.on {
    background-color: #0e1b46;
    border-radius: 0.1rem;
  }
}
</style>