/* import j_data from '../data/jidi.json' */
import axios from "../../../utils/request"
var dataFormart = function(val){
    var arr = [];
    val.map(el=>{
        var a = {
            fromName: '合肥',
            toName: '上海',
            count: '1',
            amount: '89999',
            coords: [
                [119.212, 30.257],
                el.value
            ]
        }
        arr.push(a)
    });
    return arr;
}
var option = {
    // tooltip: {
    //     triggerOn: "onmousemove",
    // },
    // tooltip: {
    //     backgroundColor:"rgba(0,0,0,0)",
    //     trigger: "item",
    //     position:"top",
    //     confine: true,
    //     enterable: true,
    //     renderMode: "html",
    //     appendToBody: true,
    //     formatter: function(){
    //       return `<div class="item_box1">
    //        <div class="l">主播姓名：${d.name}</div>
    //        <div class="l" onclick='showTag(1,${JSON.stringify(d)})'>详细介绍：<span>点击查看</span></div>
    //        <div class="l" onclick='showTag(2,a)'>视频连接：<span>点击查看</span></div>
    //       </div>`
    //     }
    // },
    geo: {
        map: 'china',
        label: {
            show: false
        },
        itemStyle: {
            normal: {
                areaColor: 'rgba(16,23,59,0.6)',
                borderWidth: 0.8, //设置外层边框
                borderColor: '#1a5ac1',
            }
        },
        emphasis: {
            label: {
                show: false,
            }
        }

    },
    series: [
        {
            name: 'outsideborder',
            map: 'china',
            type: 'map',
            roam: true,
            show: true,
            //调整以下3个配置项与页面地图重合
            // aspectScale: 1,			//echarts地图的长宽比（就是胖瘦）
            center: [104.29, 35.8], //设置可见中心坐标，以此坐标来放大和缩小
            // zoom: 1, //放大级别
            tooltip: {
                show: false
            },
            label: {
                show: false
            },
            roam: false,
            itemStyle: {
                normal: {
                    areaColor: 'rgba(20,30,64,0)',
                    borderWidth: 1.6, //设置外层边框
                    borderColor: 'rgba(16,23,59,0.6)',
                    shadowBlur: 1,
                    shadowColor: '#4682B4',
                    shadowOffsetY: 1,
                },
                emphasis: {
                  areaColor: 'rgba(20,30,64,0)',

                    borderWidth: 1.6, //设置外层边框
                    borderColor: 'rgba(16,23,59,0.6)',
                    shadowBlur: 1,
                    shadowColor: '#4682B4',
                    shadowOffsetY: 1,
                }
            },
            emphasis: {
                label: {
                    show: false,
                }
            }
        },
        {
            type: 'effectScatter',
            coordinateSystem: 'geo',
            showEffectOn: 'render',
            rippleEffect: {
                period: 15,
                scale: 2,
                brushType: 'fill'
            },
            hoverAnimation: true,
            symbolSize:20,
            itemStyle: {
                normal: {
                    color: '#e01e2a',
                    shadowBlur: 2,
                    shadowColor: '#333'
                }
            },
            data: [            {
                name: '白牛',
                value: [119.712, 30.227]
            }]
        },
        {
            type: 'effectScatter',
            coordinateSystem: 'geo',
            showEffectOn: 'render',
            symbolSize:20,
            zlevel:10,
            rippleEffect: {
                period: 15,
                scale: 2,
                brushType: 'fill'
            },
            hoverAnimation: true,
            itemStyle: {
                normal: {
                    color: '#60ffcc',
                    shadowBlur: 2,
                    shadowColor: '#333'
                }
            },
            data: j_data.data.list,
            label:{
                show:true,
                position: 'top',
                color:"#fff",
                formatter: function(d){
                    return `${d.name}`
                  }
            }
        },
        {
            name: '白条',
            type: 'lines',
            zlevel: 2,
            tooltip: {
                formatter: function(param) {
                    return param.data.fromName + '->' + param.data.toName + '<br>订单数：' + param.data.count + ' 条<br>订单总金额：' + parseFloat(param.data.amount).toFixed(2) + ' 元'
                }
            },
            effect: {
                show: true,
                period: 3, //箭头指向速度，值越小速度越快
                trailLength: 0.1, //特效尾迹长度[0,1]值越大，尾迹越长重
                symbol: 'arrow', //箭头图标
                symbolSize: 5, //图标大小
            },
            lineStyle: {
                normal: {
                    color: {
                        type: 'linear',
                        x: 1,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [{
                            offset: 0,
                            color: '#236ce6' // 0% 处的颜色
                        }],
                        global: false // 缺省为 false
                    },
                    width: 2, //线条宽度
                    opacity: 0.1, //尾迹线条透明度
                    curveness: .3 //尾迹线条曲直度
                },
                emphasis: {
                    width: 3,
                    opacity: 0.5,
                }
            },
            data: dataFormart(j_data.data.list)
        },
       
    ]
};
export default option;