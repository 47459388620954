<template>
  <div class="bg-box">
    <div class="fixed_modal">
      <div class="pop">
        <div class="title">党员先锋指数</div>
        <!-- <div class="tag">党员姓名：{{info.villager_name}} 党员类型：正式党员</div> -->
        <div class="close_btn" @click="clearActive();$parent.show = false;$parent.poptype = false;$parent.$refs.btnclaer.index=[]"></div>
        <div class="table">
          <div class="th">
            <input class="td inputx" @mouseover="changetxt"  @input="searchname" @mouseout="lose" :placeholder="txt"></input>
            <div class="td">排名</div>
            <div class="td">总分</div>
            <div class="td">基础分</div>
            <div class="td">正向分</div>
            <div class="td">反向分</div>
          </div>
          <div class="scroll">
            <div class="tr" :class="[item.active&&'active','']"  v-for="(item, index) in infolist">
              <div class="td">{{item.name}}</div>
              <div class="td">{{index+1}}</div>
              <div class="td">{{item.sum_score}}</div>
              <div class="td">{{item.base_score}}</div>
              <div class="td">{{item.zheng_score}}</div>
              <div class="td">{{item.fan_score}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script >
import img from "../assets/zp.png";
export default {
  props: ["info"],
  data() {
    return {
      img,
      min: false,
      firstId: -1,
      txt: "输入姓名可搜索",
      infolist: this.info,
    };
  },
  methods: {
    changetxt(e) {
      e.currentTarget.focus()
      e.currentTarget.value="";
      this.txt = "";
    },
    lose() {
      this.txt = "输入姓名可搜索";
    },
    clearActive(){
      this.infolist = this.infolist.map(el=>{
        el.active =false;
      })
    },
    searchname(e) {
      this.infolist = this.infolist.map((el, index) => {
        if (
          el.name.indexOf(e.currentTarget.value) >= 0 &&
          e.currentTarget.value != ""
        ) {
          el.active = true;
        } else {
          el.active = false;
        }
        return el;
      });
      this.$nextTick(() => {
        var s = document.querySelector(".active");
        if (s) {
          var f = document.querySelector(".scroll");
          var h = s.offsetTop - f.offsetTop;
          f.scrollTo({
            top: h,
            behavior: "smooth",
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.bg-box {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 11.4rem;
  position: fixed;
  top: 0;
  left: 3.9rem;
  z-index: 999;
}
.fixed_modal {
  width: 7.65rem;
  height: height(359);
  border: solid 1px #142b5f;
  box-sizing: content-box;
  border-radius: 0.1rem;
  padding: 0.05rem;
  position: relative;
  z-index: 100000;
  transition: all 500ms linear;
  * {
    transition: all 500ms linear;
  }
  .tt {
    width: 1.2rem;
    height: height(40);
  }
  &.min {
    overflow: hidden;
    transform: translate(5rem, height(-350)) scale(0);
    * {
      opacity: 0;
    }
  }
  .pop {
    width: 100%;
    height: 100%;
    background: #001025;
    border-radius: 0.1rem;
    padding: 0 0.3rem;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.8),
      inset 0px 0px 30px 0px rgba(44, 87, 228, 0.5);
    .info_x {
      display: flex;
      margin-top: height(10);
      margin-bottom: height(20);
      align-items: center;
    }
    .star {
      margin-top: height(10);
      .x {
        display: inline-block;
        width: 0.14rem;
        height: 0.14rem;
        background: url(../assets/x2.png);
        background-size: 100% 100%;
      }
      .xx {
        display: inline-block;
        width: 0.14rem;
        height: 0.14rem;
        background: url(../assets/x1.png);
        background-size: 100% 100%;
      }
    }
    .face {
      width: 0.9rem;
      height: height(100);
      opacity: 1;
      border-radius: 0.1rem;
      margin-right: 0.17rem;
      background: url(../assets/zp.png);
      background-size: 100% 100%;
    }
    .uname {
      font-size: 0.18rem;
      line-height: 1;
      margin-bottom: height(10);
    }
    .uscore {
      font-size: 0.14rem;
      line-height: 1;
    }
    .scroll {
      height: height(210);
      overflow: auto;
    }
    .close_btn {
      cursor: pointer;
      width: 0.14rem;
      height: 0.14rem;
      background: url(../assets/close.png) no-repeat center center / 0.22rem
        0.22rem;
      position: absolute;
      top: height(15);
      right: 0.25rem;
    }
    .min_btn {
      cursor: pointer;
      width: 0.14rem;
      height: 0.14rem;
      background: url(../assets/sf.png) no-repeat center center / auto 0.14rem;
      position: absolute;
      top: height(15);
      right: 0.49rem;
    }
    .tag {
      font-size: 0.14rem;
      line-height: height(56);
      text-align: center;
      color: #fff;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .title {
      font-size: 0.16rem;
      line-height: height(38);
      text-align: left;
      color: #fff;
      padding-top: height(10);
    }
    .table {
      width: 100%;
      margin-top: height(40);
      border-bottom: 1px solid #1e2953;
      .th,
      .tr {
        display: flex;
        border: 1px solid #1e2953;
        &.active {
          background: #3189d0;
        }
        .td {
          flex: 1;
          color: #fff;
          font-size: 0.14rem;
          line-height: height(40);
          text-align: center;
          &.inputx {
            width: 0;
            flex: 1;
            background: none;
            border: none !important;
            outline: none;
          }
          &:nth-child(2n) {
            border-left: 1px solid #1e2953;
            border-right: 1px solid #1e2953;
          }
        }
      }
      .th {
        background: #060d22;
        .td {
          color: #549cff;
        }
      }
    }
  }
}
input::-webkit-input-placeholder {
  font-size: 0.14rem;
  line-height: height(40);
  color: #549cff;
}
input:-moz-placeholder {
  font-size: 0.14rem;
  line-height: height(40);
  color: #549cff;
}

input::-moz-placeholder {
  font-size: 0.14rem;
  line-height: height(40);
  color: #549cff;
}
input:-ms-input-placeholder {
  font-size: 0.14rem;
  line-height: height(40);
  color: #549cff;
}
</style>