<template>
  <div>
    <div :class="[(b == 'pai' || b == 'yujing') && 'dong', 'jumper']">
      <div
        :class="[(b == 'pai' || b == 'yujing') && 'dong', 'po']"
        :style="{ background: item[1] }"
      ></div>
      <div
        :class="[(b == 'pai' || b == 'yujing') && 'dong', 'po']"
        :style="{ background: item[1] }"
      ></div>
      <div
        :class="[(b == 'pai' || b == 'yujing') && 'dong', 'po']"
        :style="{ background: item[1] }"
      ></div>
      <span :style="{ background: item[1] }"></span>
      <div class="markercontent" :style="{ background: item[1] }">
        <div
          class="iconsym"
          :style="{ background: 'url(' + c + ')no-repeat center/16px 16px' }"
        ></div>
        <div class="ppoinfo">
          <div class="poinfo">
          <div class="po_name">{{ a }}</div>
          <div
            class="po_sym"
            v-if="b == 'coor' || b == 'di' ||b=='cgb' || b == 'bian' || b == 'coorDS'  || b == 'can'" 
          >
            {{ item[0] }}
          </div>
          
        </div>
        </div>
         <div class="coords" v-if="b == 'coorDS'">
            电商
          </div>
      </div>
    </div>
  </div>
</template>
<script>
import Event from "../utils/event"
var state = {
  coor: ["党员户", "#cb1c1d"],
  coorDS: ["党员电商户", "#cb1c1d"],
  di: ["低保人群", "#E7551B"],
  bian: ["边缘人群", "#E71B85"],
  can: ["残疾人群", "#29C9AE"],
  shangchao: ["商店超市", "#da1fdc"],
  kuaidi: ["快递", "#1e27e7"],
  yiliao: ["医疗服务", "#ed6339"],
  yanglao: ["养老服务", "#e52727"],
  xuexiao: ["学校", "#ec3669"],
  wenhua: ["文化", "#36ec7f"],
  wushui: ["污水处理", "#1a1e78"],
  canyin: ["餐饮美食", "#e7a71e"],
  nongzi: ["农资", "#30bfd2"],
  dianshang: ["电商", "#7f0546"],
  caitan: ["菜摊", "#1f8b16"],
  dsh: ["电商户", "#4354F5"],
  gaizao: ["环境整治", "rgba(31,220,180,0.90)"],
  jiankong: ["监控设备", "rgba(65,236,141,0.90)"],
  qixiang: ["气象设备", "#21bfdc"],
  shuiwei: ["水位监测", "rgba(33,81,220,0.90)"],
  yujing: ["预警信息", "red"],
  pai: ["预警信息", "red"],
  cgb: ["村干部", "rgba(244,146,6,0.90)"],
};
export default {
  data(){
    return{
      polyline:null
    }
  },
  computed: {
    item() {
      return state[this.b];
    },
  },
  created(){
    Event.$on('closeCoor',()=>{
      if(this.polyline){

        this.polyline.remove();
      }
    })
    if(this.info.line){
     this.polyline = G.polyline([[this.info.fposition[1],this.info.fposition[0]],[this.info.position[1],this.info.position[0]]]).addTo(window.map);
    }
  },
  methods: {},
};
</script>
<style lang="scss" scope>
.jumper {
  position: relative;
  width: 20px;
  height: 20px;
}
.markercontent {
  opacity: 1;
  display: flex;
  padding: 5px 10px;
  background: rgba(231, 27, 133, 1);
  // border: 1px solid #800746;
  border-radius: 3px;
  position: absolute;
  left: 30px;
  bottom: 30px;
  width: max-content;
  align-items: center;
}
.iconsym {
  width: 16px;
  height: 16px;

  margin-right: 10px;
}
.ppoinfo{
  display: flex;
}
.poinfo {
  display: flex;
  flex-direction: column;
 /*  align-items: center; */
  justify-content: space-between;
}
.po_name,
.po_sym {
  font-size: 12px;
  line-height: 12px;
}
.po_sym {
  margin-top: 3px;
}
.jumper > .po {
  background-color: rgba(231, 27, 133, 1);
  width: 20px;
  height: 20px;
  border-radius: 100%;
  margin: 2px;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  margin: 0;
  -webkit-animation: jumper 1s 0s linear infinite;
  animation: jumper 1.5s 0s linear infinite;
}

.jumper > .po:nth-child(2) {
  animation-delay: 0.5s;
}

.jumper > .po:nth-child(3) {
  animation-delay: 1s;
}

@keyframes jumper {
  0% {
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
    opacity: 0;
  }

  5% {
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

//  @for $i from 1 through 50 {
//   .goomap-marker-icon:nth-child(#{$i}) {
//     .jumper.dong {
//       animation: blink 2000ms linear infinite ( $i - 1 ) * 800ms;
//     }
//   }
// } 
.jumper.dong {
      animation: blink 2000ms linear infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  85% {
    opacity: 0;
  }
  95% {
    opacity: 1;
  }
  95% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.jumper > span {
  display: inline-block;
  width: 50px;
  height: 2px;
  background: rgba(231, 27, 133, 1);
  position: absolute;
  bottom: 10px;
  left: 10px;
  transform: rotate(-45deg);
  transform-origin: left center;
}
.coords{
  position: absolute;
  width: 20px;
  height: 100%;
  top: 0;
  right: -20px;
  background: #fff100;
  color: #cb1c1d;
  text-align: center;
  font-size: 10px;
}
</style>