<template>
  <div id="baipie2" class="chars" :distributeArray="data"></div>
</template>
<script>

var colorList = [
  "#fffb72",
  "#54edfe",
  "#4bf6b8",
  "#f3697b",
];
export default {
  props:['data'],
  data(){
    return{
      distributeArray:[]
    }
  },
  computed: {
    option() { 
      return {
        series: [
          {
            type: "pie",
            top: "-8%",
            left: "20%",
            center: ["50%", "60%"],
            radius: ["35%", "55%"],
            roseType: "",
            itemStyle: {
              normal: {
                color: function (params) {
                  return colorList[params.dataIndex];
                },
              },
            },
            label: {
              show: true,
              position: "outside",
              formatter:function(params){
                  return '{a|'+params.value+'}{c|}\n{b|'+params.name+'}'
              },
              rich:{
                a:{
                  fontSize:16,
                  fontWeight:'bold',
                },
                b:{
                  fontSize:12,
                  fontWeight:'bold',
                },
                c:{
                  fontSize:12,
                  fontWeight:'bold'
                }
              }
            },
            labelLine: {
              smooth: false,
            },
            data: this.distributeArray,
          },
        ],
      };
    },
  },
  methods:{
  },
  mounted() {
    this.myChart = echarts.init(document.getElementById("baipie2"));
    this.myChart.setOption(this.option);
     window.addEventListener("resize", () => {
      console.log("窗口发生变化");
      this.myChart.resize();
    });
  },
  updated() {
    this.distributeArray = this.data;
    var sum =0;
    this.distributeArray.forEach(el => {
      sum = el.value+sum;
    });
    this.myChart.setOption(this.option);
  },
};
</script>
<style  scoped>
.chars {
  width: 100%;
  height: 100%;
}
</style>
