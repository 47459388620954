<template>
  <div class="fixed_modal">
    <div class="pop">
      <div class="title">电商户信息</div>
      <div class="tag">店铺名称：{{info.name}}</div>
      <div class="tag">掌柜姓名：{{info.villager_name}}</div>
      <div class="tag">掌柜身份：{{info.outlook}}</div>
      <div class="tag">销售平台：淘宝</div>
      <div class="tag">年度销量：{{info.sales}}单</div>
      <div class="tag">年营收额：{{info.turnover | towan}}万元</div>
      <div class="tag">店铺评分：{{info.score}}</div>
      <div class="close_btn" @click="$parent.$parent.pop_info=null"></div>
    </div>
  </div>
</template>
<script >
export default {
  props:['info'],
  data() {
    return {
      option: {},
    };
  },
  filters:{
    towan(v){
      return (Number(v)/10000).toFixed(2)
    }
  },
  components: {},
};
</script>
<style lang="scss" scoped>
.fixed_modal {
  width: 4rem;
  height: height(360);
  border: solid 1px #142b5f;
  box-sizing: content-box;
  border-radius: 0.1rem;
  padding: 0.05rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 10000;
  .pop {
    width: 100%;
    height: 100%;
    background: #001025;
    border-radius: 0.1rem;
    padding: 0 0.3rem;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.8),
      inset 0px 0px 30px 0px rgba(44, 87, 228, 0.5);
    .close_btn {
      width: 0.14rem;
      height: 0.14rem;
      background:url(../assets/close.png) no-repeat center center /auto 0.14rem;
      position: absolute;
      top: height(15);
      right: 0.15rem;
    }
    .tag {
      font-size: 0.14rem;
      line-height: height(42);
      color: #fff;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-left: 0.1rem;
    }
    .title {
      font-size: 0.18rem;
      line-height: height(38);
      text-align: center;
      color: #fff;
      border-bottom: 1px solid rgba(255,255,255,0.3);
      margin-bottom: height(10);
      padding-top: height(10);
    }
  }
}
</style>