<template>
  <div class="fixed_modal">
    <div class="pop">
      <div class="title">设备信息</div>
      <div class="tag">名称：{{info.name}}</div>
      <div class="tag">温度：{{info.info.temperature}}℃</div>
      <div class="tag">湿度：{{info.info.humidity}}%</div>
      <div class="tag">PM2.5：{{info.info.pm25}}μg/m³</div>
      <div class="tag">降雨量：{{info.info.rainfall}}mm</div>
      <div class="tag">风速：{{info.info.windSpeed}}m/s</div>
      <div class="close_btn" @click="$parent.$parent.pop_info=null"></div>
    </div>
  </div>
</template>
<script >
export default {
  props:['info'],
  data() {
    return {
      option: {},
    };
  },
  components: {},
};
</script>
<style lang="scss" scoped>
.fixed_modal {
  width: 2.4rem;
  min-height: height(200);
  border: solid 1px #142b5f;
  box-sizing: content-box;
  border-radius: 0.1rem;
  padding: 0.05rem;
  position: fixed;
  top:50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 10000;

  .pop {
    width: 100%;
    height: 100%;
    background: #001025;
    border-radius: 0.1rem;
    padding: 0 0.3rem;
    padding-bottom: height(20);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.8),
      inset 0px 0px 30px 0px rgba(44, 87, 228, 0.5);
    .close_btn {
      width: 0.14rem;
      height: 0.14rem;
      background:url(../assets/close.png) no-repeat center center /auto 0.14rem;
      position: absolute;
      top: height(15);
      right: 0.15rem;
    }
    .tag {
      text-align: left;
      font-size: 0.14rem;
      line-height: height(36);
      color: #fff;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-left: 0.1rem;
    }
    .title {
      font-size: 0.18rem;
      line-height: height(38);
      text-align: center;
      color: #fff;
      border-bottom: 1px solid rgba(255,255,255,0.3);
      margin-bottom: height(10);
      padding-top: height(10);

    }
  }
}
</style>