<template>
  <div class="right_center_bottom">
    <div class="title">政务·三务公开</div>
    <v-echart :option="option"></v-echart>
  </div>
</template>
<script>
var colorList=[ 
  "#ffbc32",
  "#2ccc44",
  "#ff3976",
  "#6173d6",
  "#914ce5",
  "#42b1cc",
  "#ff55ac",
  "#0090ff",
  "#06d3c4",
  "#ffbc32",
  "#2ccc44",
  "#ff3976",
  "#6173d6"]
export default {
  props: ["rightCenterBottom"],
  data() {
    return {
      option: null,
    };
  },
  watch: {
    rightCenterBottom() {
      this.setEchart();
    },
  },
  mounted() {
    this.setEchart();
  },
  methods: {
    setEchart() {
      const legendList = [];
      this.rightCenterBottom.forEach((el) => {
        legendList.push(el.name);
      });
      this.option = {
        color:colorList,
        tooltip: {
          trigger: "item",
          formatter: "{b}: {c} ({d}%)",
        },
        legend: {
          left: "center",
          bottom: "15%",
          textStyle: {
            color: "#fff",
          },
          data: legendList,
        },
        series: [
          {
            name: "",
            type: "pie",
            top: "10%",
            radius: ["40%", "60%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            // emphasis: {
            //   label: {
            //     show: false,
            //     fontSize: "30",
            //     fontWeight: "bold",
            //   },
            // },
            center: ["50%", "30%"],
            labelLine: {
              show: false,
            },
            data: this.rightCenterBottom,
          },
        ],
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.right_center_bottom {
  width: 4.5rem;
  height: height(290);
  background-color: #0b132d;
  border-radius: 0.1rem;
  position: absolute;
  top: height(470);
  right: 0.2rem;
  z-index: 100;
  display: flex;
  flex-direction: column;
}
</style>