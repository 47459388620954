<template>
  <div class="right_bottom">
    <div class="title">白牛村法务</div>
    <div class="rig">
      <div class="box">
        <div class="name">村民调解员</div>
        <div class="text">负责人员：{{rightBottom.tiaojieyuan}}</div>
        <div class="text">调解次数：{{rightBottom.cishu}}</div>
      </div>
      <div class="box">
        <div class="name">村民顾问</div>
        <div class="text">负责人员：{{rightBottom.guwen}}</div>
        <div class="text">咨询次数：{{rightBottom.zixun}}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props:['rightBottom']
}
</script>
<style lang="scss" scoped>
.right_bottom {
  width: 4.5rem;
  height: height(280);
  background-color: #0b132d;
  border-radius: 0.1rem;
  position: absolute;
  top: height(770);
  right: 0.2rem;
  z-index: 100;
  display: flex;
  flex-direction: column;
}
.rig{
  flex: 1;
  margin-top: 0.2rem;
  padding:0 0.15rem;
  display: flex;
}
.box{
  width: 2rem;
	height: height(200);
	background-color: #040b21;
  border-radius: 0.1rem;
  margin: 0 0.05rem;
  padding: 0 0.2rem;
}
.text{
  @include ws;
	height: height(52);
	background-color: #0e1b46;
  border-radius: 0.1rem;
  margin-top: height(10);
  text-align: center;
  color: #fff;
  font-size: 0.16rem;
  line-height: height(52);
}
.name{
  font-size: 0.18rem;
	line-height: height(58);
  color: #ffffff;
  text-align: center;
  border-bottom: 0.02rem solid rgba(255,255,255,0.2);
  @include ws;
}
</style>