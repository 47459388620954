<template>
  <div class="bg-box">
    <div class="mainboxxx">
      <div class="main-box1">
        <div class="mapbox">
          <div class="m_title tmap"></div>
          <div style="display: flex">
            <div class="map-echart">
              <v-echart :option="option_map"></v-echart>
            </div>
            <div class="bar-echart">
              <v-echart :option="option_bar"></v-echart>
            </div>
          </div>
        </div>
        <div class="hxbox">
          <div class="m_title thx"></div>
          <div class="hx-echart">
            <right-top :data="data"></right-top>
          </div>
        </div>
      </div>
      <!-- <div class="main-box2">
        <div class="mb-child">
          <div class="m_title tsp"></div>
          <div class="mb-txt">各类产品销售数据</div>
          <div class="datalist" style="text-align: center">
            <div class="tabletitle">
              <div class="tabletitle_t_1" style="color: #fff">产品</div>
              <div class="tabletitle_t_1" style="color: #fff">销量</div>
              <div class="tabletitle_t_1" style="color: #fff">营收额</div>
            </div>
            <div class="tablecontent swiper-container" id="sp1">
              <div class="swiper-wrapper">
                <div class="databox swiper-slide" v-for="item in saleData">
                  <div class="databox_1">
                    {{ item.keywords_name }}
                  </div>
                  <div class="databox_2">{{ item.sum_sales }}</div>
                  <div class="databox_3">
                    {{ item.sum_turnover }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mb-child">
          <div class="m_title txl"></div>
          <div class="m-echartbox">
            <v-echart :option="option_xl"></v-echart>
          </div>
        </div>
        <div class="mb-child">
          <div class="m_title tcl"></div>
          <div class="m-echartbox">
            <v-echart :option="option_eut"></v-echart>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
var colorList = ["#fffb72", "#54edfe", "#4bf6b8", "#f3697b"];
var xData = ["2019年", "2019年", "2019年", "2019年"];
var yData = [2342, 1230, 425, 500];
import rightTop from "../views/zhenghe/right_bottomcopycopy";
import Swiper, { Pagination, Autoplay } from "swiper";
Swiper.use([Pagination, Autoplay]);
export default {
  props: ["mapx", "data", "saleData", "category", "eut"],
  components: {
    rightTop,
  },
  data() {
    return {
      option_map: null,
      option_bar: null,
      option_xl: null,
      option_eut: null,
    };
  },
  mounted() {
    this.option_map = this.get_option(this.mapx);
    this.option_bar = this.get_option1(this.mapx);
    this.setopt_dataXl();
    this.setopt_enut();
    this.initSwpier();
  },
  watch: {
    mapx() {
      this.option_map = this.get_option(this.mapx);
      this.option_bar = this.get_option1(this.mapx);
    },
    saleData() {
      this.initSwpier();
    },
    category() {
      this.setopt_dataXl();
    },
    eut(){
        this.setopt_enut();
    }
  },
  methods: {
    get_option(val) {
      var option = null;
      var datamap = val;
      var geoCoordMap = {
        辽宁: [123.460172, 41.685769],
        重庆: [108.384366, 30.439702],
        北京: [116.4551, 40.2539],
        上海: [121.477665, 31.237658],
        江苏: [118.842091, 32.07322],
        山东: [117.1218, 36.640107],
        河北: [114.488184, 38.04081],
        天津: [117.205216, 39.0976],
        吉林: [125.324533, 43.84078],
        上海: [126.529538, 45.809426],
        内蒙古: [111.749188, 40.852133],
        山西: [112.547197, 37.875395],
        陕西: [108.948765, 34.364196],
        甘肃: [103.826726, 36.075501],
        青海: [101.77036, 36.621365],
        宁夏: [106.232837, 38.48795],
        新疆: [87.600705, 43.828587],
        西藏: [91.172637, 29.650634],
        四川: [104.069296, 30.671229],
        贵州: [106.673079, 26.643561],
        云南: [102.834278, 24.907709],
        广西: [108.361381, 22.811681],
        广东: [113.253607, 23.128689],
        湖南: [112.94662, 28.236672],
        湖北: [114.313886, 30.603689],
        河南: [113.657287, 34.753203],
        安徽: [117.229417, 31.825842],
        浙江: [120.205162, 30.244846],
        福建: [119.306345, 26.082765],
        海南: [110.346995, 20.039888],
        江西: [115.851937, 28.690723],
        香港: [114.172893, 22.284803],
        澳门: [114.172893, 22.284803],
        台湾: [121.49865, 25.053095],

        白牛村: [119.17781, 30.187313],
      };

      var planePath =
        "path://M1705.06,1318.313v-89.254l-319.9-221.799l0.073-208.063c0.521-84.662-26.629-121.796-63.961-121.491c-37.332-0.305-64.482,36.829-63.961,121.491l0.073,208.063l-319.9,221.799v89.254l330.343-157.288l12.238,241.308l-134.449,92.931l0.531,42.034l175.125-42.917l175.125,42.917l0.531-42.034l-134.449-92.931l12.238-241.308L1705.06,1318.313z";

      var dataFrom = "白牛村";

      var convertData = function (data1) {
        var res = [];
        console.log(data1, 88888888);
        for (var i = 0; i < data1.length; i++) {
          var geoCoord = geoCoordMap[data1[i].name];

          if (geoCoord) {
            res.push({
              name: data1[i].name,
              value: geoCoord.concat(data1[i].value),
            });
          }
        }
        console.log(res);
        return res;
      };

      option = {
        tooltip: {
          /* trigger: "item", */
          formatter: (val) => {
            if (val.value) {
              return val.name + ":" + val.value + "%";
            } else {
              return "";
            }
          },
        },

        visualMap: {
          type: "piecewise",
          min: 0,
          max: 1,
          left: "left",
          top: "bottom",
          text: ["高", "低"],
          calculable: false,
          show: false,
          inRange: {
            color: ["#293671", "#6bfcf6", "#0d45dd"],
          },
        },
        geo: {
          map: "china",
          zoom: 1.2,
          label: {
            normal: {
              show: true,
              color: "#c1c4c8",
            },
            emphasis: {
              show: false,
              color: "#292929",
            },
          },
          roam: true,
          itemStyle: {
            normal: {
              areaColor: "#fbfbfb",
              borderColor: "#b9b4b7",
            },
            emphasis: {
              areaColor: "#fee619",
            },
          },
        },
        series: [
          {
            name: "",
            type: "lines",
            zlevel: 2,
            symbolSize: 10,
            effect: {
              show: true,
              period: 6,
              symbol: planePath,
              trailLength: 0,
              symbolSize: 10,
            },
            label: {
              show: false,
            },
            lineStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  {
                    offset: 0,
                    color: "rgb(251,212,68,1)",
                  },
                  {
                    offset: 1,
                    color: "rgb(251,212,68,0)",
                  },
                ]),
                width: 2,
                opacity: 0.5,
                curveness: 0.2,
              },
            },
            data: this.mapx
              .filter((el) => geoCoordMap[el.name])
              .map(function (dataItem) {
                return {
                  fromName: dataFrom,
                  toName: dataItem.name,
                  coords: [geoCoordMap[dataFrom], geoCoordMap[dataItem.name]],
                };
              }),
          },
          {
            type: "effectScatter",
            coordinateSystem: "geo",
            data: convertData(datamap),
            symbolSize: 8,
            showEffectOn: "render",
            rippleEffect: {
              scale: 5,
              brushType: "stroke",
            },
            hoverAnimation: true,
            label: {
              normal: {
                formatter: "{b}",
                position: "right",
                show: true,
              },
              emphasis: {
                show: true,
              },
            },
            itemStyle: {
              normal: {
                color: "#63d4e9",
                shadowBlur: 10,
                shadowColor: "#333",
              },
            },
          },
          {
            type: "map",
            mapType: "china",
            geoIndex: 0,
            label: {
              normal: {
                show: true,
              },
              emphasis: {
                show: true,
              },
            },
            data: this.mapx,
          },
        ],
      };
      return option;
    },
    get_option1(val) {
      let option = null;
      var ydata = val.map((el) => {
        return el.name;
      });
      var xdata = val.map((el) => {
        return el.value;
      });
      option = {
        grid: {
          left: "0%",
          right: "10%",
          bottom: "10%",
          top: "3%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "none",
          },
          formatter: function (params) {
            return (
              params[0].name +
              "<br/>" +
              "<span style='display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:rgba(36,207,233,0.9)'></span>" +
              params[0].seriesName +
              " : " +
              params[0].value.toLocaleString() +
              " %<br/>"
            );
          },
        },
        xAxis: {
          show: false,
          type: "value",
        },
        yAxis: [
          {
            type: "category",
            inverse: true,
            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff",
                fontSize: 10,
              },
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            data: ydata,
          },
          {
            type: "category",
            inverse: true,
            axisTick: "none",
            axisLine: "none",
            show: true,
            axisLabel: {
              textStyle: {
                color: "#ffffff",
                fontSize: "10",
              },
              formatter: function (value) {
                /* if (value >= 10000) {
                  return (value / 10000).toLocaleString() + "万";
                } else {
                  return value.toLocaleString();
                } */
                return value.toLocaleString() + "%";
              },
            },
            data: xdata,
          },
        ],
        series: [
          {
            name: "成交占比",
            type: "bar",
            zlevel: 1,
            itemStyle: {
              normal: {
                barBorderRadius: 10,
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  {
                    offset: 0,
                    color: "rgb(57,89,255,1)",
                  },
                  {
                    offset: 1,
                    color: "rgb(46,200,207,1)",
                  },
                ]),
              },
            },
            barWidth: 5,
            data: xdata,
          },
        ],
      };
      return option;
    },
    setopt_dataXl() {
      var sum = 0;
      console.log(this.category, "category");
      this.$parent.category = this.category.map((el) => {
        el.value = parseFloat(el.value);
      });
      this.option_xl = {
        series: [
          {
            type: "pie",
            top: "-8%",
            left: "20%",
            center: ["50%", "40%"],
            radius: ["35%", "55%"],
            roseType: "",
            itemStyle: {
              normal: {
                color: function (params) {
                  return colorList[params.dataIndex];
                },
              },
            },
            label: {
              show: true,
              position: "outside",
              formatter: function (params) {
                return "{a|" + params.value + "%}\n{b|" + params.name + "}";
              },
              rich: {
                a: {
                  fontSize: 16,
                  fontWeight: "bold",
                },
                b: {
                  fontSize: 12,
                  fontWeight: "bold",
                },
              },
            },
            labelLine: {
              length: 10,
              length2: 30,
              smooth: false,
            },
            data: this.category,
          },
        ],
      };
    },
    setopt_enut() {
      var dataX = [];
      var dataY = [];
      this.eut.map((el) => {
        dataX.push(el.date + "年");
        dataY.push(parseFloat(el.value));
      });
      this.option_eut = {
        grid: {
          top: "15%",
          left: "-10%",
          bottom: "25%",
          right: "5%",
          containLabel: true,
        },
        animation: true,
        animationEasing: "elasticOut",
        xAxis: [
          {
            type: "category",
            data: dataX,
            axisTick: {
              alignWithLabel: true,
            },
            nameTextStyle: {
              color: "#82b0ec",
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "#82b0ec",
              },
            },
            axisLabel: {
              textStyle: {
                color: "#fff",
              },
              margin: 20,
            },
          },
        ],
        yAxis: [
          {
            show: false,
            type: "value",
            axisLabel: {
              textStyle: {
                color: "#fff",
              },
            },
            splitLine: {
              lineStyle: {
                color: "#0c2c5a",
              },
            },
            axisLine: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: "",
            type: "pictorialBar",
            symbolSize: [30, 10],
            symbolOffset: [0, -6],
            symbolPosition: "end",
            z: 12,
            // "barWidth": "0",
            label: {
              normal: {
                show: true,
                position: "top",
                formatter: function (param) {
                  return param.value / 10000 + "万吨";
                },
                fontSize: 12,
                fontWeight: "bold",
                color: "#34DCFF",
              },
            },
            color: "#2DB1EF",
            data: dataY,
          },
          {
            name: "",
            type: "pictorialBar",
            symbolSize: [30, 10],
            symbolOffset: [0, 7],
            // "barWidth": "20",
            z: 12,
            color: "#2DB1EF",
            data: dataY,
          },
          {
            name: "",
            type: "pictorialBar",
            symbolSize: [35, 15],
            symbolOffset: [0, 12],
            z: 10,
            itemStyle: {
              normal: {
                color: "transparent",
                borderColor: "#2EA9E5",
                borderType: "solid",
                borderWidth: 1,
              },
            },
            data: dataY,
          },
          {
            name: "",
            type: "pictorialBar",
            symbolSize: [50, 20],
            symbolOffset: [0, 18],
            z: 10,
            itemStyle: {
              normal: {
                color: "transparent",
                borderColor: "#19465D",
                borderType: "solid",
                borderWidth: 2,
              },
            },
            data: yData,
          },
          {
            type: "bar",
            //silent: true,
            barWidth: "30",
            barGap: "5%", // Make series be overlap
            barCateGoryGap: "5%",
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 0.7, [
                  {
                    offset: 0,
                    color: "#38B2E6",
                  },
                  {
                    offset: 1,
                    color: "#0B3147",
                  },
                ]),
                opacity: 0.8,
              },
            },
            data: dataY,
          },
        ],
      };
    },
    initSwpier() {
      var mySwiper = new Swiper("#sp1", {
        loop: true,
        freeMode: true,
        loopAdditionalSlides: 5,
        speed: 2000,
        slidesPerView: 5,
        observer: true,
        observeParents: true,
        observeSlideChildren: true,
        autoplay: {
          delay: 1,
        },
        direction: "vertical",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.bg-box {
  height: 100vh;
  width: 11.4rem;
  position: fixed;
  top: 0;
  left: 3.9rem;
  z-index: 999;
  overflow: hidden;
}
.m_title {
  width: 100%;
  height: height(62);
  &.tmap {
    background: url(../assets/1.png) no-repeat center/auto height(21);
  }
  &.thx {
    background: url(../assets/2.png) no-repeat center/auto height(21);
  }
  &.tsp {
    background: url(../assets/3.png) no-repeat center/auto height(21);
  }
  &.txl {
    background: url(../assets/4.png) no-repeat center/auto height(21);
  }
  &.tcl {
    background: url(../assets/5.png) no-repeat center/auto height(21);
  }
}
.mainboxxx {
  margin: 0 auto;
  z-index: 1000;
  width: 11.78rem;
  margin-top: height(321);
}
.main-box1 {
  display: flex;
  .mapbox {
    width: 7.6rem;
    height: height(450);
    background: rgba(5, 11, 44, 0.6);
    border-radius: 0.1rem;
    margin-right: 0.2rem;
    font-size: 0;
    .map-echart {
      width: 5rem;
      height: height(388);
    }
  }
  .bar-echart {
    flex: 1;
    height: height(450);
  }
  .hxbox {
    width: 3.6rem;
    height: height(450);
    background: rgba(5, 11, 44, 0.6);
    border-radius: 0.1rem;
    .hx-echart {
      width: 100%;
      height: height(390);
      padding: 0 0.3rem;
    }
  }
}
.main-box2 {
  display: flex;
  margin-top: height(20);
  .mb-child {
    width: 3.66rem;
    height: height(307);
    background: rgba(5, 11, 44, 0.6);
    border-radius: 0.1rem;
    margin-right: 0.2rem;
    padding: 0 0.1rem;
    &:last-child {
      margin-right: 0;
    }
    .mb-txt {
      color: #fff;
      line-height: 1;
      font-size: 0.14rem;
    }
    .m-echartbox {
      width: 100%;
      height: height(307);
    }
  }
}
.datalist {
  width: 100%;
  height: auto;
  margin-top: height(13);
}
.tabletitle {
  width: 100%;
  height: height(30);
  line-height: height(30);
  display: flex;
  background: #0a2157;
  justify-content: space-around;
  .tabletitle_t_1 {
    flex: 1;
    text-align: center;
  }
}
.databox_1,
.databox_2,
.databox_3 {
  flex: 1;
  text-align: center;
  color: #fff;
}
.databox {
  height: height(30);
  width: 100%;
  line-height: height(30);
  font-size: 0.12rem;
  display: flex;
  justify-content: space-around;
  color: #fff;
}
.tablecontent {
  height: height(150);
  overflow: hidden;
}
.databox:nth-child(2n) {
  background: rgba(7, 52, 98, 0.3);
}
</style>