<template>
  <div class="fixed_modal">
    <div class="pop">
      <div class="tag">处理结果</div>
      <div class="img_box">
        <div>
          <img src="http://bainiu.qlzf.top/uploads/20210318/f33c63f41306a30b135cb719754efc5a.jpg" alt="" />
          <div class="txt">改造前</div>
        </div>
        <div>
          <img src="http://bainiu.qlzf.top/uploads/20210318/f33c63f41306a30b135cb719754efc5a.jpg" alt="" />
          <div class="txt">改造后</div>
        </div>
      </div>
      <div class="tag_2">反馈说明</div>
      <div class="desc">根据上报事件情况，第一时间前往查看，确实出现灯柱歪斜问题，当即就事件问题寻找相关电工人员进行灯柱歪斜处理，目前已处理完成，灯 柱已扶正，并且通电正常，解除危险状态。</div>
      <div class="close_btn" @click="$parent.$parent.pop_info=null"></div>
    </div>
  </div>
</template>
<script >
export default {
  props:['info'],
  data() {
    return {
      option: {},
    };
  },
  components: {},
};
</script>
<style lang="scss" scoped>
.fixed_modal {
  width: 8.9rem;
  height: height(421);
  border: solid 1px #142b5f;
  box-sizing: content-box;
  border-radius: 0.1rem;
  padding: 0.05rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 10000;
  .img_box {
    display: flex;
    & > div {
      margin: 0.1rem;
      font-size: 0;
      position: relative;
      img {
        width: 4rem;
        height: height(225);
        border-radius: 0.1rem;
        
      }
      .txt{
         position: absolute;
         bottom: height(0);
         width: 4rem;
         font-size: 0.16rem;
         line-height: height(40);   
         color: #fff;
         text-align: center;
         background:rgba(0, 0, 0, 0.5)
        }
    }
  }
  .pop {
    width: 100%;
    height: 100%;
    background: #001025;
    border-radius: 0.1rem;
    padding: 0 0.3rem;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.8),
      inset 0px 0px 30px 0px rgba(44, 87, 228, 0.5);
    .close_btn {
      width: 0.14rem;
      height: 0.14rem;
      background: url(../assets/close.png) no-repeat center center /auto 0.14rem;;
      position: absolute;
      top: height(15);
      right: 0.15rem;
    }
    .tag {
      font-size: 0.14rem;
      line-height: height(46);
      color: #fff;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-left: 0.1rem;
      text-align: left;
    }
    .tag_2{
        height: height(46);
        width: 100%;
        font-size: 0.14rem;
        color: #fff;
        font-weight: 700;
        border-bottom: 1px solid rgba(255,255,255,0.20);
        line-height: height(46);
        position: relative;
        &::after{
            content: '';
            position: absolute;
            width: 0.5rem;
            height: height(3);
            background: #fff;
            bottom: height(-1.5);
            left: 0;

        }
    }
    .desc{
        color: #fff;
        font-size: 0.12rem;
        line-height: height(36);
    }
    .title {
      font-size: 0.18rem;
      line-height: height(38);
      text-align: center;
      color: #fff;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      padding-top: height(10);
    }
  }
}
</style>