<template>
  <div class="headbox" @click="$parent.$refs.map.set_center()">
    <div class="left_txt_btn" @click="handel_click">白牛乡村小脑一张图</div>
    <!-- <div class="left_txt">欢迎来到临安白牛村电商中心</div> -->
    <!-- <div class="right_txt">2020年10月5日 18 : 30 : 45 </div> -->
  </div>
</template>

<script>
export default {
  methods: {
    handel_click(){
      window.open("https://bndata.dsfw360.com/bainiu/index.html#/","_self")
    }
  },
};
</script>

<style lang='scss' scope>
.left_txt_btn{
  width: 2.3rem;
	height: height(50);
	background: #0d1a41 url(../assets/fh.png)no-repeat left 0.2rem center/auto 0.16rem;
	border-radius: 0.05rem;
  line-height: height(50);
  padding-left: 0.5rem;
  margin-top: height(15);
  font-size: 0.18rem;
  float: left;
  cursor: pointer;
}
.headbox {
  width: 19.2rem;
  height: height(94);
  font-size: 0.18rem;
  color: #fff;
  padding: 0 0.23rem;
  background: url(../assets/dbt2.png) center/auto 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}
.left_txt{
  float: left;
  line-height:height(75);
}
.right_txt{
  float: right;
  line-height:height(75);
}
</style>
