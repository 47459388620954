<template>
  <div class="c_box2">
    <div class="item_title environmentmonitoring">
      综合环境监测数据 <span class="look_more"><span @click="$parent.poptype=2;$parent.show=true">查看详情</span>>></span>
    </div>
    <div class="content">
      <div class="line_first">
        <div class="tem_number">{{ data.temperature }}°</div>
        <div class="airQuality" :style="{ background: pmcolor(data.PM25) }">
          空气质量:{{ data.PM25 }}{{ data.PM25 | pmfilter }}
        </div>
      </div>
      <div class="line_second">
        <div class="item_sec">白牛村:{{ data.now }}</div>
        <div class="item_sec">星期{{ data.weekday | weekdayfilter }}</div>
        <div class="item_sec">{{data.rain_type}}</div>
        <div class="item_sec">湿度{{ data.humidity }}</div>
      </div>
     <!--  <div class="line_third">
        <div class="item_line">
          <div class="i_name">24小时预报</div>
          <div class="i_symbol">
            <div :class="[datatype==1&&'on','sym_name']" @click="changtype(1)">温度</div>
        
            <div :class="[datatype==3&&'on','sym_name']" @click="changtype(3)">水位</div>
            <div :class="[datatype==4&&'on','sym_name']" @click="changtype(4)">空气质量</div>
          </div>
        </div>
        <div class="echarts">
          <v-echart :option="leftmidoption"></v-echart>
        </div>
      </div> -->
    <div class="item_title environmentmonitoring xxxx" style="margin-top:height(30)">
      同心荟概览 <span class="look_more"><span @click="goto()">查看详情</span>>></span>
    </div>
      <div class="content3">
        <div class="item_c3">
          <div class="item_c_title3">
            同心荟成员总数（人）<span class="item_c_number3">137</span>
          </div>
        </div>
        <div class="item_c3">
          <div class="item_c_title3">
            辐射范围（km²）<span class="item_c_number3">3100</span>
          </div>
        </div>
        <div class="item_c3">
          <div class="item_c_title3">
            回乡创业青年占比<span class="item_c_number3">77.6%</span>
          </div>
        </div>
        <div class="item_c3">
          <div class="item_c_title3">
            活动发布场次（次）<span class="item_c_number3">
              82
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
 <script>
var str = '';
export default {
  props: ["data"],
  data() {
    return {
      leftmidoption: null,
      datatype: 1,
      xdata: [],
      ydata: [],
    };
  },
  mounted() {
    console.log(11111111111);
    this.setoption();
  },
  filters: {
    pmfilter(val) {
      const x = Number(val);
      return x > 250
        ? "严重"
        : x > 150 && x <= 250
        ? "重度"
        : x > 115 && x <= 150
        ? "中度"
        : x > 75 && x <= 115
        ? "轻度"
        : x > 35 && x <= 75
        ? "良"
        : "优";
    },
    weekdayfilter(val) {
      switch (val) {
        case 1:
          return "一";
          break;
        case 2:
          return "二";
          break;
        case 3:
          return "三";
          break;
        case 4:
          return "四";
          break;
        case 5:
          return "五";
          break;
        case 6:
          return "六";
          break;
        case 0:
          return "日";
          break;
      }
    },
  },
  watch: {
    data() {
      this.setoption();
    }
  },
  methods: {
    //路由
    goto(){
      window.open('/#/CMain')
    },
    pmcolor(val) {
      const x = Number(val);
      return x > 250
        ? "#ff1935"
        : x > 150 && x <= 250
        ? "#f6d424"
        : x > 115 && x <= 150
        ? "#daaf3f"
        : x > 75 && x <= 115
        ? "#d39b12"
        : x > 35 && x <= 75
        ? "#a4b665"
        : "#00b93d";
    },
    changtype(v){
      this.datatype = v;
       this.setoption();
    },
    setxdata() {
      var arr = [];
      this.data.datalist.map((el) => {
        arr.push(el.date);
      });
      this.xdata = arr;
    },
    setdataformat() {
      var arr = [];
      this.data.datalist.map((el) => {
        if (this.datatype == 1) {
          arr.push(el.avg_temperature);
        }
        if (this.datatype == 2) {
          /* arr.push(el.avg_temperature); */
        }
        if (this.datatype == 3) {
          /* arr.push(el.avg_temperature); */
        }
        if (this.datatype == 4) {
          arr.push(el.avg_PM25);
        }
      });
      this.ydata = arr;
    },
    setoption() {
      str = this.datatype==1?"℃":this.datatype==2?"":this.datatype==3?"":"μg/m³";
      this.setxdata();
      this.setdataformat();
      this.leftmidoption = {
        xAxis: {
          type: "category",
          splitLine: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              width: 1,
              color: "#3c4356",
            },
          },
          axisTick: {
            show: false,
            alignWithLabel: false,
            lineStyle: {
              width: 3,
            },
          },
          axisLabel: {
            textStyle: {
              color: "#fff",
              fontSize: 12,
              fontWeight: "bold",
            },
          },
          data: this.xdata,
        },
        grid: {
          top:"18%",
          left: "2%",
          right: "4%",
          bottom: "0%",
          containLabel: true,
        },
        yAxis: {
          type: "value",
          name:str,
          nameTextStyle:{
            color:'#fff'
          },
          splitLine: {
            show: false,
          },
          axisLine: {
            show: false,
            lineStyle: {
              width: 3,
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            /* formatter:(v)=> {
              console.log()
              var str = 
              return v +str; 
            }, */
            color: "#fff",
            fontWeight: "bold",
          },
        },
        series: [
          {
            type: "line",
            symbol: "circle",
            symbolSize: 10,
            smooth: true,
            animationDuration: 2000,
            itemStyle: {
              normal: {
                color: "#fff",
                borderColor: "#66DC95",
                borderWidth: 2,
              },
            },
            lineStyle: {
              normal: {
                color: "#66DC95",
                width: 3,
                shadowColor: "rgba(0,0,0,0.4)",
                shadowBlur: 10,
                shadowOffsetX: 4,
                shadowOffsetY: 10,
              },
            },
            data: this.ydata,
          },
        ],
      };
    },
  },
};
</script>

 <style lang="scss" scope>
.c_box2 {
  width: 100%;
  height: 100%;
  padding: height(10) 0.1rem;
  font-size: 0;
  .content {
    width: 100%;
    margin-top: height(23);
    .line_first {
      display: flex;
      .tem_number {
        font-size: 0.6rem;
        font-weight: 500;
        color: #fff;
        margin-right: 0.2rem;
        line-height: height(48);
      }
      .airQuality {
        padding: 0 0.14rem;
        height: height(20);
        line-height: height(20);
        background: #059b3c;
        border-radius: 10px;
        font-size: 0.14rem;
        color: #fff;
        text-align: center;
      }
    }
    .line_second {
      margin-top: height(24);
      display: flex;
      justify-content: space-between;
      .item_sec {
        color: #fff;
        font-size: 0.14rem;
        line-height: 1;
      }
    }
    .line_third {
      margin-top: height(30);
      .item_line {
        display: flex;
        height: height(20);
        .i_name {
          margin-right: 0.1rem;
          font-size: 0.14rem;
          color: #fff;
          line-height: height(20);
        }
        .i_symbol {
          display: flex;
          justify-content: space-between;
          flex: 1;
          .sym_name {
            padding: height(2) 0.11rem;
            font-size: 0.13rem;
            background: #0a1f51;
            color: #fff;
            border-radius: 10px;
            cursor: pointer;
            &.on {
              background: #2b6ae9;
            }
          }
        }
      }
      .echarts {
        margin-top: height(10);
        width: 100%;
        height: height(156);
      }
    }
  }
}
.item_title.environmentmonitoring {
  background: url(../../assets/hjjc.png) no-repeat left 0.1rem center/14px auto,
    linear-gradient(to left, rgba(9, 33, 87, 0), rgba(9, 33, 87, 1));
    &.xxxx{
      margin-top: height(20);
    }
}
.content3 {
    margin-top: height(20);
    padding: 0 0.1rem;
    white-space: normal;
    font-size: 0;
    .item_c3 {
      vertical-align: top;
      width: 1.46rem;
      height: height(70);
      display: inline-block;
      background: #001025;
      border: 1px solid #142b5f;
      border-radius: 7px;
      margin-right: 0.1rem;
      margin-bottom: height(8);
      padding: height(12) 0;
      .item_c_title3 {
        font-size: 0.12rem;
        color: #fff;
        text-align: center;
        font-weight: bold;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        white-space: nowrap;
      }
      .item_c_number3 {
        font-weight: 700;
        font-size: 0.26rem;
        text-align: center;
        color: #66dc95;
      }
    }
    .item_c3:nth-child(2n) {
      margin-right: 0;
    }
    .item_c3:nth-child(3) {
      margin-bottom: 0;
    }
    .item_c3:nth-child(4) {
      margin-bottom: 0;
    }
  }
</style>