<template>
  <div class="modal">
    <div class="model">
      <div class="close" @click="$parent.show=0,onchecked={}"></div>
      <div class="title">{{obj.act_name}}</div>
      <div class="con">
        <img :src="urlx+item" v-for="(item,index) in obj.imglist"/>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props:['obj']
}
</script>
<style lang="scss" scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.9);
  z-index: 1000;
  .model {
    width: 14.3rem;
    height: height(900);
    background: #00082b;
    border: 2px solid #0b3ba0;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    z-index: 1000;
    padding: 0 0.63rem;
  }
  .title {
    line-height: height(88);
    font-size: 0.26rem;
    color: #fff;
    text-align: center;
  }

  .con {
    width: 13rem;
    height: height(730);
    overflow: auto;
    img{
      width: 100%;
    }
  }

  .close {
    width: 0.32rem;
    height: 0.32rem;
    background: url(../../../assets/gb6.png);
    background-size: 100% 100%;
    position: absolute;
    bottom: -0.52rem;
    left: 50%;
    transform: translateX(-0.16rem);
    cursor: pointer;
  }
}
</style>