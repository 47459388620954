<template>
  <div class="c_boxcopy">
    <div class="tag_cate">
      <div :class="[tty==1&&'on','tag_x']" @click="tty=1;$parent.$parent.rightbottomtype_copy=1">山核桃</div>
      <div :class="[tty==2&&'on','tag_x']" @click="tty=2;$parent.$parent.rightbottomtype_copy=2">坚果</div>
      <div :class="[tty==3&&'on','tag_x']" @click="tty=3;$parent.$parent.rightbottomtype_copy=3">笋类制品</div>
    </div>
    <div class="content">
      <div class="first_box">
        <div class="e_title">消费能力分布</div>
        <div class="echart_1">
          <v-echart :option="option_1" :act="true"></v-echart>
        </div>
      </div>
      <div class="sec_box">
        <div class="sec_1">
          <div class="e_title">{{tty==1?'山核桃':tty==2?'坚果':'笋'}}种类</div>
          <div class="es_echart">
            <v-echart :option="option_2" :act="true"></v-echart>
          </div>
        </div>
        <div class="sec_1">
          <div class="e_title">食品口味</div>
          <div class="es_echart">
            <v-echart :option="option_3" :act="true"></v-echart>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["data"],
  data() {
    return {
      option_1: null,
      option_2: null,
      option_3: null,
      option_4: null,
      tty:1,
    };
  },
  mounted() {
    this.option_1 = this.setoption_1(this.data.xiaofei);
    this.option_2 = this.setoption_2(this.data.zhonglei);
    this.option_3 = this.setoption_2(this.data.kouwei);
    this.option_4 = this.setoption_4(this.data.resou);
  },
  watch: {
    data() {
      this.option_1 = this.setoption_1(this.data.xiaofei);
      this.option_2 = this.setoption_2(this.data.zhonglei);
      this.option_3 = this.setoption_2(this.data.kouwei);
      this.option_4 = this.setoption_4(this.data.resou);
    },
  },
  methods: {
    setoption_1(val) {
      const colorList = [
        "#47A2FF ",
        "#53C8D1",
        "#59CB74",
        "#FBD444",
        "#7F6AAD",
        "#585247",
      ];
      var option_1 = {
        legend: {
          type: "scroll",
          orient: "vertical",
          right: "0%",
          top: "0%",
          itemGap: 8,
          selectedMode: false,
          icon: "pin",
          data: val,
          textStyle: {
            color: "#77899c",
            rich: {
              uname: {
                width: 70,
              },
              unum: {
                color: "#fff",
                width: 40,
                align: "right",
              },
            },
          },
          formatter(name) {
            return `{uname|${name}}{unum|}`;
          },
        },
        color: colorList,
        series: [
          {
            type: "pie",
            radius: [35, 45],
            center: ["20%", "45%"],
            label: {
              normal: {
                position: "center",
                show: false,
                rich: {
                  uname: {
                    color: "#fff",
                    fontSize:16,
                    fontWeight:'bold',
                    padding:[5,5]
                  },
                  unum: {
                    color: "#fff",
                    fontSize:16,
                  },
                },
                formatter: (data) => {
                  return `{uname|${data.data.name}}` + "\n" + `{unum|${data.data.value}%}` ;
                },
              },
            },
            emphasis: {
              label: {
                show: true,
              },
            },
            data: val,
          },
        ],
      };
      return option_1;
    },
    setoption_2(val) {
      var legendData = val.map((el) => {
        return el.name;
      });
      var option_2 = {
        legend: {
          show: false,
          bottom: 10,
          itemGap: 20,
          z: 2,
          icon: "circle",
          textStyle: {
            color: "#fff",
          },
          data: legendData,
        },
        /* tooltip: {
          show:false,
          trigger: "item",
          formatter: "{b} : {c} ({d}%)",
        }, */
        series: [
          {
            name: "访问来源",
            type: "pie",
            radius: ["58%", "75%"],
            selectedMode: "single",
            selectedOffset: 10,
            clockwise: true,
            center: ["35%", "50%"],
            color: [
              "#43cadd",
              "#3893e5",
              "#FBFE27",
              "rgb(11,228,96)",
              "#FE5050",
            ], //,
            label: {
              normal:{
                position:'center',
                show:false,
                formatter:(data)=>{
                  return data.data.name+'\n\n'+data.data.value+"%"
                }
              }
            },
            labelLine: {
              show: false,
            },
            emphasis: {
              label: {
                show: true,
              }
            },
            labelLine: {
              show: false,
              length: 0,
              length2: 70,
            },
            data: val.sort(function (a, b) {
              return a.value - b.value;
            }),
          },
        ],
      };
      return option_2;
    },
    setoption_4(val) {
      // val.splice(8)
      var colorList = [
        [
          "#ff7f50",
          "#87cefa",
          "#da70d6",
          "#32cd32",
          "#6495ed",
          "#ff69b4",
          "#ba55d3",
          "#cd5c5c",
          "#ffa500",
          "#40e0d0",
          "#1e90ff",
          "#ff6347",
          "#7b68ee",
          "#d0648a",
          "#ffd700",
          "#6b8e23",
          "#4ea397",
          "#3cb371",
          "#b8860b",
          "#7bd9a5",
        ],
        [
          "#ff7f50",
          "#87cefa",
          "#da70d6",
          "#32cd32",
          "#6495ed",
          "#ff69b4",
          "#ba55d3",
          "#cd5c5c",
          "#ffa500",
          "#40e0d0",
          "#1e90ff",
          "#ff6347",
          "#7b68ee",
          "#00fa9a",
          "#ffd700",
          "#6b8e23",
          "#ff00ff",
          "#3cb371",
          "#b8860b",
          "#30e0e0",
        ],
        [
          "#929fff",
          "#9de0ff",
          "#ffa897",
          "#af87fe",
          "#7dc3fe",
          "#bb60b2",
          "#433e7c",
          "#f47a75",
          "#009db2",
          "#024b51",
          "#0780cf",
          "#765005",
          "#e75840",
          "#26ccd8",
          "#3685fe",
          "#9977ef",
          "#f5616f",
          "#f7b13f",
          "#f9e264",
          "#50c48f",
        ],
      ][2];
      var arr = [];
      val.forEach((el, index) => {
        const a = {
          name: el.name,
          value: el.value,
          symbolSize: 10,
          draggable: true,
          itemStyle: {
            normal: {
              shadowBlur: 100,
              shadowColor: colorList[index],
              color: colorList[index],
            },
          },
        };
        arr.push(a);
      });
      var option_4 = {
        // animationDurationUpdate: function (idx) {
        //   // 越往后的数据延迟越大
        //   return idx * 0.01;
        // },
        animationEasingUpdate: "bounceIn",
        color: ["#fff", "#fff", "#fff"],
        series: [
          {
            type: "graph",
            layout: "force",
            center: [150, 50],
            force: {
              repulsion: 50,
              edgeLength: 10,
            },
            roam: true,
            label: {
              normal: {
                show: true,
              },
            },
            data: arr,
          },
        ],
      };
      return option_4;
    },
  },
};
</script>
<style lang="scss" scoped>
.c_boxcopy {
  width: 100%;
  height: 100%;
  font-size: 0;
  .content {
    margin-top: height(20);
    width: 100%;
    .e_title {
      font-size: 0.14rem;
      color: #fff;
      font-weight: 600;
      padding: 0 0.08rem;
    }
    .first_box {
      width: 100%;
      .echart_1 {
        width: 100%;
        margin-top: height(10);
        height: height(130);
      }
    }
    .sec_box {
      display: flex;
      .sec_1 {
        flex: 1;
        padding-top:height(5) ;
        .es_echart {
          margin-top: height(10);
          width: 100%;
          height: height(110);
        }
      }
    }
    .third_box {
      margin-top: height(10);
      .echart_3 {
        margin-top: height(10);
        width: 100%;
        height: height(110);
      }
    }
  }
}
.tag_cate{
  margin-top: height(5);
  display: flex;
  height: height(36);
  justify-content: space-between;
  .tag_x{
    flex: 1;
    font-size: 0.16rem;
    color: #999;
    font-size: 600;
    line-height: height(36);
    position: relative;
    text-align: center;
    cursor: pointer;
    &.on{
      color: #00F8F9;
      &::after{
        content:'';
        width: 100%;
        height: 3px;
        background: #00F8F9;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}
</style>
