<template>
  <div class="left_top">
    <div class="title">白牛村土地流转</div>
    <v-switch :switchflag.sync='switchxx'></v-switch>
    <v-echart :option="option"></v-echart>
  </div>
</template>
<script>
export default {
  props: ["leftTop"],
  data() {
    return {
      option: null,
      switchxx:false,
      xdata: [],
      ydata: [],
    };
  },
  watch: {
    leftTop() {
      this.setEchart();
    }
  },
  mounted() {
    this.setEchart();
  },
  methods: {
    setEchart() {
      this.leftTop.forEach((el) => {
        this.xdata.push(el.name);
        this.ydata.push(parseFloat(el.value));
      });
      this.option = {
        title: {
          // text: "政策补贴额度",
          x: "center",
          y: "4%",
          textStyle: {
            color: "#fff",
            fontSize: "22",
          },
          subtextStyle: {
            color: "#90979c",
            fontSize: "16",
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          top: "40px",
          right: "50px",
          left: "50px",
          bottom: "40px",
        },
        xAxis: [
          {
            type: "category",
            data: this.xdata,
            axisLine: {
              lineStyle: {
                color: "rgba(255,255,255,0.12)",
              },
            },
            axisLabel: {
              margin: 10,
              color: "#e2e9ff",
              textStyle: {
                fontSize: 14,
              },
            },
          },
        ],
        yAxis: [
          {
            name: "单位：公顷",
            axisLabel: {
              formatter: "{value}",
              color: "#e2e9ff",
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "rgba(255,255,255,1)",
              },
            },
            splitLine: {
              lineStyle: {
                color: "rgba(255,255,255,0.12)",
              },
            },
          },
        ],
        series: [
          {
            type: "bar",
            data: this.ydata,
            barWidth: "20px",
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "rgba(0,244,255,1)", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "rgba(0,77,167,1)", // 100% 处的颜色
                    },
                  ],
                  false
                ),
                barBorderRadius: [30, 30, 30, 30],
                shadowColor: "rgba(0,160,221,1)",
                shadowBlur: 4,
              },
            },
            label: {
              normal: {
                show: true,
                //   backgroundColor: "rgba(0,160,221,0.1)",
                borderRadius: 200,
                position: "top",
                color: "#fff",
                distance: 1,
                //   formatter: [""," {a|{c}}\n", "{b|}"].join(","),
                rich: {
                  // d: {
                  //   color: "#3CDDCF",
                  // },
                  a: {
                    color: "#fff",
                    align: "center",
                  },
                  // b: {
                  //   width: 1,
                  //   height: 40,
                  //   borderWidth: 1,
                  //   borderColor: "#234e6c",
                  //   align: "left",
                  // },
                },
              },
            },
          },
        ],
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.left_top {
  width: 4.5rem;
  height: height(320);
  background-color: #0b132d;
  border-radius: 0.1rem;
  position: absolute;
  top: height(110);
  left: 0.2rem;
  z-index: 100;
  display: flex;
  flex-direction: column;
}
.left_top div:nth-child(2) {
  flex: 1;
}
</style>