<template>
  <div id="map"></div>
</template>
<script>
import fandian from "../assets/fandian.png";
import xiaoxue from "../assets/xiaoxue.png";
import yanglao from "../assets/yanglao.png";
import shangdian from "../assets/shangdian.png";
import caitan from "../assets/caitan.png";
import dianzi from "../assets/dianzi.png";
import nongzi from "../assets/nongzi.png";
import wenhua from "../assets/wenhua.png";
import wushui from "../assets/wushui.png";
import yiliao from "../assets/yiliao.png";
import sd from "../assets/szzc2.png";
import ds2 from "../assets/ds2.png";
import dataJson2 from "../data.json";
var viewer = null;
var overlay = []; //覆盖物
var mapurl = "http://127.0.0.1/25d/{z}/{x}/{y}.png";
/* var mapurl = "http://192.168.1.188:81/25d/{z}/{x}/{y}.png"; */
var serverurl = "/25d/{z}/{x}/{y}.png";

export default {
  watch: {
    "$store.state.list"() {
      console.log("覆盖物发生变化", this.$store.state.list);
      this.remove();
      this.$store.state.list.forEach((i) => {
        var x = this.addpointer(i);
        overlay.push(x);
      });
    },
    "$route.path"() {
      this.remove();
    },
  },
  mounted() {
    this.initMap();
  },
  methods: {
    //初始化地图
    initMap() {
      viewer = G.map("map", {
        center: [30.181942, 119.165313],
        zoom: 20,
        zoomControl: false,
      });

      var tdt = G.tileLayer
        .wmts(
          "http://t{s}.tianditu.com/img_w/wmts?tk=3de2f50033d7ed47d7f041399e3e4743",
          {
            layer: "img", //图层名称
            tilematrixSet: "w",
            tileSize: 256, //切片大小
            style: "default",
            format: "image/png",
            maxZoom: 18,
            minZoom: 3,
            subdomains: ["0", "1", "2", "3", "4", "5", "6", "7"],
          }
        )
        .addTo(viewer);

      var m25d = G.tileLayer(this.$route.query.v == 2 ? mapurl : serverurl, {
        minZoom: 14,
        maxZoom: 20,
        tms: true,
      }).addTo(viewer);

      this.set_center();
    },
    flyTo(x, y, z) {
      if (!x) {
        return;
      }
      viewer.panTo([x, y]);
      if (z) {
        setTimeout(() => {
          viewer.setZoom(21);
        }, 500);
      }

      /*  viewer.camera.flyTo({
        destination: Cesium.Cartesian3.fromDegrees(lon, lat, z || 250),
        orientation: new Cesium.HeadingPitchRange(0.8, -0.3, 0),
      });
      this.$root.$emit("showinfo"); */
    },
    //切换中心点
    set_center() {
      this.flyTo(30.181942, 119.165313);
    },
    // 删除覆盖物
    remove() {
      if (overlay.length != 0) {
        overlay.forEach((el) => {
          viewer.removeLayer(el);
        });
      }
    },
    //添加覆盖物
    addpointer(item) {
      var pin = G.marker([item.position[1], item.position[0]], {
        icon: G.icon({
          iconUrl: item.img,
          iconSize: [30, 30],
          // iconAnchor: [-15, 75],
        }),
      }).addTo(viewer);
      pin.on("click", (e) => {
        this.flyTo(item.position[1], item.position[0]);
        setTimeout(() => {
          viewer.setZoom(21);
        }, 200);
      });
      return pin;
    },
  },
};
</script>
<style lang="scss">
/* #map {
  width: 19.2rem;
  height: 100vh;
  overflow: hidden;
  z-index: 10;
  position: fixed;
  left: 0;
  top: 0;
  canvas {
    width: 19.2rem;
    height: 100vh;
  }
} */
</style>