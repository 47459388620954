<template>
  <div class="fixed_modal">
    <div class="pop">
      <div class="title">智能设备-景区监控</div>
      <div class="img_box">
        <div>
          <video src="" class="video"></video>
        </div>
        <div>
          <div class="txt" :title="info.name">设备名称：{{ info.name }}</div>
          <div class="txt">设备区域：{{ info.maincontent }}</div>
          <div class="txt">
            设备状态：<span
              :class="[info.status == '工作中' && 'bai', 'red']"
              >{{ info.status }}</span
            >
          </div>
        </div>
      </div>
      <div class="close_btn" @click="$parent.$parent.pop_info = null"></div>
    </div>
  </div>
</template>
<script >
export default {
  props: ["info"],
  data() {
    return {
      option: {},
    };
  },
  mounted() {
    this.player = flvjs.createPlayer({
      type: "flv",
      isLive: true,
      url: `ws://127.0.0.1:8888/rtsp/1?url=${this.info.url}`,
    });
    this.player.attachMediaElement(document.querySelector(".video"));
    try {
      this.player.load();
      this.player.play();
    } catch (error) {
      console.log(error);
    }
  },
  components: {},
};
</script>
<style lang="scss" scoped>
.fixed_modal {
  width: 6.1rem;
  height: height(315);
  border: solid 1px #142b5f;
  box-sizing: content-box;
  border-radius: 0.1rem;
  padding: 0.05rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 10000;
  .img_box {
    height: height(250);
    display: flex;
    align-items: center;
    & > div {
      font-size: 0;
      video {
        background: #000 url(../assets/loading.gif) no-repeat center center /
          auto auto;
        width: 4rem;
        height: height(225);
        border-radius: 0.1rem;
        margin-right: 0.2rem;
        object-fit: cover;
      }
      .txt {
        font-size: 0.14rem;
        width: 1.5rem;
        line-height: height(50);
        color: #fff;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        .red {
          color: #f34139;
          &.bai {
            color: #fff;
          }
        }
      }
    }
  }
  .pop {
    width: 100%;
    height: 100%;
    background: #001025;
    border-radius: 0.1rem;
    padding: 0 0.3rem;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.8),
      inset 0px 0px 30px 0px rgba(44, 87, 228, 0.5);
    .close_btn {
      width: 0.14rem;
      height: 0.14rem;
      background: url(../assets/close.png) no-repeat center center / auto
        0.14rem;
      position: absolute;
      top: height(15);
      right: 0.15rem;
    }
    .tag {
      font-size: 0.14rem;
      line-height: height(72);
      color: #fff;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-left: 0.1rem;
    }
    .title {
      font-size: 0.18rem;
      line-height: height(42);
      text-align: center;
      color: #fff;
      padding-top: height(10);
      text-align: left;
      span {
        color: #f34139;
        font-size: 0.12rem;
        padding-left: 0.2rem;
        margin-left: 0.2rem;
      }
    }
  }
}
</style>