<template>
  <div class="foot_tab">
      <div :class="[index.includes('coor')&&'on']" @click="handle_push('coor')">党员户</div>
      <div :class="[index.includes('di')&&'on']"  @click="handle_push('di')">低保人群</div>
      <div :class="[index.includes('bian')&&'on']"  @click="handle_push('bian')">边缘人群</div>
      <div :class="[index.includes('can')&&'on']"  @click="handle_push('can')">残疾人群</div>
  </div>
</template>
<script>
import {get_type} from "../api"
import coor from "../assets/sz1.png"
import di from "../assets/sz2.png"
import bian from "../assets/sz4.png"
import can from "../assets/sz3.png"

export default {
    data(){
        return {
            index:["coor"],
            img:{
                coor,
                di,
                bian,
                can
            },
            data:{}
        }
    },
    watch:{
        index(){
            this.handle_click();
        }
    },
    async created() {
        let {result}= await get_type()
        this.data=result
        this.handle_click()
    },
    methods: {
        handle_push(str){
            if(this.index.includes(str)){
                this.index.splice(this.index.indexOf(str),1)
            }else{
                this.index.push(str);
            }
        },
        handle_click(){
            this.$store.commit("SET_LIST",this.index.map(index=>{
                return this.data[index].map(item=>{
                if(item.lon){
                    return {
                        img:this.img[index],
                        position:[item.lon,item.lat]
                    }
                }else{
                    return null
                }
            }).filter(item=>item)
            }).flat(1))
        }
    },
}
</script>
<style lang="scss">
.foot_tab {
  width: 1.4rem;
  height: height(140);
  background-color: rgba(11,19,45,0.5);
  border-radius: 0.1rem;
  position: fixed;
  right:4.9rem;
  bottom: height(130);
  z-index: 1000;
  font-size: 0.16rem;
  box-sizing: border-box;
  color: #fff;
  padding: 0.1rem 0;
  div{
  line-height: height(32);
      padding-left: 0.5rem;
      position: relative;
      cursor: pointer;
      &::before{
          content: "";
          width: 0.2rem;
          height: 0.2rem;
          background: rgb(11,19,45);
          position: absolute;
          top: height(9);
          left: 0.2rem;
      }
      &.on::before{
          background: rgb(11,19,45) url(../assets/ggh.png) no-repeat center/auto 0.13rem;

      }
  }
}
</style>