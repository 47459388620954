<template>
  <div class="bg-box">
    <div class="mainboxxx">
      <div class="main_line1">
        <div class="m_left">
          <div class="m_title"></div>
          <div class="m_m_left">
            <div class="big_top_box">
              <div class="t_sym_box">垃圾分类</div>
              <div class="b_flex_box">
                <div class="b_flex">
                  <div class="b_flex_small">
                    <div class="b_flex_small_txt_1">
                      {{rubbish.xunluo }}
                    </div>
                    <div class="b_flex_small_txt_2">巡逻员</div>
                  </div>
                </div>
                <div class="b_flex">
                  <div class="b_flex_small">
                    <div class="b_flex_small_txt_1">
                      {{rubbish.other }}
                    </div>
                    <div class="b_flex_small_txt_2">其他垃圾</div>
                  </div>
                </div>
                <div class="b_flex">
                  <div class="b_flex_small">
                    <div class="b_flex_small_txt_1">
                      {{rubbish.shenghuo }}
                    </div>
                    <div class="b_flex_small_txt_2">易腐垃圾</div>
                  </div>
                </div>
                <div class="b_flex">
                  <div class="b_flex_small">
                    <div class="b_flex_small_txt_1">
                      {{rubbish.canyu }}
                    </div>
                    <div class="b_flex_small_txt_2">居民参与率</div>
                  </div>
                </div>
                <div class="b_flex">
                  <div class="b_flex_small">
                    <div class="b_flex_small_txt_1">
                      {{rubbish.zhunque }}
                    </div>
                    <div class="b_flex_small_txt_2">分类准确率</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main_line3">
        <div class="m_left">
          <div class="fzfwboxxxx">
            <div class="m_title t5"></div>
            <div class="fzfw_flex">
              <div class="m_m_left">
                <div>
                  <div class="fzfwbox">
                    <div class="fzfw_1">
                      <div class="b_r_bot_left_t1">
                        {{ rule.tiaojieyuan }}
                      </div>
                      <div class="b_r_bot_left_t2">村民调解员</div>
                    </div>
                  </div>
                  <div class="b_r_bot_right">
                    <span class="b_r_bot_right_t1">调解次数</span>
                    <span class="b_r_bot_right_t2" style="color: #809146"
                      >{{ rule.cishu }}次</span
                    >
                  </div>
                </div>

                <div>
                  <div class="fzfwbox">
                    <div class="fzfw_1">
                      <div class="b_r_bot_left_t1">
                        {{ rule.guwen }}
                      </div>
                      <div class="b_r_bot_left_t2">村民顾问</div>
                    </div>
                  </div>
                  <div class="b_r_bot_right">
                    <span class="b_r_bot_right_t1">咨询次数</span>
                    <span class="b_r_bot_right_t2" style="color: #809146"
                      >{{ rule.zixun }}次</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="jyxsbox">
            <div class="m_title t6"></div>
            <div class="jyxsitembox">
              <div class="ci c1">宣传单</div>
              <div class="ci c2">微信QQ群宣传</div>
              <div class="ci c3">走访</div>
            </div>
          </div>
        </div>
        <div class="m_right">
          <div class="m_title t7"></div>
          <div class="m_x_2 ec">
            <v-echart :option="dataBuild"></v-echart>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props:['rubbish','build','edu','rule'],
  components: {},
  data() {
    return {
      dataBuild:null
    };
  },
  created() {
    this.setopt_dataBuild()
  },
  watch:{
    build(){
      this.setopt_dataBuild()
    }
  },
  methods: {
    setopt_dataBuild(){
      var dataX = [];
      var a1 = [];
      var a2 = [];
      var a3 = [];
      this.build.map((el) => {
        dataX.push(el.year);
        a1.push(el.is_build);
        a2.push(el.not_build);
        a3.push(el.completed);
      });
      this.dataBuild = {
        legend: {
          bottom: "0%",
          textStyle: {
            color: "#85abf3",
          },
          icon:'circle',
          data: ["在建", "未建", "建成"],
        },
        grid: {
          left: "0%",
          top: "5%",
          bottom: "25%",
          right: "0%",
        },
        xAxis: [
          {
            axisLabel: {
              show: true,
              textStyle: {
                color: "#85abf3",
              },
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            data: dataX,
          },
        ],
        yAxis: [
          {
            type: "value",

            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: "在建",
            type: "line",
            itemStyle: {
              color: "#ffa441",
              borderWidth:4
            },
            lineStyle:{
              width:3
            },
            label: {
              show: true,
              position: "top",
              fontSize: 12,
              color: "#fff",
            },
            emphasis: {
              focus: "series",
            },
            data: a1,
          },
          {
            name: "未建",
            type: "line",
            itemStyle: {
              color: "#22f289",
              borderWidth:4
            },
            lineStyle:{
              width:3
            },
            label: {
              show: true,
              fontSize: 12,
              position: "top",
              color: "#fff",
            },
            emphasis: {
              focus: "series",
            },
            data: a2,
          },
          {
            name: "建成",
            type: "line",
            itemStyle: {
              color: "#b60cb8",
              borderWidth:4
            },
            lineStyle:{
              width:3
            },
            label: {
              show: true,
              position: "top",
              fontSize: 12,
              color: "#fff",
            },
            emphasis: {
              focus: "series",
            },
            data: a3,
          }
        ]
      }
    }
  },
};
</script>
<style scoped lang="scss">
.bg-box {
  height: 100vh;
  width: 11.4rem;
  position: fixed;
  top: 0;
  left: 3.9rem;
  z-index: 999;
}
.m_title {
  width: 100%;
  height: height(62);
  background: url(../assets/ljfl.png) no-repeat center/1.6rem height(21);
  &.t2 {
    background: url(../assets/wmh.png) no-repeat center/2.35rem height(21);
  }
  &.t3 {
    background: url(../assets/dyhdqk.png) no-repeat center/1.98rem height(21);
  }
  &.t4 {
    background: url(../assets/zyz.png) no-repeat center/1.82rem height(21);
  }
  &.t5 {
    background: url(../assets/fzfw.png) no-repeat center/1.64rem height(21);
  }
  &.t6 {
    background: url(../assets/jyxs.png) no-repeat center/2.02rem height(21);
  }
  &.t7 {
    background: url(../assets/jfgl.png) no-repeat center/2.02rem height(21);
  }
}
.mainboxxx {
  margin: 0 auto;
  width: 11.4rem;
  z-index: 1000;
  overflow: hidden;
  .main_line1 {
    margin-top: height(198);
    display: flex;
    &.main_line2 {
      margin-top: height(307);
      margin-top: height(15);
    }
    .m_left {
      width: 100%;
      height: height(198);
      background: rgba(5, 11, 44, 0.6);
      padding: 0 0.3rem;
      padding-bottom: height(30);
      .m_m_left {
        width: 100%;
        display: flex;
        &.mmmm {
          background: none;
        }
        .m_x_1 {
          flex: 1;
          height: height(200);
        }
      }
    }
    .m_right {
      flex: 1;
      margin-left: 0.16rem;
      height: height(294);
      background: rgba(5, 11, 44, 0.6);
      padding: 0 0.3rem;
      padding-bottom: height(30);
      .m_x_2 {
        width: 100%;
        height: height(200);
      }
    }
  }
}
.big_top_box {
  width: 100%;
  display: flex;
  align-items: center;
}
.b_flex_box {
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.b_flex {
  width: 1.8rem;
  height: height(98);
  background: url(../assets/1000.png) no-repeat center/1.49rem height(70);
  background-color: rgba(4, 16, 63, 0.5);
  border-radius: 0.08rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: height(14) 0.16rem;
}
.b_flex_small {
  width: 100%;
  overflow: hidden;
}
.b_flex_small_txt_1 {
  font-size: 0.22rem;
  color: #faaa5a;
  text-align: center;
}
.b_flex_small_txt_2 {
  font-size: 0.16rem;
  color: #26add0;
  font-weight: bold;
  text-align: center;
}
.t_sym_box {
  width: 0.69rem;
  height: height(79);
  text-align: center;
  font-size: 0.17rem;
  margin-right: 0.3rem;
  padding-top: height(62);
  background: url(../assets/36.png) no-repeat top center/0.4rem height(50);
}

.main_line3 {
  width: 100%;
  height: height(435);
  margin-top: height(15);
  display: flex;

  .m_right{
    flex: 1;
    height: 100%;
    background: rgba(4, 16, 63, 0.5);
    margin-left: 0.16rem;
    .m_x_2 {
        width: 100%;
        height: height(335);
      }
  }
  .fzfwboxxxx {
    width: 4.82rem;
    height: height(247);
    background: rgba(4, 16, 63, 0.5);
    .fzfw_flex {
      display: flex;
        padding: 0 0.3rem;
      .m_m_left {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .fzfwbox {
          margin: 0 auto;
          overflow: hidden;
          text-align: right;
          width: 1.32rem;
          height: height(72);
          background: url(../assets/g39.png) no-repeat center/100% height(72);
          .fzfw_1 {
            .b_r_bot_left_t1 {
              line-height: 1;
              padding-right: 0.4rem;
              margin-top: height(9);
              color: #ffbd7b;
            }
            .b_r_bot_left_t2 {
              margin-top: height(25);
              padding-right: 0.2rem;
            }
          }
        }
      }
    }
  }
}

.b_r_bot_right {
  margin-top: height(15);
  width: 2rem;
  height: height(62);
  line-height: height(62);
  text-align: center;
  font-size: 0.16rem;
  display: flex;
  justify-content: space-between;
  background: rgba(4, 16, 63, 0.5);
  padding: 0 0.2rem;
}

.jyxsbox {
  margin-top: height(16);
  width: 100%;
  height: height(172);
  background: rgba(4, 16, 63, 0.5);
  .jyxsitembox{
    width: 100%;
    padding: 0 0.3rem;
    padding-bottom: 0.3rem;
    position: relative;
    .ci{
      position: absolute;
      width: 0.7rem;
	    height: height(70);
	    background-color: rgba(10,57,144, 0.7);;
	    border: solid 1px #75abe7;
	    opacity: 0.6;
      border-radius: 100%;
      font-size: 0.13rem;
      text-align: center;
      line-height: height(70);
      &.c1{
        left: 0.56rem;
        top: 0;
      }
      &.c2{
        left: 1.76rem;
        top: height(20);
      }
      &.c3{
        right: 0.56rem;
        top: 0;
      }
    }
  }
}
</style>
