<template>
  <div class="bg-box">
    <div class="mainboxxx">
      <div class="main_line1">
        <div class="m_left">
          <div class="m_title"></div>
          <div class="m_m_left">
            <div class="m_x_1">
              <v-echart :option="dataAge"></v-echart>
            </div>
            <div class="m_x_1">
              <v-echart :option="dataDis"></v-echart>
            </div>
          </div>
        </div>
        <div class="m_right">
          <div class="m_title t2"></div>
          <div class="m_x_2">
            <v-echart :option="datahouse"></v-echart>
          </div>
        </div>
      </div>
      <div class="main_line1 main_line2">
        <div class="m_left">
          <div class="m_title t3"></div>
          <div class="m_m_left mmmm">
            <v-echart :option="dyhd"></v-echart>
          </div>
        </div>
        <div class="m_right">
          <div class="m_title t4"></div>
          <div class="m_x_2">
            <v-echart :option="zyzsj"></v-echart>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaiPie from "./BaiPie";
import BaiPie2 from "./BaiPie2";
import BaiBar2 from "./BaiBar2";
var colorList = [
  "#fffb72",
  "#54edfe",
  "#4bf6b8",
  "#f3697b",
];
export default {
  props: ["data16", "disArray", "houseArray", "partyArray", "zhiyuanArray"],
  components: {
    BaiPie,
    BaiPie2,
    BaiBar2,
  },
  data() {
    return {
      dyhd: null,
      zyzsj: null,
      datahouse: null,
      dataAge:null,
      dataDis:null
    };
  },
  created() {
    this.setopt();
    this.setopt_2();
    this.setopt_house();
    this.setopt_dataAge();
    this.setopt_dataDis();
  },
  watch: {
    partyArray() {
      this.setopt();
    },
    zhiyuanArray() {
      this.setopt_2();
    },
    houseArray() {
      this.setopt_house();
    },
    data16(){
      this.setopt_dataAge();
    },
    disArray(){
      this.setopt_dataDis();
    }
  },
  methods: {
    setopt() {
      var dataX = [];
      var a1 = [];
      var a2 = [];
      var a3 = [];
      var a4 = [];
      var a5 = [];
      this.partyArray.map((el) => {
        dataX.push(el.date);
        a1.push(el.should);
        a2.push(el.real);
        a3.push(el.should_member);
        a4.push(el.real_member);
        a5.push(el.not_member);
      });
      this.dyhd = {
        legend: {
          bottom: "0%",
          textStyle: {
            color: "#85abf3",
          },
          data: ["应到", "实到", "应到流动", "实到流动", "未到流动"],
        },
        grid: {
          left: "0%",
          top: "5%",
          bottom: "25%",
          right: "0%",
        },
        xAxis: [
          {
            axisLabel: {
              show: true,
              textStyle: {
                color: "#85abf3",
              },
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            data: dataX,
          },
        ],
        yAxis: [
          {
            type: "value",
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: "应到",
            type: "bar",
            barGap: 0,
            itemStyle: {
              color: "#4C98FB",
            },
            label: {
              show: true,
              position: "top",
              fontSize: 12,
              color: "#fff",
            },
            emphasis: {
              focus: "series",
            },
            data: a1,
          },
          {
            name: "实到",
            type: "bar",
            itemStyle: {
              color: "#73DEBD",
            },
            label: {
              show: true,
              fontSize: 12,
              position: "top",
              color: "#fff",
            },
            emphasis: {
              focus: "series",
            },
            data: a2,
          },
          {
            name: "应到流动",
            type: "bar",
            itemStyle: {
              color: "#8CDF6C",
            },
            label: {
              show: true,
              position: "top",
              fontSize: 12,
              color: "#fff",
            },
            emphasis: {
              focus: "series",
            },
            data: a3,
          },
          {
            name: "实到流动",
            type: "bar",
            itemStyle: {
              color: "#F56679",
            },
            label: {
              show: true,
              position: "top",
              fontSize: 12,
              color: "#fff",
            },
            emphasis: {
              focus: "series",
            },
            data: a4,
          },
          {
            name: "未到流动",
            type: "bar",
            itemStyle: {
              color: "#9D50E0",
            },
            label: {
              show: true,
              position: "top",
              fontSize: 12,
              color: "#fff",
            },
            emphasis: {
              focus: "series",
            },
            data: a5,
          },
        ],
      };
    },
    setopt_2() {
      var nameArray = [];
      var dataArray = [];
      this.zhiyuanArray.map((el) => {
        nameArray.push(el.name);
        dataArray.push(el.value);
      });
      this.zyzsj = {
        legend: {
          show: false,
        },
        grid: {
          left: "18%",
          top: "5%",
          bottom: "10%",
          right: "8%",
        },
        xAxis: {
          type: "value",

          splitLine: {
            show: false,
          },
          axisLabel: {
            color: "#85abf3",
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
        },
        yAxis: {
          type: "category",
          inverse: true,
          axisLabel: {
            show: true,
            textStyle: {
              color: "#85abf3",
            },
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          data: nameArray,
        },
        series: [
          {
            z: 2,
            name: "value",
            type: "bar",
            data: dataArray,
            barCategoryGap: "70%",
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                  {
                    offset: 0,
                    color: "#0055fd",
                  },
                  {
                    offset: 1,
                    color: "#00aaff",
                  },
                ]),
              },
            },
            label: {
              show: true,
              position: "right",
              color: "#fff",
              fontSize: 14,
              offset: [0, 0],
            },
          },
        ],
      };
    },
    setopt_house() {
      var dataxhouse = [];
      var datayhouse = [];
      this.houseArray.map((el) => {
        dataxhouse.push(el.name);
        datayhouse.push(el.value);
      });
      console.log(dataxhouse, 111111111111);
      this.datahouse = {
        legend: {
          show: false,
        },
        grid: {
          left: "8%",
          top: "5%",
          bottom: "10%",
          right: "8%",
        },
        yAxis: {
          type: "value",

          splitLine: {
            show: false,
          },
          axisLabel: {
            color: "#85abf3",
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
        },
        xAxis: {
          type: "category",
          inverse: true,
          axisLabel: {
            show: true,
            textStyle: {
              color: "#85abf3",
            },
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          data: dataxhouse,
        },
        series: [
          {
            z: 2,
            name: "value",
            type: "bar",
            data: datayhouse,
            barCategoryGap: "70%",
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                  {
                    offset: 0,
                    color: "#0055fd",
                  },
                  {
                    offset: 1,
                    color: "#00aaff",
                  },
                ]),
              },
            },
            label: {
              show: true,
              position: "top",
              color: "#fff",
              fontSize: 14,
              offset: [0, 0],
            },
          },
        ],
      };
    },
    setopt_dataAge(){
    var sum =0;
    this.data16.forEach(el => {
      sum = el.value+sum;
    });
    this.data16.forEach(el => {
      el.value = (el.value/sum).toFixed(1)*100
    });
      this.dataAge = {
        series: [
          {
            type: "pie",
            top: "-8%",
            left: "20%",
            center: ["50%", "60%"],
            radius: ["35%", "55%"],
            roseType: "",
            itemStyle: {
              normal: {
                color: function (params) {
                  return colorList[params.dataIndex];
                },
              },
            },
            label: {
              show: true,
              position: "outside",
              formatter:function(params){
                  return '{a|'+params.value+'%}\n{b|'+params.name+'}'
              },
              rich:{
                a:{
                  fontSize:24,
                  fontWeight:'bold',
                },
                b:{
                  fontSize:14,
                  fontWeight:'bold',
                }
              }
            },
            labelLine: {
              length: 10,
              length2: 30,
              smooth: false,
            },
            data: this.data16,
          },
        ],
      }
    },
    setopt_dataDis(){
      var sum =0;
    this.disArray.forEach(el => {
      sum = el.value+sum;
    });
    this.dataDis = {
        series: [
          {
            type: "pie",
            top: "-8%",
            left: "20%",
            center: ["50%", "60%"],
            radius: ["35%", "55%"],
            roseType: "",
            itemStyle: {
              normal: {
                color: function (params) {
                  return colorList[params.dataIndex];
                },
              },
            },
            label: {
              show: true,
              position: "outside",
              formatter:function(params){
                  return '{a|'+params.value+'}{c|}\n{b|'+params.name+'}'
              },
              rich:{
                a:{
                  fontSize:16,
                  fontWeight:'bold',
                },
                b:{
                  fontSize:12,
                  fontWeight:'bold',
                },
                c:{
                  fontSize:12,
                  fontWeight:'bold'
                }
              }
            },
            labelLine: {
              smooth: false,
            },
            data: this.disArray,
          },
        ],
      }
    }
  },
};
</script>
<style scoped lang="scss">
.bg-box {
  height: 100vh;
  width: 11.4rem;
  position: fixed;
  top: 0;
  left: 3.9rem;
  z-index: 999;
}

.m_title {
  width: 100%;
  height: height(62);
  background: url(../assets/rkgk.png) no-repeat center/1.6rem height(21);
  &.t2 {
    background: url(../assets/wmh.png) no-repeat center/2.35rem height(21);
  }
  &.t3 {
    background: url(../assets/dyhdqk.png) no-repeat center/1.98rem height(21);
  }
  &.t4 {
    background: url(../assets/zyz.png) no-repeat center/1.82rem height(21);
  }
}
.mainboxxx {
  margin: 0 auto;
  width: 11.4rem;
  z-index: 1000;
  overflow: hidden;
  .main_line1 {
    margin-top: height(232);
    display: flex;
    &.main_line2 {
      margin-top: height(307);
      margin-top: height(15);
    }
    .m_left {
      width: 6.82rem;
      height: height(294);
      background: rgba(5, 11, 44, 0.6);
      padding: 0 0.3rem;
      padding-bottom: height(30);
      .m_m_left {
        width: 100%;
        height: height(200);
        display: flex;
        background: url(../assets/bjxian.png) no-repeat center/100% 100%;
        &.mmmm {
          background: none;
        }
        .m_x_1 {
          flex: 1;
          height: height(200);
        }
      }
    }
    .m_right {
      flex: 1;
      margin-left: 0.16rem;
      height: height(294);
      background: rgba(5, 11, 44, 0.6);
      padding: 0 0.3rem;
      padding-bottom: height(30);
      .m_x_2 {
        width: 100%;
        height: height(200);
      }
    }
  }
}
</style>