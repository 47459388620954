<template>
  <div class="modal">
    <div class="model">
      <div class="close" @click="$parent.show = 0,$parent.onchecked_2={}"></div>
      <div class="title">同心荟核心成员介绍</div>
      <div class="con">
        <div class="left">
          <img :src="urlx+obj.data.img" alt="" />
          <div class="name">{{obj.data.name}} · 同心荟核心成员</div>
        </div>
        <div class="right">
          <div class="h1">基本信息</div>
          <p>
            {{obj.data.desc}}
          </p>
          <div class="hr20"></div>
          <div class="hr20"></div>
          <div class="h1">获得荣誉</div>
          <p v-for="(item,index) in obj.data.honor">
            {{item}}
          </p>
          <div class="hr20"></div>
          <div class="hr20"></div>
          <!-- <div class="h1">直播间链接</div>
          <p>
              TWICE TV “第29届首尔歌谣大赏” <br />
            TWICE TV “第34届韩国金唱片大奖 DAY 2” <br />
            TWICE TV “ONCE HALLOWEEN 2”
          </p> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props:['obj'],
  mounted() {
    console.log(this.obj)
  },
}
</script>
<style lang="scss" scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.9);
  z-index: 1000;
  .model {
    width: 14.3rem;
    height: height(900);
    background: #00082b;
    border: 2px solid #0b3ba0;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    z-index: 1000;
    padding: 0 0.63rem;
  }
  .title {
    line-height: height(88);
    font-size: 0.26rem;
    color: #fff;
    text-align: center;
  }
  .con {
    display: flex;
    .left {
      width: 4.3rem;
      font-size: 0;
      margin-right: 0.32rem;
      img {
        width: 4.3rem;
        height: 5.73rem;
        /* object-fit: cover;  */
        object-fit: contain; 
      }
      .name {
        font-size: 0.26rem;
        color: #fff;
        line-height: 1;
        margin-top: height(25);
        text-align: center;
        font-weight: bold;
      }
    }
    .right {
      .h1 {
        font-size: 0.28rem;
        color: #fff;
        font-weight: bold;
        line-height: 1;
        margin-bottom: height(20);
      }
      p {
        font-size: 0.2rem;
        line-height: height(36);
        color: #fff;
        font-weight: normal;
      }
    }
  }
  .close {
    width: 0.32rem;
    height: 0.32rem;
    background: url(../../../assets/gb6.png);
    background-size: 100% 100%;
    position: absolute;
    bottom: -0.52rem;
    left: 50%;
    transform: translateX(-0.16rem);
    cursor: pointer;
  }
}
</style>