<template>
  <div class="map_box">
    <div id="map"></div>
    <v-pop :info="pop_info" v-if="pop_info"/>
  </div>
</template>
<script>
import Event from '../utils/event.js'
import fandian from "../assets/fandian.png";
import xiaoxue from "../assets/xiaoxue.png";
import yanglao from "../assets/yanglao.png";
import shangdian from "../assets/shangdian.png";
import caitan from "../assets/caitan.png";
import dianzi from "../assets/dianzi.png";
import nongzi from "../assets/nongzi.png";
import wenhua from "../assets/wenhua.png";
import wushui from "../assets/wushui.png";
import yiliao from "../assets/yiliao.png";
import sd from "../assets/szzc2.png";
import ds2 from "../assets/ds2.png";
import dataJson2 from "../data.json";
import divicon from './divicon'
import dsh from "../assets/dshz.png";
import cgb from '../assets/cgb.png'

import dyds from "../assets/dyds.png";
import Vue from "vue/dist/vue.common.js"
import vPop from "./pop"
var viewer = null;
var overlay = []; //覆盖物
var item_child = null;
/* var mapurl = "http://127.0.0.1/25d/{z}/{x}/{y}.png"; */
var mapurl = "http://agul.vaiwan.com/25d/{z}/{x}/{y}.png";
var serverurl = "/25d/{z}/{x}/{y}.png";
export default {
  data(){
    return {
      pop_info:null,
      xlist:[],
    }
  },
  components:{
    vPop
  },
  watch: {
    "$store.state.list"() {
      console.log("覆盖物发生变化", this.$store.state.list);
      this.remove();
      this.$store.state.list.forEach((i) => {
        var x = this.addpointer(i);
        overlay.push(x);
      });
    },
    "$route.path"() {
      this.remove();
    },
  },
  created(){
    Event.$on('closeCoor',(res)=>{
      if(res.type=='coorDS'){
        item_child=null;
        this.$store.commit('SET_LIST',this.xlist)
      }
    })
  },
  mounted() {
    this.initMap();
  },
  methods: {
    //初始化地图
    initMap() {
      viewer = G.map("map", {
        center: [30.181942, 119.165313],
        zoom: 20,
        zoomControl: false,
      });
      window.map=viewer
      var tdt = G.tileLayer
        .wmts(
          "http://t{s}.tianditu.com/img_w/wmts?tk=3de2f50033d7ed47d7f041399e3e4743",
          {
            layer: "img", //图层名称
            tilematrixSet: "w",
            tileSize: 256, //切片大小
            style: "default",
            format: "image/png",
            maxZoom: 18,
            minZoom: 3,
            subdomains: ["0", "1", "2", "3", "4", "5", "6", "7"],
          }
        )
        .addTo(viewer);

      var m25d = G.tileLayer(this.$route.query.v == 2 ? mapurl : serverurl, {
        minZoom: 14,
        maxZoom: 20,
        tms: true,
      }).addTo(viewer);

      this.set_center();
    },
    flyTo(x, y, z) {
      if (!x) {
        return;
      }
      viewer.panTo([x, y]);
      if (z) {
        setTimeout(() => {
          viewer.setZoom(21);
        }, 500);
      }

      /*  viewer.camera.flyTo({
        destination: Cesium.Cartesian3.fromDegrees(lon, lat, z || 250),
        orientation: new Cesium.HeadingPitchRange(0.8, -0.3, 0),
      });
      this.$root.$emit("showinfo"); */
    },
    //切换中心点
    set_center() {
      this.flyTo(30.181942, 119.165313);
    },
    // 删除覆盖物
    remove() {
      if (overlay.length != 0) {
        overlay.forEach((el) => {
          viewer.removeLayer(el);
        });
      }
    },
    //添加覆盖物
    addpointer(item) {
      var pin = G.marker([item.position[1], item.position[0]], {
        icon: G.divIcon({
          html:new Vue({...divicon,data(){return{a:item.name,b:item.type,c:item.img,info:item}}}).$mount().$el.innerHTML,
          offset:[1,10]
        }),
      }).addTo(viewer);
      pin.on("click", (e) => {
        if(item_child){
          return;
        }
        if(item.type=='coorDS'){
          this.xlist = this.$store.state.list;
          /* console.log(xlist,8888888888) */
          item.info.type=item.type;
          this.pop_info=item.info;
          console.log(item,7897987897897987987)
          item_child=[];
          item_child.push(item);
          item.info.circle.map(el=>{
            item_child.push({
              ...el,
              line:true,
              fposition:item.position,
              img:el.circle_type=='2'?cgb:el.circle_type=='1'?dyds:dsh,
              position:[el.lon, el.lat],
              type:el.circle_type=='2'?'cgb':el.circle_type=='1'?'coorDS':'dsh'
            })
          })
          console.log(item_child,333333333)
          this.$store.commit('SET_LIST',item_child)
          console.log(this.$store.state)
          var polyline = G.polyline([[22.54369, 114.10827],[22.54317, 114.11224]]).addTo(viewer);
        }else{
          item.info.type=item.type;
          this.pop_info=item.info;
        }
        console.log(item,78978789789)
        // this.flyTo(item.position[1], item.position[0]);
        // setTimeout(() => {
        //   viewer.setZoom(21);
        // }, 200);
      });
      return pin;
    },
  },
};
</script>
<style lang="scss">
.map_box{
  width: 100%;
  height: 100%;
}
#map {
 width: 100%;
  height: 100%;
  canvas {
    width: 100%;
    height: 100%;
  }
}
</style>