<template>
  <div :class="[txt == 'cyxw' && isShow && 'on', 'siderBtnbox']">
    <div
      :class="[strx.includes('dsh') && 'on', 'siderBtn']"
      @click.stop="showSmybol('dsh')"
    >
      <div class="zcate_check" :class="[strx.includes('dsh') && 'on']"></div>
      <div class="zcate_name">电商分布</div>
    </div>
    <div class="siderBtn" @click.stop="showSmybol('dsfz', 21)">
      <div class="zcate_check" :class="[strx.includes('dsfz') && 'on']"></div>
      <div class="zcate_name">电商发展</div>
    </div>
  </div>
</template>
<script >
import Event from "../utils/event";
import img from "../assets/zp.png";
export default {
  data() {
    return {
      strx: [],
      isShow: false,
      txt: [],
    };
  },
  created() {
    Event.$on("showSymbolBtn", (show, str) => {
      if (!show || (str != "cyxw" && this.txt != str)) {
        this.strx = [];
      }
      this.txt = str;
      this.isShow = show;
    });
  },
  methods: {
    showSmybol(str, iss2) {
      if (!iss2) {
        Event.$emit("changeSmy", str);
      } else {
        Event.$emit("changeSmy", this.strx[0]);
      }
      this.$parent.$parent.show = false;
      this.$parent.$parent.poptype = false;
      if (this.strx.includes(str)) {
        this.strx.splice(this.strx.indexOf(str), 1);
      } else {
        this.strx = [str];
        if (iss2) {
          this.$parent.$parent.show = true;
          this.$parent.$parent.poptype = iss2;
        } else {
          /* this.strx.push(str); */
        }
      }
      
    },
  },
};
</script>
<style lang="scss" scoped>
.siderBtnbox {
  position: absolute;
  // bottom: height(164);
  padding: height(15) 0.15rem;
  width: 1.2rem;
  height: auto;
  background: rgba(0, 16, 37, 0.6);
  border-radius: 5px;
  top: height(-10);
  transform: translateY(-100%);
  left: 0;
  z-index: 99999999;
  display: none;
  &.on {
    display: block;
  }
}
.siderBtn {
  width: 1rem;
  height: height(26);
  background: rgba(0, 16, 37, 0.3);
  border-radius: 0.05rem;
  font-size: 0.14rem;
  color: #fff;
  display: flex;
  line-height: height(26);
  align-items: center;
  cursor: pointer;
  .zcate_check {
    width: 16px;
    height: 16px;
    border: 1px solid #aaaaaa;
    &.on {
      background: url(../assets/ticket.png) no-repeat center/100% 100%;
    }
  }
  .zcate_name {
    flex: 1;
    font-size: 0.14rem;
    color: #fff;
    line-height: height(16);
    text-align: left;
    margin-left: 0.1rem;
  }
}
</style>