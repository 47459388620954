import img from "../../../assets/asd.png"
import p_data from '../data/people.json'

var dataFormart = function(val){
    var arr = [];
    val.map(el=>{
        var a = {
            fromName: '合肥',
            toName: '上海',
            count: '1',
            amount: '89999',
            coords: [
                [119.212, 30.257],
                el.value
            ],
            lineStyle:{
                color:el.desc!=''?'red':'#60ffcc'
            }
        }
        arr.push(a)
    });
    return arr;
}

var option = {
    // tooltip: {
    //     triggerOn: "onmousemove",
    // },
    tooltip: {
            backgroundColor:"rgba(0,0,0,0)",
            trigger: "item",
            position:"top",
            confine: true,
            enterable: true,
            renderMode: "html",
            appendToBody: true,
            formatter: function(d){
              return `<div class="item_box1">
               <div class="l">主播姓名：${d.name}</div>
               <div class="l" style="display:${d.data.desc?'block':'none'}" onclick='showTag(1,${JSON.stringify(d)})'>详细介绍：<span>点击查看</span></div>
               <div class="l" style="display:${d.data.video?'block':'none'}" onclick='showTag(4,${JSON.stringify(d)})'>视频连接：<span>点击查看</span></div>
              </div>`
            }
    },
    geo: {
        map: 'linan',
        label: {
            show: false
        },
        itemStyle: {
            normal: {
                areaColor: 'rgba(16,23,59,0.6)',
                borderWidth: 0.8, //设置外层边框
                borderColor: '#1a5ac1',
            },
            emphasis: {
                areaColor: 'rgba(16,23,59,0.6)',
                borderWidth: 0.8, //设置外层边框
                borderColor: '#1a5ac1',
            }
        },
        
        emphasis: {
            label: {
                show: false,
            }
        }

    },
    series:[
        {
            type: 'effectScatter',
            coordinateSystem: 'geo',
            showEffectOn: 'render',
            rippleEffect: {
                period: 15,
                scale: 2,
                brushType: 'fill'
            },
            hoverAnimation: true,
            symbolSize:20,
            itemStyle: {
                normal: {
                    color: '#e01e2a',
                    shadowBlur: 2,
                    shadowColor: '#333'
                }
            },
            data: [            {
                name: '临安区',
                value: [119.212, 30.257]
            }],
            label:{
                show:true,
                position: 'top',
                color:"#fff",
                formatter: function(d){
                    return `同心荟`
                  }
            },
            tooltip: {
                show:false,
                // showContent:true,
                // alwaysShowContent:true,
                // trigger: "item",
                // position:"top",
                // confine: true,
                // enterable: false,
                // renderMode: "html",
                // appendToBody: true,
                // formatter: function(d){
                //   return `<img class="img_logo" src="${img}" />`
                // }
            },
        },
        {
            type: 'effectScatter',
            coordinateSystem: 'geo',
            showEffectOn: 'render',
            rippleEffect: {
                period: 10,
                scale: 1,
                brushType: 'fill'
            },
            symbolSize:15,
            hoverAnimation: true,
            label:{
                show:true,
                position:'top',
                color:'#fff',
                fontSize:16,
                formatter:(param)=>{
                    var str = '';
                    if(param.data.video!=''||param.data.desc!=''){
                        str = param.data.name
                    }
                    return str;
                }
            },
            itemStyle: {
                normal: {
                    color: function(d){
                       return d.data.desc?'red':'#60ffcc'
                       
                    },
                    shadowBlur: 2,
                    shadowColor: '#333'
                }
            },
            data: p_data.data.p_list
        },
        {
            name: '白条',
            type: 'lines',
            zlevel: 2,
            tooltip: {
                show:false,
                formatter: function(param) {
                    return param.data.fromName + '->' + param.data.toName + '<br>订单数：' + param.data.count + ' 条<br>订单总金额：' + parseFloat(param.data.amount).toFixed(2) + ' 元'
                }
            },
            effect: {
                show: true,
                period: 3, //箭头指向速度，值越小速度越快
                trailLength: 0.4, //特效尾迹长度[0,1]值越大，尾迹越长重
                symbol: 'arrow', //箭头图标
                symbolSize: 5, //图标大小
            },
            lineStyle: {
                normal: {
                    color: {
                        type: 'linear',
                        x: 1,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [{
                            offset: 0,
                            color: '#236ce6' // 0% 处的颜色
                        }],
                        global: false // 缺省为 false
                    },
                    width: 2, //线条宽度
                    opacity: 0.1, //尾迹线条透明度
                    curveness: .3 //尾迹线条曲直度
                },
                emphasis: {
                    width: 3,
                    opacity: 0.5,
                }
            },
            data: dataFormart(p_data.data.p_list)
        }
    ]
};
export default option;