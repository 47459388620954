<template>
  <div class="right_top">
    <div class="title">白牛村垃圾分类</div>
    <div class="box">
      <div class="li">
        <div class="t1">巡逻员</div>
        <div class="t2">{{rightTop.xunluo}}</div>
      </div>
      <div class="li">
        <div class="t1">其他垃圾</div>
        <div class="t2">{{rightTop.other}}</div>
      </div>
      <div class="li">
        <div class="t1">易腐垃圾</div>
        <div class="t2">{{rightTop.shenghuo}}</div>
      </div>
    </div>
    <div class="box">
      <div class="li">
        <div class="t1">居民参与率</div>
        <div class="t2">{{rightTop.canyu}}</div>
      </div>
      <div class="li">
        <div class="t1">分类准确率</div>
        <div class="t2">{{rightTop.zhunque}}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props:['rightTop']
}
</script>
<style lang="scss" scoped>
.right_top {
  width: 4.5rem;
  height: height(200);
  background-color: #0b132d;
  border-radius: 0.1rem;
  position: absolute;
  top: height(110);
  right: 0.2rem;
  z-index: 100;
}
.title{
  margin-bottom: height(24);
}
.box{
  display: flex;
  padding: 0 0.15rem;
}
.li{
  flex: 1;
  height: height(50);
  text-align: center;
  border-radius: 0.04rem;
  background-color: #091a51;
  margin: 0 0.05rem;
  margin-bottom: height(10);
  padding-top: height(7);
  .t1{
    font-size:0.12rem;
    line-height: height(12);
    color: #fff;
  }
  .t2{
    font-size:0.18rem;
    line-height: height(18);
    color: #42eb84;
    margin-top: height(10);
  }
}
</style>