<template>
  <div class="bg-box">
    <div class="mainboxxx">
      <div class="itembox">
        <div class="m_title t8"></div>
        <div class="srqkbox">
          <div class="srqk">
            <v-echart :option="dataIncome"></v-echart>
          </div>
          <div class="srqk">
              <v-echart :option="dataAvg"></v-echart>
          </div>
        </div>
        <div class="txtbox">
            <div class="tx_t">村集体</div>
            <div class="tx_t">人均收入</div>
        </div>
      </div>
      <div class="itembox">
        <div class="m_title t9"></div>
        <div class="picbox">
          <div class="pictitle">
            {{honor.start_year}}年至{{honor.end_year}}获区级以上荣誉<span>{{honor.total}}</span>项
          </div>
          <div class="pic_itembox">
            <div class="pic_1">
                <img :src="honor.images[0]" alt="">
            </div>
            <div class="pic_2_box">
              <div class="pic_2">
                  <img :src="honor.images[1]" alt="">
              </div>
              <div class="pic_2">
                  <img :src="honor.images[2]" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["avg", "honor", "income"],
  components: {},
  data() {
    return {
      dataIncome: null,
      dataAvg:null
    };
  },
  created() {
    this.setopt_dataIncome();
    this.setopt_dataAvg();
  },
  watch: {
    income() {
      this.setopt_dataIncome();
    },
    avg(){
        this.setopt_dataAvg();
    }
  },
  methods: {
    setopt_dataAvg(){
       var incomeX = [];
      var incomeY = [];
      this.avg.map((el) => {
        incomeX.push(el.name);
        incomeY.push(parseFloat(el.value));
      });
      this.dataAvg = {
        legend: {
          show: false,
        },
        grid: {
          left: "15%",
          top: "10%",
          bottom: "10%",
          right: "8%",
        },
        yAxis: {
          type: "value",

          splitLine: {
            show: false,
          },
          axisLabel: {
            show:false
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
        },
        xAxis: {
          type: "category",
          inverse: true,
          axisLabel: {
            show: true,
            textStyle: {
              color: "#85abf3",
            },
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          data: incomeX,
        },
        series: [
          {
            z: 2,
            name: "value",
            type: "bar",
            data: incomeY,
            barCategoryGap: "65%",
            itemStyle: {    
                color:'#00aaff'
              /* normal: {
                color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                  {
                    offset: 0,
                    color: "#0055fd",
                  },
                  {
                    offset: 1,
                    color: "#00aaff",
                  },
                ]),
              }, */
            },
            label: {
              show: true,
              position: "top",
              color: "#fff",
              fontSize: 10,
              offset: [-5, -5],
              formatter:function(param){
                  return param.value/10000+'万元'
              }
            },
          },
        ],
      }; 
    },
    setopt_dataIncome() {
      var incomeX = [];
      var incomeY = [];
      this.income.map((el) => {
        incomeX.push(el.name);
        incomeY.push(parseFloat(el.value));
      });
      this.dataIncome = {
        legend: {
          show: false,
        },
        grid: {
          left: "15%",
          top: "5%",
          bottom: "10%",
          right: "8%",
        },
        yAxis: {
          type: "value",

          splitLine: {
            show: false,
          },
          axisLabel: {
            show:false
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
        },
        xAxis: {
          type: "category",
          inverse: true,
          axisLabel: {
            show: true,
            textStyle: {
              color: "#85abf3",
            },
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          data: incomeX,
        },
        series: [
          {
            z: 2,
            name: "value",
            type: "bar",
            data: incomeY,
            barCategoryGap: "70%",
            itemStyle: {    
                color:'#0055fd'
              /* normal: {
                color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                  {
                    offset: 0,
                    color: "#0055fd",
                  },
                  {
                    offset: 1,
                    color: "#00aaff",
                  },
                ]),
              }, */
            },
            label: {
              show: true,
              position: "top",
              color: "#fff",
              fontSize: 14,
              offset: [0, 0],
              formatter:function(param){
                  return param.value/10000+'万元'
              }
            },
          },
        ],
      };
    },
  },
};
</script>
<style scoped lang="scss">
.bg-box {
  height: 100vh;
  width: 11.4rem;
  position: fixed;
  top: 0;
  left: 3.9rem;
  z-index: 999;
  overflow: hidden;
}
.m_title {
  width: 100%;
  height: height(62);
  background: url(../assets/rkgk.png) no-repeat center/1.6rem height(21);
  &.t2 {
    background: url(../assets/wmh.png) no-repeat center/2.35rem height(21);
  }
  &.t3 {
    background: url(../assets/dyhdqk.png) no-repeat center/1.98rem height(21);
  }
  &.t4 {
    background: url(../assets/zyz.png) no-repeat center/1.82rem height(21);
  }
  &.t8 {
    background: url(../assets/srqk.png) no-repeat center/1.66rem height(21);
  }
  &.t9 {
    background: url(../assets/ry.png) no-repeat center/1.29rem height(21);
  }
}
.mainboxxx {
  margin: 0 auto;
  display: flex;
  z-index: 1000;
  width: 11.78rem;
  padding: 0 0.17rem;
  margin-top: height(351);
  .itembox {
    flex: 1;
    margin-right: 0.16rem;
    height: height(380);
    background: rgba(5, 11, 44, 0.6);
    .txtbox{
        display: flex;
        flex: 1;
        justify-content: space-around;
        .tx_t{
            text-align: center;
            font-size: 0.16rem;
            line-height: 1;
        }
    }
    &:last-child {
      margin-right: 0;
    }
    .srqkbox {
      padding: 0 0.3rem;
      display: flex;
      width: 100%;
      height: height(280);
      justify-content: center;
      align-items: center;
      background: url(../assets/1523.png) no-repeat center/1px height(197);
      .srqk {
        flex: 1;
        height: height(200);
      }
    }
    .picbox {
      padding: 0 0.3rem;

      .pictitle {
        text-align: center;
        line-height: height(38);
        height: height(40);
        background-color: rgba(14, 109, 233, 0.6);
        border-radius: 0.04remx;
        border: solid 1px #0e6de9;
        font-size: 0.18rem;
        & > span {
          font-size: 0.26rem;
          color: #00f8f9;
        }
      }
      .pic_itembox {
        display: flex;
        margin-top: height(16);
      }
    }
  }
}
.pic_1 {
  width: 3.46rem;
  height: height(228);
  border: solid 3px #0e6de9;
  margin-right: 0.1rem;
}
.pic_2_box {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.pic_1,
.pic_2 {
  border: solid 3px #0e6de9;
}
.pic_2 {
  width: 100%;
  height: height(108);
}
img{
    width: 100%;
    height: 100%;
}
</style>