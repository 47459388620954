<template>
  <div class="echarts" ref="echarts"></div>
</template>

<script>
export default {
  props: ["option",'act'],
  watch: {
    option() {
      console.log(this.option,156849789)
      this.initData();
    },
  },
  mounted() {
    this.init();
    if (this.option) {
      this.initData();
    }
    window.addEventListener("resize", () => {
      console.log("窗口发生变化");
      this.myChart.resize();
    });
  },
  methods: {
    init() {
      this.myChart = echarts.init(this.$refs.echarts);
      if(this.act){
          this.datasetval();
      }
    },
    initData() {
      this.myChart.setOption(this.option);
      console.log(888)
    },
    datasetval() {
      let index = -1;
      var timer = setInterval(() => {
        // 高亮指定的数据图形
        this.myChart.dispatchAction({
          type: "highlight",
          seriesIndex: 0,
          dataIndex: index + 1 == this.option.legend.data.length ? 0 : index + 1,
        });
        // 取消高亮指定的数据图形
        this.myChart.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
          dataIndex: index == this.option.legend.data.length ? index - 1 : index,
        });
        index++;
        if (index >= this.option.legend.data.length) {
          index = 0;
        }
      }, 2000);
    },
  },
};
</script>

<style lang='scss'>
.echarts {
  width: 100%;
  height: 100%;
  flex: 1;
}
</style>
