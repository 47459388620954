<template>
  <div class="c_box">
    <div>
      <div class="item_title gaikuang">
        白牛概览
        <span class="look_more"
          ><span
            @click="
              $parent.poptype = 1;
              $parent.show = true;
            "
            >查看详情</span
          >>></span
        >
      </div>
      <div class="content">
        <div class="item_c">
          <div class="item_c_title">
            白牛总人口数 (人)<span class="item_c_number">{{
              data.allperson
            }}</span>
          </div>
        </div>
        <div class="item_c">
          <div class="item_c_title">
            党员总数<span class="item_c_number">{{ data.partymember }}</span>
          </div>
        </div>
        <div class="item_c">
          <div class="item_c_title">
            从业电商人员<span class="item_c_number">{{ data.ECperson }}</span>
          </div>
        </div>
        <div class="item_c">
          <div class="item_c_title">
            白牛村总面积 (km²)<span class="item_c_number">{{
              data.bainiuarea
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="renjun">
      <div class="item_title gaikuang">人均收入 (元)</div>
      <div class="ren_echart">
        <v-echart :option="optionavg"></v-echart>
      </div>
    </div>
    <div class="renjun">
      <div class="item_title gaikuang">产学研数据</div>
      <div class="item_box">
        
        <div class="item_box_right">
          <div class="icon_box"></div>
          <span>96</span>个
        </div>
        <div class="item_box_left">
          <div class="icon_box"></div>
          <span>38w</span>+
        </div>
      </div>
    </div>
  </div>
</template>
 <script>
export default {
  props: ["data", "avgdata"],
  data() {
    return {
      optionavg: null,
      xdata: [],
      ydata: [],
    };
  },
  mounted() {
    this.setoptionavg();
  },
  watch:{
    avgdata(){
      this.setoptionavg();
    }
  },
  methods: {
    setx() {
      this.xdata = this.avgdata.map((el) => {
        return el.date;
      });
    },
    sety() {
      this.ydata = this.avgdata.map((el) => {
        return parseFloat(el.value);
      });
    },
    setoptionavg() {
      this.setx();
      this.sety();
      this.optionavg = {
        xAxis: {
          type: "category",
          splitLine: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              width: 1,
              color: "#3c4356",
            },
          },
          axisTick: {
            show: false,
            alignWithLabel: false,
            lineStyle: {
              width: 3,
            },
          },
          axisLabel: {
            textStyle: {
              color: "#fff",
              fontSize: 12,
              fontWeight: "bold",
            },
          },
          data: this.xdata,
        },
        grid: {
          top: "18%",
          left: "2%",
          right: "4%",
          bottom: "0%",
          containLabel: true,
        },
        yAxis: {
          type: "value",
          nameTextStyle: {
            color: "#fff",
          },
          splitLine: {
            show: false,
          },
          axisLine: {
            show: false,
            lineStyle: {
              width: 3,
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            /* formatter:(v)=> {
              console.log()
              var str = 
              return v +str; 
            }, */
            color: "#fff",
            fontWeight: "bold",
          },
        },
        series: [
          {
            type: "bar",
            symbol: "circle",
            barWidth: 10,
            smooth: true,
            animationDuration: 2000,
            itemStyle: {
              normal: {
                color: "#66DC95",
                borderColor: "#66DC95",
                borderWidth: 2,
              },
            },
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: "#fff",
              },
            },
            lineStyle: {
              normal: {
                color: "#66DC95",
                width: 3,
                shadowColor: "rgba(0,0,0,0.4)",
                shadowBlur: 10,
                shadowOffsetX: 4,
                shadowOffsetY: 10,
              },
            },
            data: this.ydata,
          },
        ],
      };
    },
  },
};
</script>
  <style lang="scss" scope>
.item_box {
  display: flex;
  height: height(150);
  div {
    flex: 1;
    font-size: 0.14rem;
    text-align: center;
    line-height: 1;
    span {
      font-size: 0.3rem;
    }
    &.item_box_left {
      div {
        height: height(116);
        background: url(../../assets/ll1.png) no-repeat center/1.2rem auto;
      }
    }
    &.item_box_right {
      div {
        height: height(116);
        background: url(../../assets/rr1.png) no-repeat center/1.2rem auto;
      }
    }
  }
}
.c_box {
  width: 100%;
  height: 100%;
  padding: height(10) 0.1rem;
  font-size: 0;
  .content {
    margin-top: height(20);
    padding: 0 0.1rem;
    white-space: normal;
    font-size: 0;
    .item_c {
      vertical-align: top;
      width: 1.46rem;
      height: height(70);
      display: inline-block;
      background: #001025;
      border: 1px solid #142b5f;
      border-radius: 7px;
      margin-right: 0.1rem;
      margin-bottom: height(8);
      padding: height(12) 0;
      .item_c_title {
        font-size: 0.12rem;
        color: #fff;
        text-align: center;
        font-weight: bold;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .item_c_number {
        font-weight: 700;
        font-size: 0.26rem;
        text-align: center;
        color: #66dc95;
      }
    }
    .item_c:nth-child(2n) {
      margin-right: 0;
    }
    .item_c:nth-child(3) {
      margin-bottom: 0;
    }
    .item_c:nth-child(4) {
      margin-bottom: 0;
    }
  }
}
.item_title.gaikuang {
  background: url(../../assets/bngl.png) no-repeat left 0.1rem center/14px auto,
    linear-gradient(to left, rgba(9, 33, 87, 0), rgba(9, 33, 87, 1));
}
.renjun {
  margin-top: height(20);
  .ren_echart {
    width: 100%;
    height: height(146);
  }
}
</style>