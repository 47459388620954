<template>
  <div class="c_box5">
    <div class="item_title shopnumber">
      白牛电商店铺综合指数
    </div>
    <div class="content">
      <div class="table">
        <div class="item_line xx1">
          <div class="tx1">店铺名称</div>
          <div class="tx2">营收额</div>
          <div class="tx3">评分</div>
        </div>
        <div class="xxx1">
          <div
          :class="[index % 2 == 0 && 'x1', 'item_line']"
          v-for="(item, index) in data"
          class="tt1"
        >
          <div class="tx1">{{ item.name }}</div>
          <div class="tx2">{{ item.turnover }}</div>
          <div class="tx3">{{ item.score }}</div>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["data"],
  mounted() {
    this.scolltable();
  },
  watch: {
    data() {
      this.scolltable();
    },
  },
  methods: {
    scolltable() {
      if (this.data && this.data.length > 5 ) {
        var css = document.createElement("style");
        css.type = "text/css";
        css.innerHTML = `@keyframes rowup {
            0% {
                transform: translate3d(0, 0, 0);
            }
            100% {
                transform: translate3d(0, -${(this.data.length-5) * 30}px, 0);
            }
        }

        .tt1{
          animation: ${2000 * this.data.length}ms rowup linear infinite normal;
        }`;
        document.head.appendChild(css);
      }
    },
  },
};
</script>
<style lang="scss" scope>
.c_box5 {
  width: 100%;
  height: 100%;
  padding: height(10) 0.1rem;
  font-size: 0;
  .content {
    width: 100%;
    margin-top: height(10);

    .table {
      width: 100%;
      
      .xxx1{
       height: height(146);
        overflow: hidden;
      }
      .item_line {
        display: flex;
        height: height(30);
        padding: 0 0.2rem;
        background: #0a2157;
        &.x1 {
          background: none;
        }
        &.x2 {
          background: rgba(7, 52, 98, 0.3);
        }
        & div {
          line-height: height(30);
          font-size: 0.12rem;
          color: #fff;
        }
        .tx1 {
          width: 42%;
        }
        .tx2 {
          width: 42%;
        }
        .tx3 {
          width: 16%;
        }
      }
    }
  }
}
.item_title.shopnumber {
  background: url(../../assets/ds.png) no-repeat left 0.1rem center/14px auto,
    linear-gradient(to left, rgba(9, 33, 87, 0), rgba(9, 33, 87, 1));
}
</style>