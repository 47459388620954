import axios from 'axios'
import  md5 from 'md5'
import Qs from 'qs'
// 创建 axios 实例
const service = axios.create({
  //baseURL: "https://bigdata.venyuan.com/tzadm/",
  baseURL: "https://bainiu.qlzf.top/index.php/",
  timeout: 6000,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
  }
})

console.log(Qs)

const err = (error) => {
  console.log("请求无响应",error)
  return Promise.reject(error)
}

// request interceptor
service.interceptors.request.use(config => {
  var arr = [];
  var data = config.params||{};
  if (config.method == 'post') {
    data = config.data
    console.log(111)
  }
  console.log(data)
  data.timestamp = (new Date()).valueOf();
  for (var i in data) {
    if (data[i]) arr.push(i + '=' + data[i])
  }
  arr = arr.sort();
  console.log(arr.join('&') + "3d5af45650e623ffc0b4b7ecabff6a29e08e3916")
  data.sign = md5(arr.join('&') + "3d5af45650e623ffc0b4b7ecabff6a29e08e3916")
  if (config.method == 'post') {
    config.data=Qs.stringify(data);
  }else{
    config.params=data
  }
  return config
}, err)

// response interceptor
service.interceptors.response.use((response) => {
  if(response.data.code==0){
    return response.data
  }else{
    alert(response.data.msg)
    //throw new Error(response.data)
    return Promise.reject(response.data)
  }
}, err)


export default service