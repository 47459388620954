<template>
  <div class="page">
    <v-left-top :leftTop="leftTopData"></v-left-top>
    <v-left-center :leftCenter="leftCenterData"></v-left-center>
    <v-left-bottom></v-left-bottom>
    <!-- <v-right-top :rightTop="rightTopData"></v-right-top>
    <v-right-center :rightCenter="rightCenterData"></v-right-center>
    <v-right-center-bottom
      :rightCenterBottom="rightCenterBottomData"
    ></v-right-center-bottom>
    <v-right-bottom :rightBottom="rightBottomData"></v-right-bottom> -->
    <v-right></v-right>
    <v-box :show.sync="show"></v-box>
  </div>
</template>
<script>
import vLeftTop from "./left_top";
import vLeftCenter from "./left_center";
import vLeftBottom from "./left_bottom";
import vRightBottom from "./right_bottom";
import vRightTop from "./right_top";
import vRightCenter from "./right_center";
import vRight from "./right";
import vRightCenterBottom from "./right_center_bottom";
import vBox from "./box";
import { Manage } from "../../api/index";
export default {
  data() {
    return {
      show: false,
      leftTopData:[],
      leftCenterData:[],
      rightTopData: {},
      rightBottomData: {},
      rightCenterData: {},
      rightCenterBottomData: [],
    };
  },
  components: {
    vLeftTop,
    vLeftCenter,
    vRightTop,
    vRight,
    vRightCenter,
    vRightBottom,
    vLeftBottom,
    vRightCenterBottom,
    vBox,
  },
  created() {
    this.getInfo();
  },
  mounted() {
    this.$root.$on('showinfo',()=>{
      setTimeout(() => {
        this.show = true;
      }, 3000);
    })
  },
  methods: {
    getInfo() {
      Manage({}).then((res) => {
        this.leftTopData = res.result.land.transfer;
        this.leftCenterData = res.result.land.division;
        this.rightTopData = res.result.rubbish;
        this.rightBottomData = res.result.rule;
        this.rightCenterData = res.result.task;
        this.rightCenterBottomData = [
          {
            name: "党务",
            value: parseInt(res.result.task.dang),
          },
          {
            name: "村务",
            value: parseInt(res.result.task.cun),
          },
          {
            name: "财务",
            value: parseInt(res.result.task.cai),
          },
        ];
        /*  this.rubbish = res.result.rubbish;
        this.rule = res.result.rule;
        this.activity = res.result.activity;
        this.task = res.result.task;
        this.star = res.result.star;
        this.party_type = res.result.party_type;
        this.transfer = res.result.land.transfer;
        this.division = res.result.land.division;
        this.sanwulist = [
          {
            name: "党务",
            value: parseInt(res.result.task.dang),
          },
          {
            name: "村务",
            value: parseInt(res.result.task.cun),
          },
          {
            name: "财务",
            value: parseInt(res.result.task.cai),
          },
        ];*/
      });
    },
  },
};
</script>
<style lang="scss">
// .vtitle {
//   font-size: 0.2rem;
//   line-height: height(40);
//   height: height(40);
//   text-align: center;
//   color: #ffffff;
//   background: url(../../assets/btk.png) no-repeat center/auto height(40);
// }
</style>