<template>
  <div class="switch" @click="changeSwitch" v-if="false">
      <div :class="[switchflag&&'on','btn']"></div>
  </div>
</template>

<script>
import dataJson2 from "../data.json";

export default {
    props:['switchflag','catetype'],
    methods: {
        changeSwitch(){
           // this.$root.$emit('pointshow',this.catetype,this.switchflag)
            this.$emit('update:switchflag',!this.switchflag);
            if(!this.switchflag){
                this.$store.commit("SET_LIST",dataJson2.data.filter(item=>item.type==this.catetype))
            }else{
                this.$store.commit("SET_LIST",[])
            }
        }
    },
};
</script>

<style lang='scss'>
.switch{
    width: 0.6rem;
	height: 0.2rem;
	border-radius: 0.1rem;
    border: solid 1px #417dff;
    position: absolute;
    top: 0.5rem;
    right: 0.1rem;
    padding: 0.01rem;
    z-index: 100;
    cursor: pointer;
}
.btn{
    width: 0.3rem;
    height: 0.16rem;
    background: #417dff;
	border-radius: 0.08rem;
}
.btn.on{
    background-color: #2ecf71;
    position: absolute;
    right: 0.01rem;
    top: 0.01rem;
}
</style>
