<template>
  <div class="fixed_modal">
    <div class="pop">
      <div class="title">{{info.name}}</div>
      <div class="tag">改建地点：{{info.name}}</div>
      <div class="img_box">
        <div>
          <img :src="info.front_url" alt="" />
          <div class="txt">改造前</div>
        </div>
        <div>
          <img :src="info.after_url" alt="" />
          <div class="txt">改造后</div>
        </div>
      </div>
      <div class="close_btn" @click="$parent.$parent.pop_info=null"></div>
    </div>
  </div>
</template>
<script >
export default {
  props:['info'],
  data() {
    return {
      option: {},
    };
  },
  components: {},
};
</script>
<style lang="scss" scoped>
.fixed_modal {
  width: 8.9rem;
  height: height(412);
  border: solid 1px #142b5f;
  box-sizing: content-box;
  border-radius: 0.1rem;
  padding: 0.05rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 10000;
  .img_box {
    height: height(262);
    display: flex;
    & > div {
      margin: 0.1rem;
      font-size: 0;
      img {
        width: 4rem;
        height: height(225);
        border-radius: 0.1rem;
        
      }
      .txt{
         font-size: 0.14rem;
         line-height: height(34);   
         color: #fff;
         text-align: center;
        }
    }
  }
  .pop {
    width: 100%;
    height: 100%;
    background: #001025;
    border-radius: 0.1rem;
    padding: 0 0.3rem;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.8),
      inset 0px 0px 30px 0px rgba(44, 87, 228, 0.5);
    .close_btn {
      width: 0.14rem;
      height: 0.14rem;
      background: url(../assets/close.png) no-repeat center center /auto 0.14rem;;
      position: absolute;
      top: height(15);
      right: 0.15rem;
    }
    .tag {
      font-size: 0.14rem;
      line-height: height(72);
      color: #fff;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-left: 0.1rem;
      text-align: left;
    }
    .title {
      font-size: 0.18rem;
      line-height: height(38);
      text-align: center;
      color: #fff;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      padding-top: height(10);
    }
  }
}
</style>