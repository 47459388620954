<template>
  <div id="classapp">
    <!-- 左 -->

    <div class="table1">
      <div class="btk1">白牛村游客分析</div>
      <div class="echart1-1">
        <div class="childx">
          <v-echart :option="leftTop_1"></v-echart>
        </div>
        <div class="childx">
          <v-echart :option="leftTop_2"></v-echart>
        </div>
      </div>
      <div class="echart1-2">
        <v-echart :option="leftSec"></v-echart>
      </div>
    </div>
    <div class="table2">
      <div class="btk1">游客近7天客流统计</div>
      <div class="echart2">
        <v-echart :option="leftThird"></v-echart>
      </div>
    </div>
    <div class="table3">
      <div class="btk1">今日车流量统计</div>
      <div class="echart3">
        <v-echart :option="leftBottom"></v-echart>
      </div>
    </div>

    <!-- 右 -->

    <div class="table4">
      <div class="btk1">今日游客人数</div>
      <div class="bignumber">0</div>
      <v-switch :switchflag.sync='switchxx1'></v-switch>
    </div>
    <div class="table5">
      <div class="btk1">游客消费分析</div>
      <div class="echart5">
        <v-echart :option="rightTop"></v-echart>
      </div>
    </div>
    <div class="table6">
      <div class="btk1">白牛村客流监测</div>
      <v-switch :switchflag.sync='switchxx2'></v-switch>
      <div class="imgList">
        <img />
        <img />
      </div>
    </div>
    <div class="table7">
      <div class="btk1">游客来源分析</div>
      <div class="echart7">
        <v-echart :option="rightBottom"></v-echart>
      </div>
    </div>
  </div>
</template>


<script>
import { Tour } from "../../api/index";
var colorList = [
  "#06d3c4",
  "#ffbc32",
  "#2ccc44",
  "#ff3976",
  "#6173d6",
  "#914ce5",
  "#42b1cc",
  "#ff55ac",
  "#0090ff",
  "#06d3c4",
  "#ffbc32",
  "#2ccc44",
  "#ff3976",
  "#6173d6",
  "#914ce5",
  "#42b1cc",
  "#ff55ac",
  "#0090ff",
];
export default {
  name: "chart2",
  data() {
    return {
      leftTop_1: null,
      leftTop_2: null,
      leftSec: null,
      leftThird: null,
      leftBottom: null,
      rightTop: null,
      rightBottom:null,
      switchxx1:false,
      switchxx2:false
    };
  },
  created() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      Tour({}).then((res) => {
        this.leftTop_1 = this.left_top(res.result.sex, 1);
        this.leftTop_2 = this.left_top(res.result.group, 2);
        this.leftSec = this.left_sec(res.result.group);
        this.leftThird = this.left_third(res.result.statistics);
        this.leftBottom = this.left_third(res.result.car);
        this.rightTop = this.right_top(res.result.order_analysis);
        this.rightBottom = this.right_bottom(res.result.source.list);

        /* this.tp1data = res.result.sex;
        this.tp2data = res.result.group;
        for (var i = 0; i < res.result.statistics.length; i++) {
          this.tb2data.arr1.push(res.result.statistics[i].count);
          this.tb2data.arr2.push(res.result.statistics[i].date);
        }
        this.tl1data = res.result.car;
        this.tp4data = res.result.source.list;
        this.shopingdata = res.result.order_analysis;
        for (var i = 0; i < res.result.source.list.length; i++) {
          this.tb3data.datamax.push(
            parseInt(res.result.source.max / 2) +
              parseInt(res.result.source.max)
          );
          this.tb3data.datax.push(res.result.source.list[i].value);
          this.tb3data.datay.push(res.result.source.list[i].license_top);
        } */
      });
    },
    left_top(arr, type) {
      const legendData = [];
      var txt1 = "";
      if (type == 1) {
        txt1 = "性别";
        arr.forEach((el) => {
          if (el.sex == "1") {
            legendData.push("女");
            el.name = "女";
          }
          if (el.sex == "2") {
            legendData.push("男");
            el.name = "男";
          }
        });
      }
      if (type == 2) {
        txt1 = "年龄段";
        arr.forEach((el) => {
          legendData.push(el.age_group);
          el.name = el.age_group;
        });
      }
      const obj = {
        legend: {
          show: true,
          bottom: 0,
          itemGap: 5,
          z: 2,
          icon: "circle",
          padding: [0, 0],
          textStyle: {
            color: "#fff",
            lineHeight: 1,
            padding: [0, 0],
          },
          data: legendData,
        },
        color: [
          "#06d3c4",
          "#ffbc32",
          "#2ccc44",
          "#ff3976",
          "#6173d6",
          "#914ce5",
          "#42b1cc",
          "#ff55ac",
          "#0090ff",
          "#06d3c4",
          "#ffbc32",
          "#2ccc44",
          "#ff3976",
          "#6173d6",
          "#914ce5",
          "#42b1cc",
          "#ff55ac",
          "#0090ff",
        ],
        grid: {
          bottom: 150,
          left: 100,
          right: "10%",
        },

        series: [
          // 主要展示层的
          {
            radius: ["30%", "70%"],
            center: ["50%", "45%"],
            type: "pie",
            label: {
              position: "center",
              formatter: function (param) {
                return "{b|" + txt1 + "}\n{b|统计}";
              },
              rich: {
                b: {
                  fontSize: 14,
                  padding: [5, 5, 5, 5],
                  fontWeight: 400,
                  color: "#fff",
                },
              },
            },
            data: arr,
          },
        ],
      };
      return obj;
    },
    left_sec(arr) {
      const provRank = [];
      const ddata = [];
      for (var i = 0; i < arr.length; i++) {
        var obj = {};
        obj.value = arr[i].scale;
        obj.itemStyle = {
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 1,
            y2: 0,
            colorStops: [
              {
                offset: 1,
                color: "#0489f0", // 0% 处的颜色
              },
              {
                offset: 0,
                color: "#28d9e8", // 100% 处的颜色
              },
            ],
            global: false, // 缺省为 false
          },
        };
        ddata.push(obj);
        provRank.push(arr[i].age_group);
      }
      let opt = {
        title: {
          text: "人数占比",
          textStyle: {
            color: "#fff",
            fontSize: 16,
          },
          top: "5%",
        },
        grid: {
          top: "25%",
          left: "0%",
          right: "10%",
          bottom: "10%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          axisLabel: {
            color: "#85abf3",
          },
          splitLine: {
            show: false,
          },
        },
        yAxis: {
          type: "category",
          boundaryGap: true,
          inverse: true, //反向
          axisLine: {
            show: true,
            lineStyle: {
              color: "#85abf3",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            color: "#85abf3",
          },
          data: provRank,
        },
        series: [
          {
            name: "",
            barMaxWidth: "40%",
            type: "bar",
            label: {
              show: true,
              position: "right",
              formatter: function (param) {
                return param.data.value + "%";
              },
              color: "#fff",
              fontSize: 12,
            },
            data: ddata,
          },
        ],
      };
      return opt;
    },
    left_third(arr) {
      let xdata = [];
      let ydata = [];
      arr.forEach((el) => {
        xdata.push(el.date);
        ydata.push(el.count);
      });
      let opt = {
        grid: {
          top: "20%",
          left: "3%",
          right: "3%",
          bottom: "5%",
          containLabel: true,
        },
        xAxis: {
          data: xdata,
          axisLine: {
            lineStyle: {
              color: "#3d5269",
            },
          },
          axisLabel: {
            color: "#5174d3",
            fontSize: 10,
            type: "dashed",
          },
        },
        yAxis: {
          name: "单位:次",
          nameTextStyle: {
            color: "#5174d3",
            fontSize: 10,
          },
          axisLine: {
            lineStyle: {
              color: "#5174d3",
            },
          },
          axisLabel: {
            color: "#5174d3",
            fontSize: 10,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#2d3d53",
              type: "dashed",
            },
          },
          interval: 1000,
        },
        series: [
          {
            type: "bar",
            barWidth: 20,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "#04acfd",
                    },
                    {
                      offset: 1,
                      color: "#0152f2",
                    },
                  ],
                  false
                ),
              },
            },
            label: {
              normal: {
                show: true,
                fontSize: 8,
                fontWeight: "bold",
                color: "#5174d3",
                position: "top",
              },
            },
            data: ydata,
          },
        ],
      };

      return opt;
    },
    left_bottom(arr) {
      let xdata = [];
      let ydata = [];
      arr.forEach((el) => {
        xdata.push(el.date);
        ydata.push(el.count);
      });
      let opt = {
        grid: {
          top: "5%",
          bottom: "15%",
          left: "5%",
          right: "5%",
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: xdata,
          axisLine: {
            lineStyle: {
              color: "#5174d3",
            },
          },
          axisLabel: {
            show: true,
            color: "#5174d3",
          },
        },
        yAxis: {
          type: "value",
          axisLine: {
            lineStyle: {
              color: "#5174d3",
            },
          },
          axisLabel: {
            show: true,
            color: "#5174d3",
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            data: ydata,
            type: "line",
            smooth: true,
            lineStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "#063d8c", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "#0e6f57", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 1,
                y2: 0,
                colorStops: [
                  {
                    offset: 0,
                    color: "#0e6f57", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "#063d8c", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
          },
        ],
      };
      return opt;
    },
    right_top(objx) {
      let legnedData = [];
      let seriseData = [];
      let radiusData = [70, 75];
      var LEGEND = {};
      let textx = "总:" + (objx.total / 10000).toFixed(1) + "万元";
      objx.list.forEach((el, i) => {
        legnedData.push(el.name);
        LEGEND[el.name] = el.value;
        var ssobj = {
          name: el.name,
          type: "pie",
          hoverAnimation: false, //鼠标移入变大
          clockWise: true,
          top: "-5%",
          left: "-50%",
          radius: [80 - i * 10 + "%", 85 - i * 10 + "%"],
          itemStyle: {
            normal: {
              color: colorList[i+1],
              label: {
                show: false,
              },
              labelLine: {
                show: false,
              },
            },
          },
          data: [
            {
              value: el.value + 500000,
              name: "invisible",
              itemStyle: {
                normal: {
                  color: "rgba(124,228,245,0.2)",
                },
              },
            },
            {
              value: el.value,
              name: el.name,
            },
          ],
        };
        seriseData.push(ssobj);
      });

      const opt = {
        title: {
          text: textx,
          textStyle: {
            color: "#fff",
            fontSize: 12,
          },
          top: "center",
          left: "16%",
        },
        legend: {
          orient: "",
          
          right: "2%",
          top: "center",
          icon: "circle",
          formatter: function (res) {
            return res + ":" + LEGEND[res] + "元";
          },
          textStyle: {
            color: "#bceeff",
            padding: [3, 0, 0, 3],
          },
          data: legnedData,
        },
        series: seriseData,
      };
      return opt;
    },
    right_bottom(arr) {
      const provRank = [];
      const ddata = [];
      for (var i = 0; i < arr.length; i++) {
        var obj = {};
        obj.value = arr[i].value;
        obj.itemStyle = {
          barBorderRadius: [30, 30, 30, 30],
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 1,
            y2: 0,
            colorStops: [
              {
                offset: 1,
                color: "#28d9e8", // 0% 处的颜色
              },
              {
                offset: 0,
                color: "#0489f0", // 100% 处的颜色
              },
            ],
            global: false, // 缺省为 false
          },
        };
        ddata.push(obj);
        provRank.push(arr[i].license_top);
      }
      let opt = {
        title: {
          textStyle: {
            color: "#fff",
            fontSize: 16,
          },
          top: "5%",
        },
        grid: {
          top: "5%",
          left: "5%",
          right: "10%",
          bottom: "10%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          axisLabel: {
            color: "#85abf3",
          },
          splitLine: {
            show: false,
          },
        },
        yAxis: {
          type: "category",
          boundaryGap: true,
          inverse: true, //反向
          axisLine: {
            show: false,
            lineStyle: {
              color: "#85abf3",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            
            color: "#fff",
          },
          data: provRank,
        },
        series: [
          {
            name: "",
            barMaxWidth: "40%",
            type: "bar",
            
            label: {
              show: true,
              position: "right",
              formatter: function (param) {
                return param.data.value + "辆";
              },
              color: "#fff",
              fontSize: 12,
            },
            data: ddata,
          },
        ],
      };
      return opt;
    },
  },
};
</script>

<style scoped lang="scss">
* {
  margin: 0;
  padding: 0;
}
html {
  font-size: calc(100vw / 1920 * 100);
  /* 换算比例：除以100 */
}
.f-l {
  float: left;
}
.f-r {
  float: right;
}
#table3 {
  width: 4.16rem;
  height: height(196);
}
#classapp {
  width: 19.2rem;
  height: height(108);
  background-size: 100%;
  /* position:relative; */
}

.line {
  width: 5.56rem;
  height: height(2);
  margin: auto;
  background-image: linear-gradient(0deg, #0dccff 0%, #4760ff 100%);
  margin-bottom: height(22);
}
.table1,
.table2,
.table3,
.table4,
.table5,
.table6,
.table7 {
  width: 4.5rem;
  height: height(480);
  background-color: #0b132d;
  border-radius: 0.1rem;
  overflow: hidden;
  position: absolute;
}
.table1 {
  top: height(110);
  left: 0.21rem;
}
.table2 {
  height: height(220);
  top: height(600);
  left: 0.21rem;
}
.table3 {
  height: height(220);
  top: height(828);
  left: 0.21rem;
}
.table4 {
  height: height(130);
  top: height(110);
  right: 0.2rem;
}
.table4 > .bignumber {
  font-size: 0.36rem;
  letter-spacing: 0.04rem;
  color: #ffffff;
  line-height: height(90);
  text-align: center;
}
.table4 > .switch {
  float: right;
  position: absolute;
  margin: 0;
  top: height(75);
  right: 0.15rem;
  /* margin:0.1rem 0.06rem 0.1rem 3.84rem; */
}
.table5 {
  height: height(260);
  top: height(250);
  right: 0.2rem;
}
.table5 > .table {
  height: height(195);
}
.table6 {
  height: height(240);
  top: height(520);
  right: 0.2rem;
}
.table7 {
  height: height(280);
  top: height(769);
  right: 0.2rem;
}
.imgList {
  margin-top: height(40);
  padding: 0 0.2rem height(20) 0.2rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.imgList > img {
  width: 2rem;
  height: height(140);
  background-color: #c44638;
  border-radius: 0.1rem;
  border: solid 0.01rem #ffffff;
}
.btk1 {
  width: 4.5rem;
  height: height(40);
  background: url(../../assets/btk.png) no-repeat center/auto 0.4rem;
  margin: auto;
  color: #fff;
  font-size: 0.2rem;
  line-height: height(40);
  text-align: center;
}
.echart1-1 {
  display: flex;
}
.childx {
  width: 50%;
  height: height(218);
}
.echart1-2 {
  height: height(222);
  padding: 0 0.2rem;
}
.echart2,
.echart3 {
  height: height(180);
}
.echart5 {
  height: height(220);
}
.echart7 {
  height: height(240);
}
</style>
