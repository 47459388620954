<template>
  <div>
    <div class="left_1 le1">
      <div class="commontitle">白牛村人口概况</div>
      <div class="left_1_echartbox">
        <v-echart :option="leftTop" :act='true'></v-echart>
      </div>
    </div>
    <div class="left_2 le2">
      <div class="commontitle">白牛村人口分布</div>
      <v-switch :switchflag.sync="switchxx1" :catetype="9"></v-switch>
      <div class="left_2_echartbox">
        <v-echart :option="leftMid" :act='true'></v-echart>
      </div>
    </div>
    <div class="left_3 le3">
      <div class="commontitle">白牛村就业人数</div>
      <div class="left_3_echartbox">
        <employ-echart :arrdata="employdata"></employ-echart>
      </div>
    </div>

    <div class="topbox">
      <div class="commontitle mid">白牛村人口总数</div>
      <div class="num_box">{{ population }}</div>
    </div>
    <div class="right_1 r1">
      <div class="commontitle">白牛村青年返乡</div>
      <div class="right_1_echartbox">
        <v-echart :option="rightTop"></v-echart>
      </div>
    </div>
    <div class="right_2 r2">
      <div class="commontitle">白牛村党员总数：85人</div>
      <v-switch :switchflag.sync="switchxx2"></v-switch>
      <div class="catebox">
        <div class="catename">党员活动计划</div>
        <table class="table2" border="1" rules="all" bordercolor="#1e2953">
          <tr>
            <td>
              <div class="cate">
                <div class="cc1">党员活动</div>
                <div class="cc2 cc2_1">12</div>
              </div>
            </td>
            <td>
              <div class="cate">
                <div class="cc1">义务劳动</div>
                <div class="cc2 cc2_2">4</div>
              </div>
            </td>
            <td>
              <div class="cate">
                <div class="cc1">培训学习</div>
                <div class="cc2 cc2_3">3</div>
              </div>
            </td>
          </tr>
        </table>
      </div>
      <div class="catebox">
        <div class="catename">党员考核结果</div>
        <table class="table2" border="1" rules="all" bordercolor="#1e2953">
          <tr>
            <td>
              <div class="cate">
                <div class="cc1">五星级(单位:人)</div>
                <div class="cc2 cc2_1">5</div>
              </div>
            </td>
            <td>
              <div class="cate">
                <div class="cc1">四星级(单位:人)</div>
                <div class="cc2 cc2_2">75</div>
              </div>
            </td>
            <td>
              <div class="cate">
                <div class="cc1">三星级(单位:人)</div>
                <div class="cc2 cc2_3">5</div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="cate">
                <div class="cc1">二星级(单位:人)</div>
                <div class="cc2 cc2_4">0</div>
              </div>
            </td>
            <td>
              <div class="cate">
                <div class="cc1">一星级(单位:人)</div>
                <div class="cc2 cc2_4">0</div>
              </div>
            </td>
            <td>
              <div class="cate">
                <div class="cc1">无星级(单位:人)</div>
                <div class="cc2 cc2_4">0</div>
              </div>
            </td>
          </tr>
        </table>
      </div>
      <div class="catebox">
        <div class="catename">党员类型</div>
        <table class="table2" border="1" rules="all" bordercolor="#1e2953">
          <tr>
            <td>
              <div class="cate">
                <div class="cc1">正式党员</div>
                <div class="cc2 cc2_1">85</div>
              </div>
            </td>
            <td>
              <div class="cate">
                <div class="cc1">年长党员</div>
                <div class="cc2 cc2_2">5</div>
              </div>
            </td>
            <td>
              <div class="cate">
                <div class="cc1">流动党员</div>
                <div class="cc2 cc2_1">3</div>
              </div>
            </td>
            <td>
              <div class="cate">
                <div class="cc1">预备党员</div>
                <div class="cc2 cc2_2">2</div>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="right_3">
      <div class="commontitle">党员先锋数据</div>
      <div class="parterpioneerbox">
        <div class="ppbox">
          <div class="ppt" v-if="index < 5" v-for="(item, index) in poineer">
            {{ numList[index] }} 党员先锋：{{ item }}
          </div>
        </div>
        <div class="ppbox">
          <div class="ppt" v-if="index >= 5" v-for="(item, index) in poineer">
            {{ numList[index] }} 党员先锋：{{ item }}
          </div>
        </div>
      </div>
    </div>

    <div :class="[flag ? 'popcontent' : 'popcontent hidden']">
      <div class="mainx">
        <div class="poptitle">人口分布</div>
        <div class="popinfo">
          <span>户主：张三</span>
          <span>分布类型：低保户</span>
          <span>年收入：1000元</span>
        </div>
        <div class="tablebox">
          <table class="tablex" border="1" rules="all" bordercolor="#1e2953">
            <tr class="thead">
              <th>补助金额</th>
              <th>月份</th>
              <th>状态</th>
            </tr>
            <tr>
              <td>250</td>
              <td>2020.12</td>
              <td>未领取</td>
            </tr>
            <tr>
              <td>250</td>
              <td>2020.12</td>
              <td>未领取</td>
            </tr>
            <tr>
              <td>250</td>
              <td>2020.12</td>
              <td>未领取</td>
            </tr>
            <tr>
              <td>250</td>
              <td>2020.12</td>
              <td>未领取</td>
            </tr>
            <tr>
              <td>250</td>
              <td>2020.12</td>
              <td>未领取</td>
            </tr>
          </table>
        </div>
        <div class="closersymbol" @click="closemidbox"></div>
      </div>
    </div>
  </div>
</template>
<script>
var colorList = [
  "#0090ff",
  "#06d3c4",
  "#ffbc32",
  "#2ccc44",
  "#ff3976",
  "#6173d6",
  "#914ce5",
  "#42b1cc",
  "#ff55ac",
  "#0090ff",
  "#06d3c4",
  "#ffbc32",
  "#2ccc44",
  "#ff3976",
  "#6173d6",
  "#914ce5",
  "#42b1cc",
  "#ff55ac",
  "#0090ff",
  "#06d3c4",
  "#ffbc32",
  "#2ccc44",
  "#ff3976",
  "#6173d6",
  "#914ce5",
  "#42b1cc",
  "#ff55ac",
  "#0090ff",
  "#06d3c4",
  "#ffbc32",
  "#2ccc44",
  "#ff3976",
  "#6173d6",
  "#914ce5",
  "#42b1cc",
  "#ff55ac",
  "#0090ff",
  "#06d3c4",
  "#ffbc32",
  "#2ccc44",
  "#ff3976",
  "#6173d6",
  "#914ce5",
  "#42b1cc",
  "#ff55ac",
  "#0090ff",
  "#06d3c4",
  "#ffbc32",
  "#2ccc44",
  "#ff3976",
  "#6173d6",
  "#914ce5",
  "#42b1cc",
  "#ff55ac",
  "#0090ff",
  "#06d3c4",
  "#ffbc32",
  "#2ccc44",
  "#ff3976",
  "#6173d6",
  "#914ce5",
  "#42b1cc",
  "#ff55ac",
  "#0090ff",
  "#06d3c4",
  "#ffbc32",
  "#2ccc44",
  "#ff3976",
  "#6173d6",
  "#914ce5",
  "#42b1cc",
  "#ff55ac",
  "#0090ff",
  "#06d3c4",
  "#ffbc32",
  "#2ccc44",
  "#ff3976",
  "#6173d6",
  "#914ce5",
  "#42b1cc",
  "#ff55ac",
  "#0090ff",
  "#06d3c4",
  "#ffbc32",
  "#2ccc44",
  "#ff3976",
  "#6173d6",
  "#914ce5",
  "#42b1cc",
  "#ff55ac",
  "#0090ff",
  "#06d3c4",
  "#ffbc32",
  "#2ccc44",
  "#ff3976",
  "#6173d6",
  "#914ce5",
  "#42b1cc",
  "#ff55ac",
];
import { Digitpeople } from "../api/index";
import employEchart from "./columnar3D";
export default {
  data() {
    return {
      leftTop: null,
      leftMid: null,
      rightTop: null,
      numList: ["①", "②", "③", "④", "⑤", "⑥", "⑦", "⑧", "⑨", "⑩"],
      poineer: [],
      population: 0,
      employdata: null,
      flag: false,
      switchxx1: false,
      switchxx2: false,
    };
  },
  components: {
    employEchart,
  },
  created() {
    this.getInfo();
  },
  mounted() {
    this.$root.$on("showinfo", () => {
      setTimeout(() => {
        // this.flag = true;
      }, 3000);
    });
  },

  methods: {
    getInfo() {
      Digitpeople({}).then((res) => {
        this.leftTop = this.left_top(res.result.age);
        this.leftMid = this.left_top(res.result.distribute);
        this.rightTop = this.right_top(res.result.youth);
        this.poineer = res.result.member;
        this.population = res.result.basic.total_user;
        const x = {
          maxdata: [],
          dataX: [],
          datav: [],
        };
        // this.$store.commit(
        //   "SET_LIST",
        //   res.result.coor.map((item) =>{
        //     if(item.lon){
        //       return {
        //     type: 12,
        //     id: 2,
        //     name: item.name,
        //     position: [item.lon, item.lat],
        //   }
        //     }else{
        //       return null
        //     }
        //   }).filter(item=>item)
        // );
        for (var i = 0; i < res.result.employ.list.length; i++) {
          x.maxdata.push(
            parseInt(res.result.employ.max) +
              parseInt(parseInt(res.result.employ.max) / 3)
          );
          x.dataX.push(res.result.employ.list[i].date);
          x.datav.push(parseInt(res.result.employ.list[i].value));
        }
        this.employdata = x;
        /* this.basic = res.result.basic;
        this.education = res.result.education;
        this.jobArray = res.result.job;
        this.ageArray = res.result.age;
        this.distributeArray = res.result.distribute;
        this.scoretop3 = res.result.score.splice(0, 3);
        this.scorelist = res.result.score;
        this.memberlist = res.result.member;
        this.backData = res.result.youth;
        for(var i=0;i<res.result.employ.list.length;i++){
            this.employdata.maxdata.push(parseInt(res.result.employ.max)+parseInt(parseInt(res.result.employ.max)/3))
            this.employdata.dataX.push(res.result.employ.list[i].date);
            this.employdata.datav.push(parseInt(res.result.employ.list[i].value));
        }*/
      });
    },
    left_top(arr) {
      var sum = 0;
      var legendData = [];
      arr.forEach((el) => {
        sum = el.value + sum;
        legendData.push(el.name);
      });
      arr.forEach((el) => {
        el.scale = ((el.value / sum) * 100).toFixed(2);
      });
      var obj = {
        legend: {
          show: true,
          bottom: 10,
          itemGap: 20,
          z: 2,
          icon: "circle",
          textStyle: {
            color: "#fff",
          },
          data: legendData,
        },
        series: [
          {
            name: "",
            type: "pie",
            radius: ["40%", "55%"],
            top: "5%",
            itemStyle: {
              normal: {
                color: function (params) {
                  return colorList[params.dataIndex];
                },
              },
            },
            label: {
              normal: {
                position: "outside",
                padding: [0, -50, 25, -50],
                formatter: function (params) {
                  if (params.data.scale != 0)
                    return (
                      "{a|" +
                      params.data.name +
                      "}" +
                      "{b|" +
                      params.data.scale +
                      "%}"
                    );
                },
                show: false,
                rich: {
                  a: {
                    color: "#fff",
                    padding: [0, 10, 0, 4],
                    fontSize: 12,
                  },
                  b: {
                    color: "#fff",
                    fontSize: 16,
                    fontWeight: "bold",
                  },
                },
              },
            },
            emphasis: {
              label: {
                show: true,
              },
              labelLine: {
                show: true
              },
            },
            labelLine: {
              show: false,
              length: 0,
              length2: 70,
            },
            data: arr,
          },
        ],
      };
      return obj;
    },
    right_top(arr) {
      const xdata = [];
      const ydata = [];
      arr.forEach((el) => {
        xdata.push(parseInt(el.date));
        ydata.push(parseInt(el.value));
      });
      var obj = {
        grid: {
          top: "6%",
          left: "10%",
          right: "10%",
          bottom: "10%",
        },
        xAxis: [
          {
            type: "category",
            axisLine: {
              show: true,
              lineStyle: {
                color: "#9581F5",
              },
            },
            offset: 0,
            splitArea: {
              // show: true,
              color: "#f00",
              lineStyle: {
                color: "#f00",
              },
            },
            axisLabel: {
              color: "#fff",
            },
            splitLine: {
              show: false,
            },
            boundaryGap: false,
            data: xdata,
          },
        ],
        yAxis: [
          {
            type: "value",
            min: 0,
            // max: 140,
            splitNumber: 4,
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(255,255,255,0.1)",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#9581F5",
              },
            },
            axisLabel: {
              show: true,
              margin: 20,
              textStyle: {
                color: "#d1e6eb",
              },
            },
            axisTick: {
              show: true,
            },
          },
        ],
        series: [
          {
            name: "青年返乡",
            type: "line",
            // smooth: true, //是否平滑
            showAllSymbol: true,
            // symbol: 'image://./static/images/guang-circle.png',
            symbol: "circle",
            symbolSize: 5,
            lineStyle: {
              normal: {
                color: "#6c50f3",
                shadowColor: "rgba(0, 0, 0, .3)",
                shadowBlur: 0,
                shadowOffsetY: 5,
                shadowOffsetX: 5,
              },
            },
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: "#6c50f3",
              },
            },
            itemStyle: {
              color: "#6c50f3",
              borderColor: "#fff",
              borderWidth: 3,
              shadowColor: "rgba(0, 0, 0, .3)",
              shadowBlur: 0,
              shadowOffsetY: 2,
              shadowOffsetX: 2,
            },
            tooltip: {
              show: true,
            },
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "rgba(108,80,243,0.3)",
                    },
                    {
                      offset: 1,
                      color: "rgba(108,80,243,0)",
                    },
                  ],
                  false
                ),
                shadowColor: "rgba(108,80,243, 0.9)",
                shadowBlur: 20,
              },
            },
            data: ydata,
          },
        ],
      };
      return obj;
    },
    closemidbox() {
      this.flag = false;
    },
  },
};
</script>
<style lang="scss">
.left_1,
.left_2,
.right_1,
.right_2 {
  width: 4.5rem;
  height: height(350);
  position: absolute;
  background-color: #0b132d;
  border-radius: 0.1rem;
  &.le1 {
    top: height(110);
    left: 0.2rem;
  }
  &.le2 {
    top: height(470);
    left: 0.2rem;
  }
  &.r1 {
    top: height(110);
    right: 0.2rem;
    height: height(290);
  }
  &.r2 {
    top: height(410);
    right: 0.2rem;
    height: height(390);
  }
}
.left_3 {
  width: 4.5rem;
  height: height(220);
  background-color: #0b132d;
  border-radius: 0.1rem;
  position: absolute;
  top: height(830);
  left: 0.2rem;
}
.right_3 {
  width: 4.5rem;
  height: height(240);
  background-color: #0b132d;
  border-radius: 0.1rem;
  position: absolute;
  top: height(810);
  right: 0.2rem;
}
.left_1_echartbox,
.left_2_echartbox {
  padding: 0 0.16rem;
  width: 100%;
  height: height(300);
}
.left_3_echartbox {
  width: 100%;
  height: height(180);
}
.right_1_echartbox {
  width: 100%;
  height: height(225);
}
.topbox {
  position: absolute;
  top: height(110);
  left: 4.8rem;
  width: 3.5rem;
  height: auto;
  background-color: #0b132d;
  border-radius: 0.1rem;
}
.num_box {
  height: height(70);
  line-height: height(70);
  text-align: center;
  font-size: 0.36rem;
  font-weight: bold;
  color: #fff;
}
.commontitle {
  font-size: 0.2rem;
  color: #fff;
  text-align: center;
  height: height(40);
  line-height: height(40);
  width: 3.95rem;
  margin: 0 auto;
  background: url(../assets/btk.png) no-repeat center/auto height(40);
  &.mid {
    background: url(../assets/btk.png) no-repeat center/auto height(40);
    width: 3.05rem;
  }
}
.popcontent {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 4rem;
  height: height(370);
  background: rgba(5, 11, 44, 0.8);
  box-shadow: 0px 0px height(20) 0px rgba(0, 0, 0, 0.8);
  border-radius: 0.2rem;
  margin: auto;
  &.hidden {
    display: none;
  }
}
.mainx {
  width: 100%;
  height: 100%;
  padding: height(10) 0.2rem;
}
.poptitle {
  width: 100%;
  height: height(50);
  font-size: 0.2rem;
  line-height: height(50);
  color: #fff;
  text-align: center;
  position: relative;
  &::after {
    content: "";
    width: 3.4rem;
    height: height(1);
    background: rgba(255, 255, 255, 0.3);
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}
.popinfo {
  display: flex;
  justify-content: space-between;
  height: height(50);
  width: 100%;
  padding: 0 0.13rem;
  span {
    line-height: height(50);
    font-size: 0.14rem;
    color: #fff;
  }
}
.tablex {
  width: 100%;
  height: auto;
  font-size: 0.14rem;
  color: #fff;
  text-align: center;
}
.thead {
  th {
    height: height(40);
    font-weight: bold;
    color: #549cff;
  }
}
td {
  height: height(40);
  line-height: height(40);
}
.closersymbol {
  width: 0.3rem;
  height: height(30);
  position: absolute;
  background: url(../assets/gb.png) no-repeat center/0.3rem auto;
  border-radius: 100%;
  right: -0.15rem;
  top: height(-15);
}
.catebox {
  margin-top: height(20);
  padding: 0 0.15rem;
}
.catename {
  text-align: center;
  font-size: 0.14rem;
  line-height: 1;
  color: #fff;
  margin-bottom: height(9);
}
.table2 {
  width: 100%;
  height: auto;
  tr {
    height: height(50);
  }
  td {
    height: height(50);
    line-height: height(50);
  }
}
.cate {
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1;
}
.cc1 {
  font-size: 0.12rem;
  color: #fff;
  margin-bottom: height(5);
}
.cc2 {
  font-weight: bold;
  font-size: 0.24rem;
  &.cc2_1 {
    color: #2ecf71;
  }
  &.cc2_2 {
    color: #ff5e6d;
  }
  &.cc2_3 {
    color: #ff5e6d;
  }
  &.cc2_4 {
    color: #b148d8;
  }
}
.parterpioneerbox {
  display: flex;
  margin-top: height(20);
  padding: 0 0.45rem;
  justify-content: space-between;
  div:nth-child(1) {
    div:nth-child(1) {
      color: #ff5e6d;
    }
    div:nth-child(2) {
      color: #eed428;
    }
    div:nth-child(3) {
      color: #2ecf71;
    }
  }
}
.ppbox {
  height: auto;
  display: flex;
  flex-direction: column;
}
.ppt {
  font-size: 0.16rem;
  color: #fff;
  line-height: 1;
  margin-bottom: height(18);
  &:nth-child(5) {
    margin-bottom: 0;
  }
}
.btnbox {
  position: absolute;
  width: 0.6rem;
  height: height(20);
  border: 0.01rem solid #417dff;
  border-radius: 0.1rem;
  right: 0.15rem;
  top: height(57);
  &::after {
    content: "";
    width: 0.3rem;
    height: 0.16rem;
    position: absolute;
    left: 0.01rem;
    bottom: 0.01rem;
    background-color: #417dff;
    border-radius: 0.08rem;
  }
  &.on {
    &::after {
      content: "";
      width: 0.3rem;
      height: 0.16rem;
      position: absolute;
      right: 0.01rem;
      bottom: 0.01rem;
      background-color: #2ecf71;
      border-radius: 0.08rem;
    }
  }
}
</style>