<template>
  <div>
    <div class="right_t" v-if="info.type=='coorDS'">
      <img class="min_img" :src="img" @click="min = !min" />
      <div class="box">
        <div class="tt">红色管家</div>
        <div class="t">{{info.red.name}}</div>
      </div>
      <div class="box">
        <div class="tt">联系电商户：{{info.circle.length-1}}</div>
        <div class="scroll_box">
          <div class="t" v-if="i.circle_type!=2" v-for="i in info.circle">{{i.name}}</div>
        </div>
      </div>
    </div>
    <div class="fixed_modal" :class="min && 'min'">
      <div class="pop">
        <div class="title">党员信息</div>
        <!-- <div class="tag">党员姓名：{{info.villager_name}} 党员类型：正式党员</div> -->
        <div class="close_btn" @click="$parent.$parent.pop_info = null;setlist()"></div>
        <div class="min_btn" @click="min = !min" v-if="info.type=='coorDS'"></div>
        <div class="info_x">
          <img class="face" :src="img" />
          <div class="right_info">
            <div class="uname">{{info.name}}</div>
            <div class="uscore">总分:{{info.sum_score}}</div>
            <div class="star">
              <div :class="[Number(info.star)>index?'xx':'x']" v-for="(i,index) in 5"></div>
            </div>
          </div>
        </div>
        <div class="table">
          <div class="th">
            <div class="td">指数类型</div>
            <div class="td">内容</div>
            <div class="td">分值</div>
          </div>
          <div class="scroll">
            <div class="tr">
              <div class="td">基础</div>
              <div class="td">-</div>
              <div class="td">-</div>
            </div>
            <div class="tr">
              <div class="td">正向</div>
              <div class="td">-</div>
              <div class="td">-</div>
            </div>
            <div class="tr">
              <div class="td">反向</div>
              <div class="td">-</div>
              <div class="td">-</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script >
import Event from '../utils/event.js'
import img from "../assets/zp.png";
export default {
  props: ["info"],
  data() {
    return {
      img,
      min: false,
    };
  },
  created() {
    console.log(this.info,'xxxxxxxxxxxxxxxxxxxxxxxxxxxx')
  },
   methods:{
    setlist(){
      console.log(777777777)
      Event.$emit('closeCoor',this.info)
    }
  }
};
</script>
<style lang="scss" scoped>
.right_t {
  position: fixed;
  top: height(151);
  right: 3.92rem;
  z-index: 100000;
  text-align: center;
  .box {
    margin-top: height(20);
    .t {
      width: 1.2rem;
      height: height(40);
      line-height: height(40);
      text-align: center;
      background: rgba(7,13,34,0.50);
    }
     .tt {
      width: 1.2rem;
      height: height(40);
      line-height: height(40);
      text-align: center;
      background: #070d22;
    }
  }
}
.scroll_box{
  height:height(160);
  overflow: auto;
}
.min_img {
  width: 0.88rem;
  height: height(100);
  cursor: pointer;
  background: #000;
  border-radius: 0.2rem;
  background: url(../assets/zp1.png);
  background-size: 100% 100%;
}
.fixed_modal {
  width: 4.1rem;
  height: height(359);
  border: solid 1px #142b5f;
  box-sizing: content-box;
  border-radius: 0.1rem;
  padding: 0.05rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 10000;
  transition: all 500ms linear;
  * {
    transition: all 500ms linear;
  }
  .tt {
    width: 1.2rem;
    height: height(40);
  }
  &.min {
    overflow: hidden;
    transform: translate(5rem, height(-350)) scale(0);
    * {
      opacity: 0;
    }
  }
  .pop {
    width: 4.1rem;
    height: height(370);
    background: #001025;
    border-radius: 0.1rem;
    padding: 0 0.3rem;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.8),
      inset 0px 0px 30px 0px rgba(44, 87, 228, 0.5);
    .info_x {
      display: flex;
      margin-top: height(10);
      margin-bottom: height(20);
      align-items: center;
    }
    .star {
      margin-top: height(10);
      .x {
        display: inline-block;
        width: 0.14rem;
        height: 0.14rem;
        background: url(../assets/x2.png);
        background-size: 100% 100%;
      }
      .xx {
        display: inline-block;
        width: 0.14rem;
        height: 0.14rem;
        background: url(../assets/x1.png);
        background-size: 100% 100%;
      }
    }
    .face {
      width: 0.9rem;
      height: height(100);
      opacity: 1;
      border-radius: 0.1rem;
      margin-right: 0.17rem;
      background: url(../assets/zp.png);
      background-size: 100% 100%;
    }
    .uname {
      font-size: 0.18rem;
      line-height: 1;
      margin-bottom: height(10);
    }
    .uscore {
      font-size: 0.14rem;
      line-height: 1;
    }
    .scroll {
      height: height(124);
      overflow: auto;
    }
    .close_btn {
      cursor: pointer;
      width: 0.14rem;
      height: 0.14rem;
      background: url(../assets/cxx1.png) no-repeat center center / auto 0.14rem;
      position: absolute;
      top: height(15);
      right: 0.25rem;
    }
    .min_btn {
      cursor: pointer;
      width: 0.14rem;
      height: 0.14rem;
      background: url(../assets/sf.png) no-repeat center center / auto 0.14rem;
      position: absolute;
      top: height(15);
      right: 0.49rem;
    }
    .tag {
      font-size: 0.14rem;
      line-height: height(56);
      text-align: center;
      color: #fff;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .title {
      font-size: 0.16rem;
      line-height: height(38);
      text-align: left;
      color: #fff;
      padding-top: height(10);
    }
    .table {
      width: 100%;
       border-bottom: 1px solid #1e2953;
      .th,
      .tr {
        display: flex;
        border: 1px solid #1e2953;
        .td {
          flex: 1;
          color: #fff;
          font-size: 0.14rem;
          line-height: height(40);
          text-align: center;
          &:nth-child(2) {
            border-left: 1px solid #1e2953;
            border-right: 1px solid #1e2953;
          }
        }
      }
      .th {
        background: #060d22;
        .td {
          color: #549cff;
        }
      }
    }
  }
}
</style>