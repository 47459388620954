<template>
  <div class="fixed_modal">
    <div class="pop">
      <div class="title">村民信息</div>
      <div class="tag">户主：{{info.name}} 分布类型：{{info.is_type | typename}}</div>
      <div class="close_btn" @click="$parent.$parent.pop_info=null"></div>
      <div class="table">
        <div class="th">
          <div class="td">补助金额</div>
          <div class="td">月份</div>
          <div class="td">保障总人口</div>
        </div>
         <div class="tr" v-for="item in info.lists">
          <div class="td">{{item.money}}</div>
          <div class="td">{{item.date}}</div>
          <div class="td">{{item.num}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script >
export default {
 props:["info"],
 filters:{
   typename(val){
     return val==1?'一般人群':val==2?'边缘':val==3?'残疾':'低保'
   }
 },
 methods: {
   closepop(){

   }
 },
};
</script>
<style lang="scss" scoped>
.fixed_modal {
  width: 4.1rem;
  height: height(370);
  border: solid 1px #142b5f;
  box-sizing: content-box;
  border-radius: 0.1rem;
  padding: 0.05rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 10000;
  .pop {
    width: 4.1rem;
    height: height(370);
    background: #001025;
    border-radius: 0.1rem;
    padding: 0 0.3rem;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.8),
      inset 0px 0px 30px 0px rgba(44, 87, 228, 0.5);
      .close_btn{
          cursor: pointer;
          width: 0.14rem;
          height: 0.14rem;
          background: url(../assets/close.png) no-repeat center center /auto 0.14rem;
          position: absolute;
          top: height(15);
          right: 0.15rem;
      }
    .tag {
      font-size: 0.14rem;
      line-height: height(56);
      text-align: center;
      color: #fff;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .title {
      font-size: 0.18rem;
      line-height: height(38);
      text-align: center;
      color: #fff;
      border-bottom: 1px solid rgba(255,255,255,0.3);
      padding-top: height(10);

    }
    .table {
      width: 100%;
       border-bottom: 1px solid #1e2953;
      .th,
      .tr {
        display: flex;
             border: 1px solid #1e2953;
        .td {
          flex: 1;
          color: #fff;
          font-size: 0.14rem;
          line-height: height(40);
          text-align: center;
          &:nth-child(2){
                  border-left: 1px solid #1e2953;
                  border-right: 1px solid #1e2953;
              }
        }
      }
         .th{
           background: #060d22;
          .td{
              color: #549cff;
          }
      }
    }
  }
}
</style>