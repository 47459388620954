import Vue from 'vue'
/* import App from './App.vue' */
import Bainiu from './Bainiu.vue'
import router from "./router"
import store from "./vuex"
import vEchart from "./components/echart"
import vSwitch from "./components/switch"

Vue.component('vEchart',vEchart)

Vue.component('vSwitch',vSwitch)

Vue.config.productionTip = false
Vue.prototype.gotonewpage = function(url){
  window.open(url)
}

// Vue.prototype.urlx = "http://kityo.cn:88/txh"
Vue.prototype.urlx = "/img"

new Vue({
  router,
  store,
  render: h => h(Bainiu),
}).$mount('#app')
