import Vue from 'vue'
import Vuex from 'vuex'
 
//注入vuex
Vue.use(Vuex)

const store = new Vuex.Store({
	state:{
		list:[]//覆盖物,
	},
	mutations:{
		//设置覆盖物
		SET_LIST:(state,list) => {
			state.list=list
		}/* ,
		SET_POPTYPE:(state,val)=>{
			state.poptype = val
		} */
	}
})
 
export default store;