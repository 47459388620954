import axios from "../utils/request"
//export const  screenInfo=()=>axios.get("/api/AppYsw/screenInfo")
export const  Village=()=>axios.post('index/village/village',{appid:'datatgAiIkIedLDO8Jma',noncestr:randomString(10)})

export const  Digitpeople=()=>axios.post('index/Digitpeople/people',{appid:'datatgAiIkIedLDO8Jma',noncestr:randomString(10)})
export const  Survey=()=>axios.post('index/Survey/survey',{appid:'datatgAiIkIedLDO8Jma',noncestr:randomString(10)})

export const  Tour=()=>axios.post('index/tour/tour',{appid:'datatgAiIkIedLDO8Jma',noncestr:randomString(10)})

export const  Shop=()=>axios.post('index/shop/shop',{appid:'datatgAiIkIedLDO8Jma',noncestr:randomString(10)})

export const  Manage=()=>axios.post('index/manage/manage',{appid:'datatgAiIkIedLDO8Jma',noncestr:randomString(10)})

export const  get_type=()=>axios.post('index/Digitpeople/type',{appid:'datatgAiIkIedLDO8Jma',noncestr:randomString(10)})

export const  Environment=()=>axios.post('index/Environment/info',{appid:'datatgAiIkIedLDO8Jma',noncestr:randomString(10)})
//预警
export const  getWarming=()=>axios.post('index/Environment/getWarming',{appid:'datatgAiIkIedLDO8Jma',noncestr:randomString(10)})
//随手拍
export const  suishoupai=()=>axios.post('index/Environment/pai',{appid:'datatgAiIkIedLDO8Jma',noncestr:randomString(10)})

export const  circle=()=>axios.post('index/digitpeople/circle',{appid:'datatgAiIkIedLDO8Jma',noncestr:randomString(10)})

export const  culture=()=>axios.post('index/digitpeople/culture',{appid:'datatgAiIkIedLDO8Jma',noncestr:randomString(10)})

export const  honor=()=>axios.post('index/digitpeople/honor',{appid:'datatgAiIkIedLDO8Jma',noncestr:randomString(10)})

export const  party_work=()=>axios.post('index/manage/party_work',{appid:'datatgAiIkIedLDO8Jma',noncestr:randomString(10)})

export const  assets=()=>axios.post('index/Town/assets',{appid:'datatgAiIkIedLDO8Jma',noncestr:randomString(10)})

function randomString(len) {
　　len = len || 32;
　　var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';    /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
　  var maxPos = $chars.length;
　　var pwd = '';
　　for (var i = 0; i < len; i++) {
　　　pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return pwd;
}