<template>
  <div class="bg-box">
    <div class="form-box">
      <div class="delBtn zhuanquan" @click="$parent.$parent.show = false;$parent.$parent.$refs.btnclaer.index=[]"></div>
      <div class="f-title mb-59">白牛电商大数据科学决策</div>
      <div class="f-charts h-534">
        <div class="f-chart f-2" style="flex:1;">
          <div class="c-title">山核桃成交地图流向</div>
          <div class="map_box">
            <v-echart :option="option_map"></v-echart>
          </div>
        </div>
        <div class="f-chart min">
          <div class="c-title">各省零售电商成交额占比</div>
          <div class="map_box">
            <v-echart :option="option_bar"></v-echart>
          </div>
        </div>
        <div class="f-chart min">
          <div class="c-title">各类坚果消费者画像</div>
          <div class="">
            <right-top :data="data"></right-top>
          </div>
        </div>
      </div>
      <div class="c-title">白牛销售数据、热销商品、潜力商品一览</div>
      <div class="f-charts">
        <div class="f-chart">
          <div class="f-chart-title">各类产品销售数据</div>
          <div class="right">
            <div class="partysort vl">
              <div class="partycate headx">
                <div class="p_num">产品名称</div>
                <div class="p_name">销量</div>
                <div class="p_score">营收额</div>
              </div>
              <div class="scolltablebox">
                <div
                  :class="[index % 2 == 1 && 'x1', 'partycate sale2']"
                  v-for="(item, index) in sale"
                >
                  <div class="p_num">{{ item.keywords_name }}</div>
                  <div class="p_name">{{ item.sum_sales }}</div>
                  <div class="p_score">{{ item.sum_turnover }}</div>
                </div>
                 <div
                  :class="[index % 2 == 1 && 'x1', 'partycate sale2']"
                  v-for="(item, index) in sale"
                >
                  <div class="p_num">{{ item.keywords_name }}</div>
                  <div class="p_name">{{ item.sum_sales }}</div>
                  <div class="p_score">{{ item.sum_turnover }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="f-chart">
          <div class="f-chart-title">热销商品排行榜</div>
          <div class="right">
            <div class="partysort vl">
              <div class="partycate headx">
                <div class="p_num p2">排名</div>
                <div class="p_name p2">商品名称</div>
                <div class="p_score p2">热销指数</div>
                <div class="p_price">客单价</div>
                <div class="p_change">排名变化</div>
              </div>
              <div class="scolltablebox">
                <div
                  iv
                  :class="[index % 2 == 1 && 'x1', 'partycate hot2']"
                  v-for="(item, index) in hot"
                >
                  <div class="p_num p2">{{ item.top }}</div>
                  <div class="p_name p2">
                    <div class="move_1">
                      <div class="move_2">{{ item.goodName }}</div>
                      <div class="move_2">{{ item.goodName }}</div>
                    </div>
                  </div>
                  <div class="p_score p2">{{ item.hot }}</div>
                  <div class="p_price">{{ item.Price }}</div>
                  <div class="p_change">{{ item.changes }}</div>
                </div>
                <div
                  iv
                  :class="[index % 2 == 1 && 'x1', 'partycate hot2']"
                  v-for="(item, index) in hot"
                >
                  <div class="p_num p2">{{ item.top }}</div>
                  <div class="p_name p2">
                    <div class="move_1">
                      <div class="move_2">{{ item.goodName }}</div>
                      <div class="move_2">{{ item.goodName }}</div>
                    </div>
                  </div>
                  <div class="p_score p2">{{ item.hot }}</div>
                  <div class="p_price">{{ item.Price }}</div>
                  <div class="p_change">{{ item.changes }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="f-chart">
          <div class="f-chart-title">潜力商品排行榜</div>
          <div class="right">
            <div class="partysort vl">
              <div class="partycate headx">
                <div class="p_num p2">排名</div>
                <div class="p_name p2">商品名称</div>
                <div class="p_score p2">热销指数</div>
                <div class="p_price">客单价</div>
                <div class="p_change">排名变化</div>
              </div>
              <div class="scolltablebox">
                <div
                  iv
                  :class="[index % 2 == 1 && 'x1', 'partycate potential2']"
                  v-for="(item, index) in potential"
                >
                  <div class="p_num p2">{{ item.top }}</div>
                  <div class="p_name p2">
                    <div class="move_1">
                      <span class="move_2">{{ item.goodName }}</span>
                      <span class="move_2">{{ item.goodName }}</span>
                    </div>
                  </div>
                  <div class="p_score p2">{{ item.hot }}</div>
                  <div class="p_price">{{ item.Price }}</div>
                  <div class="p_change">{{ item.changes }}</div>
                </div>
                <div
                  iv
                  :class="[index % 2 == 1 && 'x1', 'partycate potential2']"
                  v-for="(item, index) in potential"
                >
                  <div class="p_num p2">{{ item.top }}</div>
                  <div class="p_name p2">
                    <div class="move_1">
                      <span class="move_2">{{ item.goodName }}</span>
                      <span class="move_2">{{ item.goodName }}</span>
                    </div>
                  </div>
                  <div class="p_score p2">{{ item.hot }}</div>
                  <div class="p_price">{{ item.Price }}</div>
                  <div class="p_change">{{ item.changes }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import rightTop from "./right_bottomcopy";
export default {
  props: ["data", "hot", "sale", "potential", "mapx"],
  components: {
    rightTop,
  },
  data() {
    return {
      option_map: null,
      option_bar: null,
    };
  },
  mounted() {
    this.option_bar = this.get_option1(this.mapx);
    this.option_map = this.get_option(this.mapx);
    this.scolltable();
  },
  watch: {
    mapx() {
      this.option_bar = this.get_option1(this.mapx);
      this.option_map = this.get_option(this.mapx);
    },
  },
  methods: {
    get_option(val) {
      var option = null;
      var datamap = val;
      var geoCoordMap = {
        辽宁: [123.460172,41.685769],
        重庆: [108.384366, 30.439702],
        北京: [116.4551, 40.2539],
        上海:[121.477665,31.237658],
        江苏:[118.842091,32.07322],
        山东:[117.1218,36.640107],
        河北:[114.488184,38.04081],
        天津:[117.205216,39.0976],
        吉林:[125.324533,43.84078],
        上海:[126.529538,45.809426],
        内蒙古:[111.749188,40.852133],
        山西:[112.547197,37.875395],
        陕西:[108.948765,34.364196],
        甘肃:[103.826726,36.075501],
        青海:[101.77036,36.621365],
        宁夏:[106.232837,38.48795],
        新疆:[87.600705,43.828587],
        西藏:[91.172637,29.650634],
        四川:[104.069296,30.671229],
        贵州:[106.673079,26.643561],
        云南:[102.834278,24.907709],
        广西:[108.361381,22.811681],
        广东:[113.253607,23.128689],
        湖南:[112.94662,28.236672],
        湖北:[114.313886,30.603689],
        河南:[113.657287,34.753203],
        安徽:[117.229417,31.825842],
        浙江:[120.205162,30.244846],
        福建:[119.306345,26.082765],
        海南:[110.346995,20.039888],
        江西:[115.851937,28.690723],
        香港:[114.172893,22.284803],
        澳门:[114.172893,22.284803],
        台湾:[121.49865,25.053095],

        白牛村: [119.17781, 30.187313],
      };

      var planePath =
        "path://M1705.06,1318.313v-89.254l-319.9-221.799l0.073-208.063c0.521-84.662-26.629-121.796-63.961-121.491c-37.332-0.305-64.482,36.829-63.961,121.491l0.073,208.063l-319.9,221.799v89.254l330.343-157.288l12.238,241.308l-134.449,92.931l0.531,42.034l175.125-42.917l175.125,42.917l0.531-42.034l-134.449-92.931l12.238-241.308L1705.06,1318.313z";

      var dataFrom = "白牛村";

      var convertData = function (data1) {
        var res = [];
        console.log(data1, 88888888);
        for (var i = 0; i < data1.length; i++) {
          var geoCoord = geoCoordMap[data1[i].name];

          if (geoCoord) {
            res.push({
              name: data1[i].name,
              value: geoCoord.concat(data1[i].value),
            });
          }
        }
        console.log(res);
        return res;
      };

      option = {
        tooltip: {
          /* trigger: "item", */
          formatter:(val)=>{
            if(val.value){
              return val.name+":"+val.value+"%";
            }else{
              return '';
            }
          }
        },

        visualMap: {
          type:"piecewise",
          min: 0,
          max: 1,
          left: "left",
          top: "bottom",
          text: ["高", "低"],
          calculable: false,
          show: false,
          inRange: {
            color: ["#293671", "#6bfcf6","#0d45dd"],
          },
        },
        geo: {
          map: "china",
          zoom: 1.2,
          label: {
            normal: {
              show: true,
              color: "#c1c4c8",
            },
            emphasis: {
              show: false,
              color: "#292929",
            },
          },
          roam: true,
          itemStyle: {
            normal: {
              areaColor: "#fbfbfb",
              borderColor: "#b9b4b7",
            },
            emphasis: {
              areaColor: "#fee619",
            },
          },
        },
        series: [
          {
            name: "",
            type: "lines",
            zlevel: 2,
            symbolSize: 10,
            effect: {
              show: true,
              period: 6,
              symbol: planePath,
              trailLength: 0,
              symbolSize: 10,
            },
            label:{
              show:false
            },
            lineStyle: {
              normal: {
                color:   new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  {
                    offset: 0,
                    color: "rgb(251,212,68,1)",
                  },
                  {
                    offset: 1,
                    color: "rgb(251,212,68,0)",
                  },
                ]),
                width: 2,
                opacity: 0.5,
                curveness: 0.2,
              },
            },
            data: this.mapx.filter(el=>geoCoordMap[el.name]).map(function (dataItem) {
              return {
                fromName: dataFrom,
                toName:  dataItem.name,
                coords:[geoCoordMap[dataFrom], geoCoordMap[dataItem.name]]
              };
            }),
          },
          {
            type: "effectScatter",
            coordinateSystem: "geo",
            data: convertData(datamap),
            symbolSize: 8,
            showEffectOn: "render",
            rippleEffect: {
              scale: 5,
              brushType: "stroke",
            },
            hoverAnimation: true,
            label: {
              normal: {
                formatter: "{b}",
                position: "right",
                show: true,
              },
              emphasis: {
                show: true,
              },
            },
            itemStyle: {
              normal: {
                color: "#63d4e9",
                shadowBlur: 10,
                shadowColor: "#333",
              },
            },
          },
          {
            type: "map",
            mapType: "china",
            geoIndex: 0,
            label: {
              normal: {
                show: true,
              },
              emphasis: {
                show: true,
              },
            },
            data: this.mapx,
          },
        ],
      };
      return option;
    },
    get_option1(val) {
      let option = null;
      var ydata = val.map((el) => {
        return el.name;
      });
      var xdata = val.map((el) => {
        return el.value;
      });
      option = {
        grid: {
          left: "2%",
          right: "10%",
          bottom: "0%",
          top: "5%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "none",
          },
          formatter: function (params) {
            return (
              params[0].name +
              "<br/>" +
              "<span style='display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:rgba(36,207,233,0.9)'></span>" +
              params[0].seriesName +
              " : " +
              params[0].value.toLocaleString() +
              " %<br/>"
            );
          },
        },
        xAxis: {
          show: false,
          type: "value",
        },
        yAxis: [
          {
            type: "category",
            inverse: true,
            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff",
                fontSize:10
              },
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            data: ydata,
          },
          {
            type: "category",
            inverse: true,
            axisTick: "none",
            axisLine: "none",
            show: true,
            axisLabel: {
              textStyle: {
                color: "#ffffff",
                fontSize: "10",
              },
              formatter: function (value) {
                /* if (value >= 10000) {
                  return (value / 10000).toLocaleString() + "万";
                } else {
                  return value.toLocaleString();
                } */
                return value.toLocaleString() + "%";
              },
            },
            data: xdata,
          },
        ],
        series: [
          {
            name: "成交占比",
            type: "bar",
            zlevel: 1,
            itemStyle: {
              normal: {
                barBorderRadius: 10,
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  {
                    offset: 0,
                    color: "rgb(57,89,255,1)",
                  },
                  {
                    offset: 1,
                    color: "rgb(46,200,207,1)",
                  },
                ]),
              },
            },
            barWidth: 5,
            data: xdata,
          },
        ],
      };
      return option;
    },

    scolltable() {
      if (this.hot.length > 5) {
        console.log(123456);
        var css = document.createElement("style");
        css.type = "text/css";
        css.innerHTML = `@keyframes rowup {
            0% {
                transform: translate3d(0, 0, 0);
            }
            100% {
                transform: translate3d(0, -${(this.hot.length - 5) * 30}px, 0);
            }
        }

        .hot2{
          animation: ${2000 * this.hot.length}ms rowup linear infinite normal;
        }`;
        document.head.appendChild(css);
      }
      if (this.sale.length > 5) {
        var css2 = document.createElement("style");
        css2.type = "text/css";
        css2.innerHTML = `@keyframes rowup2 {
            0% {
                transform: translate3d(0, 0, 0);
            }
            100% {
                transform: translate3d(0, -${(this.sale.length - 5) * 30}px, 0);
            }
        }

        .sale2{
          animation: ${2000 * this.sale.length}ms rowup2 linear infinite normal;
        }`;
        document.head.appendChild(css2);
      }
      if (this.potential.length > 5) {
        var css2 = document.createElement("style");
        css2.type = "text/css";
        css2.innerHTML = `@keyframes rowup2 {
            0% {
                transform: translate3d(0, 0, 0);
            }
            100% {
                transform: translate3d(0, -${
                  (this.potential.length - 5) * 30
                }px, 0);
            }
        }

        .potential2{
          animation: ${
            2000 * this.potential.length
          }ms rowup2 linear infinite normal;
        }`;
        document.head.appendChild(css2);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
div {
  box-sizing: border-box;
  line-height: 1;
}
.flex {
  display: flex;
}
.f-2 {
  flex: 2 !important;
  display: flex;
  flex-direction: column;
}
.h-534 {
  height: 5.34rem;
}
.mb-20 {
  margin-bottom: 0.2rem;
}
.mb-21 {
  margin-bottom: 0.21rem;
}
.mb-59 {
  margin-bottom: 0.59rem;
}
.bg-box {
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  .form-box {
    padding: 0.35rem 0.18rem 0 0.55rem;
    width: 13.1rem;
    height: 9.7rem;
    opacity: 1;
    background: url(../../assets/jcbj.png) no-repeat center center / cover;
    border-radius: 0.11rem;
    position: relative;
    overflow: hidden;
    // background: url($url+'pop1Bj.png') no-repeat center center /cover;
    .map_box {
      height: height(500);
    }
    .delBtn {
      position: absolute;
      width: 0.4rem;
      height: 0.4rem;
      background: url(../../assets/close.png) no-repeat center center / auto;
      top: 0.15rem;
      right: 0.25rem;
    }
    .f-title {
      font-size: 0.24rem;
      text-align: center;
      color: #85a4e8;
      margin-bottom: 0.59rem;
      font-weight: 500;
    }
    .c-title {
      font-size: 0.18rem;
      font-weight: 500;
      color: #ffffff;
      display: flex;
      align-items: center;
      &:before {
        display: block;
        content: "";
        width: 0.04rem;
        height: 0.16rem;
        background: #66dc95;
        border-radius: 2px;
        margin-right: 0.09rem;
      }
    }
    .video-box {
      width: 6rem;
      height: 3.38rem;
      margin-right: 0.21rem;
      margin-top: 0.02rem;
    }
    .intro-box {
      flex: 1;
      height: 3.3rem;
      overflow: auto;
      font-size: 0.18rem;
      font-weight: 500;
      color: #ffffff;
      line-height: 0.24rem;
    }
    .f-charts {
      display: flex;
      flex-wrap: wrap;
      padding-right: 0.2rem;
      .f-chart {
        width:31.5%;
        &.flex{
          flex: 1;
        }
        &.min{
          width:25%;
        }
        margin-right: 0.2rem;
        .f-chart-title {
          font-size: 0.14rem;
          height: height(50);
          line-height: height(50);
        }
        .right {
          width: 100%;
          margin-top: height(0);
          .partysort {
            width: 100%;
            height: auto;
            &.vl {
              width: 100%;
            }
            .partycate {
              display: flex;
              width: 100%;
              padding: 0 0.2rem;
              height: height(30);
              text-align: left;
              &.x1 {
                background: rgba(7, 52, 98, 0.3);
              }
              &.headx {
                background: #0a2157;
              }
              .p_num {
                width: 33%;
                color: #fff;
                font-size: 0.12rem;
                font-weight: 600;
                line-height: height(30);
                &.p2 {
                  width: 8%;
                }
              }
              .p_name {
                width: 33%;
                color: #fff;
                font-size: 0.12rem;
                font-weight: 600;
                line-height: height(30);
                overflow: hidden;
                .move_1 {
                  animation: move 20s linear infinite;
                  white-space: nowrap;
                  width: max-content;
                  .move_2 {
                    width: auto;
                    min-width: 100%;
                    line-height: height(30);
                    padding-right: 0.5rem;
                  }
                }
                &.p2 {
                  width: 40%;
                  overflow: hidden;
                  white-space: nowrap;
                }
              }
              .p_score {
                width: 33%;
                color: #fff;
                font-size: 0.12rem;
                font-weight: 600;
                line-height: height(30);
                &.p2 {
                  width: 20%;
                  text-align: center;
                }
              }
              .p_price,
              .p_change {
                width: 16%;
                color: #fff;
                font-size: 0.12rem;
                font-weight: 600;
                line-height: height(30);
                text-align: center;
              }
            }
          }
          .item_c {
            vertical-align: top;
            width: 1.49rem;
            height: height(70);
            display: inline-block;
            background: #001025;
            border: 1px solid #142b5f;
            border-radius: 7px;
            margin-right: 0.1rem;
            margin-bottom: height(20);
            &:nth-child(4) {
              margin-bottom: 0;
            }
            &:nth-child(5) {
              margin-bottom: 0;
            }
            &:nth-child(6) {
              margin-bottom: 0;
            }
            padding: height(12) 0;
            .item_c_title {
              font-size: 0.12rem;
              color: #fff;
              text-align: center;
              font-weight: bold;
              margin: 0 auto;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-between;
            }
            .item_c_number {
              font-weight: 700;
              margin-top: height(10);
              font-size: 0.26rem;
              text-align: center;
              color: #66dc95;
            }
          }
        }
      }
    }
  }
}
.scolltablebox {
  height: height(150);
  overflow: hidden;
}
@keyframes move {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-49.9%, 0);
  }
}
</style>
