<template>
  <div id="bbar2" class="chars" :houseArray="data"></div>
</template>
<script>
export default {
  props:['data'],
  data(){
    return{
      jobArray:[],
      datax:[],
      datay:[]
    }
  },
  computed: {
    option() {
      return {
        legend: {
          show: false,
        },
        grid: {
          left: "8%",
          top:"5%",
          bottom:"10%",
          right:"8%"
        },
         yAxis: {
          type: "value",

          splitLine: {
            show: false,
          },
          axisLabel: {
            color:'#85abf3'
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
        },
         xAxis: {
         type: "category",
            inverse: true,
            axisLabel: {
              show: true,
              textStyle: {
                color: "#85abf3",
              },
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
          data: this.datax,
        },
        series: [
          {
            z: 2,
            name: "value",
            type: "bar",
            data: this.datay,
            barCategoryGap:'70%',
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                  {
                    offset: 0,
                    color: "#0055fd",
                  },
                  {
                    offset: 1,
                    color: "#00aaff",
                  },
                ]),
              },
            },
            label: {
              show: true,
              position: "top",
              color: "#fff",
              fontSize: 14,
              offset: [0, 0],
            },
          },
        ],
      };
    },
  },
  created(){
    
  },
  methods: {
    
  },
  mounted() {
    this.myChart = echarts.init(document.getElementById("bbar2")); 
     window.addEventListener("resize", () => {
      console.log("窗口发生变化");
      this.myChart.resize();
    });
  },
  updated() {
    this.jobArray = this.data;
    for(var i=0;i<this.jobArray.length;i++){
      this.datax.push(this.jobArray[i].name);
      this.datay.push(parseInt(this.jobArray[i].value))
    }
    this.myChart.setOption(this.option);
  },
};
</script>
<style scoped>
.chars {
  width: 100%;
  height: 100%;
}
</style>