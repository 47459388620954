<template>
  <div class="page">
    <v-header></v-header>
    <div class="content">
      <div class="left">
        <div class="top">
          <div class="left_line"></div>
          <div class="right_line"></div>
          <div class="bottom_line"></div>
          <div class="top_line"></div>
          <div class="title">同心荟概览</div>
          <div class="text_con">
            <div class="li">
              <div class="t1">同心荟成员总数（人）</div>
              <div class="t2">137</div>
            </div>
            <div class="li">
              <div class="t1">辐射人数（人）</div>
              <div class="t2">1000</div>
            </div>
            <div class="li">
              <div class="t1">回乡创业青年占比</div>
              <div class="t2">77.6%</div>
            </div>
            <div class="li">
              <div class="t1">辐射范围（km²）</div>
              <div class="t2">3100</div>
            </div>
          </div>
        </div>
        <div class="midd">
          <div class="left_line"></div>
          <div class="right_line"></div>
          <div class="bottom_line"></div>
          <div class="top_line"></div>
          <div class="title">同心荟活动安排预告</div>
          <div class="hr20"></div>
          <div class="table_box">
            <div class="table">
              <div class="thead">
                <div class="th">活动时间</div>
                <div class="th flex">活动名称</div>
              </div>
              <div class="tbody gun">
                <div class="tt1">
                  <div class="tr" v-for="(item,index) in act">
                    <div class="td">{{item.act_time}}</div>
                    <div class="td flex">{{item.act_name}}</div>
                  </div>
                  <!-- <div class="tr" v-for="(item,index) in act">
                    <div class="td">{{item.act_time}}</div>
                    <div class="td flex">{{item.act_name}}</div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom">
          <div class="left_line"></div>
          <div class="right_line"></div>
          <div class="bottom_line"></div>
          <div class="top_line"></div>
          <div class="title">同心荟历史活动回顾（已办82场）</div>
          <div class="hr20"></div>
          <div class="table_box">
            <div class="table">
              <div class="thead">
                <div class="th">活动时间</div>
                <div class="th flex">活动名称</div>
                <div class="th">活动地点</div>
              </div>
              <div class="tbody">
                <div class="tt6">
                  <div class="tr" @click="show=3,onchecked=item" v-for="(item,index) in act_f">
                    <div class="td">{{item.act_time}}</div>
                    <div class="td flex">{{item.act_name}}</div>
                    <div class="td">{{item.addr}}</div>
                  </div>
                  <div class="tr" @click="show=3,onchecked=item" v-for="(item,index) in act_f">
                    <div class="td">{{item.act_time}}</div>
                    <div class="td flex">{{item.act_name}}</div>
                    <div class="td">{{item.addr}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="left_line"></div>
        <div class="right_line"></div>
        <div class="bottom_line"></div>
        <div class="top_line"></div>
        <v-map v-if="type==2"></v-map>
        <v-lin v-if="type==1"></v-lin>
        <div class="btn" @click="type==1?type=2:type=1">{{type==1?'全国地图':'临安地图'}}</div>
        <div v-if="type==1" class="yqrtitle"></div>
      </div>
    </div>
    <v-modal v-if="show==1" :obj="onchecked_2"></v-modal>
    <v-video v-if="show==2" :obj="onchecked_3"></v-video>
    <v-images v-if="show==3" :obj="onchecked"></v-images>
    <v-shipin v-if="show==4" :obj="onchecked_4"></v-shipin>
  </div>
</template>
<script>

import vHeader from "./header/index";
import vMap from "./map/index";
import vLin from "./lin/index";
import vModal from "./modal/index";
import vVideo from "./modal/video";
import vImages from "./modal/images";
import vShipin from "./modal/shipin";
import actData from './data/act.json'
var actx = actData;
console.log(actData)
export default {
  props:['type'],
  components: {
    vHeader,
    vMap,
    vLin,
    vModal,
    vVideo,
    vImages,
    vShipin
  },
  created() {
    if(this.type){
      console.log(this.type,'iiiiiiiiiiiiiiiiiiii')
      document.title="一群人"
    }else{
      document.title="云上白牛·同心荟"
    }
    window.showTag = (re,res)=>{
      this.show = re;
      console.log(this.show)
      if(re==1){
        this.onchecked_2=res;
      }
      if(re==2){
        this.onchecked_3=res;
      }
      if(re==4){
        this.onchecked_4=res;
      }
    }
  },
  data(){
    return {
      type:1,
      show:0,
      act:actData.data.foreshow,
      act_f:actData.data.finshedact||[{}],
      onchecked:{},
      onchecked_2:{},
      onchecked_3:{},
      onchecked_4:{}
    }
  },
  mounted() {
    this.scolltable_1();
    this.scolltable_2();
  },
  beforeDestroyed() {
    document.title="云上白牛乡村小脑一张图"
  },
  methods: {
    scolltable_1() {
      if (this.act && this.act.length > 8 ) {
        var css = document.createElement("style");
        css.type = "text/css";
        css.innerHTML = `@keyframes rowup {
            0% {
                transform: translate3d(0, 0, 0);
            }
            100% {
                transform: translate3d(0, -${(this.act.length-8) * 30}px, 0);
            }
        }

        .tt1{
          animation: ${1000 * this.act.length}ms rowup linear infinite normal;
        }`;
        document.head.appendChild(css);
      }
    },
     scolltable_2() {
      if (this.act_f && this.act_f.length > 4 ) {
        var css2 = document.createElement("style");
        css2.type = "text/css";
        css2.innerHTML = `@keyframes rowup6 {
            0% {
                transform: translate3d(0, 0, 0);
            }
            100% {
                transform: translate3d(0, -${(this.act_f.length-4) * 30}px, 0);
            }
        }

        .tt6{
          animation: ${1000 * this.act_f.length}ms rowup6 linear infinite normal;
        }`;
        document.head.appendChild(css2);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  background: url(../../assets/bbg.png) center/cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10000;
  .title {
    height: height(56);
    line-height: height(56);
    padding-left: 0.28rem;
    font-weight: bold;
    font-size: 0.18rem;
    color: #ffffff;
    border-bottom: height(1) solid;
    position: relative;
    border-image: linear-gradient(
        -90deg,
        rgba(15, 100, 210, 0) 0%,
        rgba(15, 100, 210, 1) 100%
      )
      19 19;
    background: url(../../assets/dz.png) no-repeat left center/auto height(17),
      url(../../assets/dzz.png) no-repeat right center/auto height(11);
    &::before {
      content: "";
      width: 1.7rem;
      background: #0f64d2;
      height: height(3);
      position: absolute;
      bottom: height(-2);
      left: 0;
    }
  }

  .content {
    flex: 1;
    display: flex;
    padding: height(20);
    .left_line {
      width: 2px;
      height: 70%;
      background: linear-gradient(
        0deg,
        rgba(103, 187, 251, 0) 0%,
        rgba(103, 187, 251, 1) 50%,
        rgba(103, 187, 251, 0) 100%
      );
      position: absolute;
      left: -2px;
      top: 15%;
    }
    .right_line {
      width: 2px;
      height: 70%;
      background: linear-gradient(
        0deg,
        rgba(103, 187, 251, 0) 0%,
        rgba(103, 187, 251, 1) 50%,
        rgba(103, 187, 251, 0) 100%
      );
      position: absolute;
      right: -2px;
      top: 15%;
    }
    .top_line {
      width: 70%;
      height: 2px;
      background: linear-gradient(
        90deg,
        rgba(103, 187, 251, 0) 0%,
        rgba(103, 187, 251, 1) 50%,
        rgba(103, 187, 251, 0) 100%
      );
      position: absolute;
      top: -2px;
      left: 15%;
    }
    .bottom_line {
      width: 70%;
      height: 2px;
      background: linear-gradient(
        90deg,
        rgba(103, 187, 251, 0) 0%,
        rgba(103, 187, 251, 1) 50%,
        rgba(103, 187, 251, 0) 100%
      );
      position: absolute;
      bottom: -2px;
      left: 15%;
    }
    .left {
      width: 4.4rem;
      margin-right: 0.2rem;
      .top {
        width: 4.4rem;
        height: height(265);
        opacity: 1;
        background: rgba(0, 8, 43, 0.6);
        border: 2px solid #0b3ba0;
        padding: 0 0.2rem;
        position: relative;
        .text_con {
          display: flex;
          flex-wrap: wrap;
          .li {
            width: 48%;
            flex-shrink: 0;
            padding-left: 0.2rem;
            margin-bottom: height(8);
            .t1 {
              font-size: 0.14rem;
              color: #fff;
              padding-top: height(26);
              white-space: nowrap;
              line-height: 1;
            }
            .t2 {
              font-size: 0.36rem;
              font-weight: 500;
              text-align: left;
              color: #60ffcc;
              line-height: 1;
              margin-top: height(12);
            }
          }
        }
      }
      .midd {
        width: 4.4rem;
        height: height(370);
        opacity: 1;
        background: rgba(0, 8, 43, 0.6);
        border: 2px solid #0b3ba0;
        margin-top: 0.2rem;
        padding: 0 0.2rem;
        position: relative;
        .table_box {
          height: height(270);
        }
      }
      .bottom {
        width: 4.4rem;
        height: height(290);
        opacity: 1;
        background: rgba(0, 8, 43, 0.6);
        border: 2px solid #0b3ba0;
        margin-top: 0.2rem;
        padding: 0 0.2rem;
        position: relative;
        .table_box {
          height: height(150);
        }
      }
    }
    .table {
      width: 100%;
      height: 100%;
      overflow: hidden;
      padding-top: height(30);
      position: relative;
      .flex {
        flex: 1;
      }
      .tr {
        display: flex;
        cursor: pointer;
      }
      .th,
      .td {
        padding: 0 0.2rem;
        color: #fff;
        height: height(30);
        line-height: height(30);
        text-align: center;
      }
      .thead {
        background: #0f63d0;
        height: height(30);
        line-height: height(30);
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 100;
      }
    }
    .right {
      flex: 1;
      height: height(955);
      opacity: 0.8;
      background: url(../../assets/bg1.jpg)no-repeat center/cover;
      border: 2px solid #0b3ba0;
      position: relative;
      .btn {
        width: 1.28rem;
        height: 0.5rem;
        opacity: 1;
        background: #0f1947;
        line-height: 0.5rem;
        text-align: center;
        position: absolute;
        top: height(20);
        right: 0.3rem;
        color: #fff;
        font-size: 0.18rem;
        cursor: pointer;
        z-index: 12000;
      }
      .yqrtitle{
        position: absolute;
        top: height(40);
        width: 100%;
        height: height(41);
        left: 0;
        background:url(../../assets/yqrtitle.png)no-repeat left height(20) center/100% 100%;
      }
    }
  }
}
.gun{
  height: height(120);
}
</style>
