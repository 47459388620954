<template>
  <div class="c_box3">
    <div class="item_title passengerflow">
      白牛客流监测 <span class="look_more"><span>查看详情</span>>></span>
    </div>
    <div class="content">
      <div class="monitoring"></div>
      <div class="person_data">
        <div class="sexbox">
          <div class="data_title">用户画像比例</div>
          <div class="s_item">
            <div class="imgbox">
              <div class="item_img"></div>
              <div class="item_img"></div>
              <div class="item_img"></div>
              <div class="item_img"></div>
              <div class="item_img an"></div>
              <div class="item_img an"></div>
              <div class="item_img an"></div>
              <div class="item_img an"></div>
              <div class="item_img an"></div>
              <div class="item_img an"></div>
            </div>
            <div class="data_desc">
              <div class="desc_title">男性游客</div>
              <div class="data_percent">25.8%</div>
            </div>
          </div>
          <div class="s_item">
            <div class="imgbox">
              <div class="item_img"></div>
              <div class="item_img"></div>
              <div class="item_img"></div>
              <div class="item_img"></div>
              <div class="item_img an"></div>
              <div class="item_img an"></div>
              <div class="item_img an"></div>
              <div class="item_img an"></div>
              <div class="item_img an"></div>
              <div class="item_img an"></div>
            </div>
            <div class="data_desc">
              <div class="desc_title">女性游客</div>
              <div class="data_percent">25.8%</div>
            </div>
          </div>
        </div>
        <div class="person_flow">
          <div class="data_title">客流统计</div>
          <div class="today_data">
            <div class="today_person">
              <div class="t_title">今日客流数</div>
              <div class="t_number">3.86<span>万人</span></div>
            </div>
            <div class="today_person year">
              <div class="t_title">年度客流总数</div>
              <div class="t_number">245.8<span>万人</span></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scope>
.c_box3 {
  width: 100%;
  height: 100%;
  padding: height(10) 0.1rem;
  font-size: 0;
  .content {
    width: 100%;
    margin-top: height(10);
    .monitoring {
      width: 100%;
      height: height(185);
      border: 1px solid #142b5f;
      border-radius: 5px;
    }
    .person_data {
      width: 100%;
      display: flex;
      .data_title {
        height: height(35);
        font-size: 0.14rem;
        font-weight: 700;
        color: #fff;
        line-height: height(35);
      }
      .person_flow {
        flex: 1;
        .today_data {
          height: height(90);
          background: #001025;
          border: 1px solid #142b5f;
          border-radius: 5px;
         .today_person{
           height: auto;
           margin-top: height(2);
           margin-left: 0.1rem;
           &.year{
             position: relative;
             top: height(-10);
           }
            .t_title {
              color: #fff;
              font-size: 0.2rem;
              transform: scale(0.5);
              transform-origin:left top;
              font-weight: 700;
              white-space: nowrap;
            }
            .t_number{
              font-size: 0.24rem;
              font-weight: 700;
              color: #66dc95;
              position: relative;
              top: height(-10);
              line-height: 1;
              & span{
                font-size: 0.12rem;
                margin-left: 0.08rem;
              }
            }
         }
         .today_person{
            .t_title {
              color: #fff;
              font-size: 0.2rem;
              transform: scale(0.5);
              transform-origin:left top;
              font-weight: 700;
              white-space: nowrap;
            }
            .t_number{
              font-size: 0.24rem;
              font-weight: bold;
              color: #66dc95;
            }
         }
        }
      }
      .sexbox {
        width: 2rem;
        margin-right: 0.05rem;
        .s_item {
          height: height(40);
          background: #001025;
          border: 1px solid #142b5f;
          border-radius: 5px;
          margin-bottom: height(10);
          display: flex;
          .imgbox {
            display: flex;
            flex: 1;
            align-items: center;
            padding-left: 0.1rem;
            .item_img {
              width: calc(100% / 10);
              height: height(20);
              margin-right: 0.05rem;
              background: url(../../assets/n2.png) no-repeat center/auto
                height(20);
              &.an {
                background: url(../../assets/n.png) no-repeat center/auto
                  height(20);
              }
              &:nth-child(10) {
                margin-right: 0;
              }
            }
          }
          .data_desc {
            text-align: center;
            padding-top: height(2);
            height: 100%;
            .desc_title {
              font-size: 0.2rem;
              color: #fff;
              transform: scale(0.5);
              transform-origin: top;
            }
            .data_percent {
              font-size: 0.14rem;
              color: #66dc95;
              position: relative;
              font-weight: 700;
              top: height(-10);
            }
          }
        }
      }
    }
  }
}
.item_title.passengerflow {
  background: url(../../assets/kljc.png) no-repeat left 0.1rem center/14px auto,
    linear-gradient(to left, rgba(9, 33, 87, 0), rgba(9, 33, 87, 1));
}
</style>