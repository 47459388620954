<template>
	<div :class='["pop_box",show&&"on",remove&&"remove"]' @touchmove.stop="stop">
		<div class="model" @click="close" v-if="!remove&&model"></div>
		<div class="blur" @click="close" v-if="!remove&&blur"></div>
		<div class="spec_con" v-if="show">
           <slot></slot>
		</div>
	</div>
</template>

<script>
	export default {
		props:["show","model","blur"],
		data(){
			return {remove:false}
		},
		methods:{
			stop(){},
			close(){
				this.remove=true
				setTimeout(()=>{
					this.$emit("update:show",false)
					this.remove=false
				},500)
			}
		}
	}
</script>

<style lang="scss" scoped>
.pop_box {
	display: none;
	&.on{
		display: block;
		.spec_con{
			animation: swipeUp 500ms forwards;
		}
	}
	&.remove{
		.spec_con{
			animation: swipeDown 500ms forwards;
		}
	}
	.model {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.8);
		z-index: 99999;
	}
	.blur {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: rgba(0,0,0,0);
		background-size:cover ;
		z-index: 999999;
	}
	.spec_con {
		width: 19.2rem;
		overflow: hidden;
		position: fixed;
		bottom: 0;
		left: 0;
		z-index: 999999;
	}
}
 @keyframes swipeDown{
	 0% {
	     -webkit-transform: translate(0,0);
	 }
	 100% {
	     -webkit-transform: translate(0,100%);
	 }
 }
 @keyframes swipeUp{
	 0% {
	     -webkit-transform: translate(0,100%);
	 }
	 100% {
	     -webkit-transform: translate(0,0);
	 }
 }
</style>
