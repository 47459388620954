<template>
  <div class="left_center">
    <div class="title">白牛村土地划分</div>
    <v-echart :option="option" :act='true'></v-echart>
  </div>
</template>
<script>
var colorList=[
    "#2ccc44",
  "#ff3976",
  "#6173d6",
  "#914ce5",
  "#42b1cc",
  "#ff55ac",
  "#0090ff",
  "#06d3c4",
  "#ffbc32",
  "#2ccc44",
  "#ff3976",
  "#6173d6",
  "#914ce5",
  "#42b1cc",
  "#ff55ac",
  "#0090ff",
  "#06d3c4",
  "#ffbc32",
  "#2ccc44",
  "#ff3976",
  "#6173d6"
]
export default {
  props:['leftCenter'],
  data() {
    return {
      option: null,
    };
  },
  watch:{
    leftCenter(){
      this.setEchart();
    }
  },
  mounted() {
    this.setEchart();
  },
  methods: {
     setEchart() {
      const legendList = [];
      this.leftCenter.forEach((el) => {
        legendList.push(el.name);
      });
      this.option = {
        color:colorList,
        /* tooltip: {
          trigger: "item",
          formatter: "{b}: {c} ({d}%)",
        }, */
        legend: {
          left: "center",
          bottom: "10%",
          textStyle: {
            color: "#fff",
          },
          data: legendList,
        },
        series: [
          {
            name: "",
            type: "pie",
            top: "15%",
            radius: ["40%", "60%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              formatter: "{b}: \n{c} ({d}%)",
              color:'#fff',
              position: "outside",
            },
            center: ["50%", "30%"],
            emphasis: {
              label: {
                show: true,
              },
              labelLine:{
                show:false
              }
            },
            data: this.leftCenter,
          },
        ],
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.left_center {
  width: 4.5rem;
  height: height(260);
  background-color: #0b132d;
  border-radius: 0.1rem;
  position: absolute;
  top:  height(440);
  left: 0.2rem;
  z-index: 100;
  display: flex;
  flex-direction: column;
}
</style>