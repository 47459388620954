<template>
  <div class="zbtnbox">
    <div class="abc"></div>
    <div
      :class="[onover === 0 && 'on', 'zbtnx']"
      @click="$parent.show=false;$parent.poptype = 0;changeonover(0, onover === 0)"
      style="color:#dc251c"
    >
      <!--  乡村环境 -->一面旗
      <div :class="['zcatebox']" v-if="onover === 0" @click.stop="">
        <div
          class="zcate"
          :class="index.includes('coor') && 'on'"
          @click.stop="handle_push('coor')"
        >
          <div :class="[index.includes('coor') && 'on', 'zcate_check']">
            <div class="zcate_name">党员户</div>
          </div>
        </div>
        <div
          class="zcate"
          :class="index.includes('dyfs') && 'on'"
          @click.stop="
            $parent.show = true;
            $parent.poptype = 6;
            handle_push('dyfs');
          "
        >
          <div :class="[index.includes('dyfs') && 'on', 'zcate_check']">
            <div class="zcate_name">党员先锋指数</div>
          </div>
        </div>
        <!-- <div class="zcate" @click.stop="handle_push('gaizao')">
          <div :class="[index.includes('gaizao') && 'on', 'zcate_check']"></div>
          <div class="zcate_name">环境整治</div>
        </div>
        <div class="zcate" @click.stop="handle_push('qixiang')">
          <div
            :class="[index.includes('qixiang') && 'on', 'zcate_check']"
          ></div>
          <div class="zcate_name">气象设备</div>
        </div>
        <div class="zcate" @click.stop="handle_push('shuiwei')">
          <div
            :class="[index.includes('shuiwei') && 'on', 'zcate_check']"
          ></div>
          <div class="zcate_name">水位设备</div>
        </div> -->
      </div>
    </div>
    <div
      :class="[onover == 1 && 'on', 'zbtnx']"
      @click="gotonewpage('//effect.linanpecans.com/lasht/home')"
    >
      <!-- 景区监测 -->一棵树
      <!-- <div :class="[onover[1] == true && 'on', 'zcatebox']" @click.stop="">
        <div class="zcate" @click.stop="handle_push('jiankong')">
          <div
            :class="[index.includes('jiankong') && 'on', 'zcate_check']"
          ></div>
          <div class="zcate_name">景区监控</div>
        </div>
      </div> -->
    </div>
    <div
      :class="[onover == 2 && 'on', 'zbtnx']"
      @click="
        $parent.poptype = 19;
        changeonover(2, onover == 2);
      "
    >
      <!-- 数字村民 -->一张网
      <!-- <div :class="[onover[2] == true && 'on', 'zcatebox']" @click.stop=""> -->
      <!-- <div class="zcate" @click.stop="handle_push('guanhuai')">
          <div :class="[index.includes('guanhuai') && 'on', 'zcate_check']"></div>
          <div class="zcate_name">老人关怀</div>
        </div> -->
      <!-- <div class="zcate" @click.stop="handle_push('coor')">
          <div :class="[index.includes('coor') && 'on', 'zcate_check']"></div>
          <div class="zcate_name">党员户</div>
        </div> -->
      <!--  <div class="zcate" @click.stop="handle_push('di')">
          <div :class="[index.includes('di') && 'on', 'zcate_check']"></div>
          <div class="zcate_name">低保人群</div>
        </div>
        <div class="zcate" @click.stop="handle_push('bian')">
          <div :class="[index.includes('bian') && 'on', 'zcate_check']"></div>
          <div class="zcate_name">边缘人群</div>
        </div>
        <div class="zcate" @click.stop="handle_push('can')">
          <div :class="[index.includes('can') && 'on', 'zcate_check']"></div>
          <div class="zcate_name">残疾人群</div>
        </div> -->
      <!-- <div class="zcate" @click.stop="handle_push('shijian')">
          <div :class="[index.includes('shijian') && 'on', 'zcate_check']"></div>
          <div class="zcate_name">事件上报</div>
        </div> -->
      <!-- </div> -->
    </div>
    <div
      :class="[onover == 3 && 'on', 'zbtnx']"
      @click="
        changeonover(3, onover == 3);
        $parent.show = true;
        $parent.poptype = 20;
      "
    >
      <!-- 乡村服务 -->一群人
      <!-- <div :class="[onover[3] == true && 'on', 'zcatebox']" @click.stop="">
        <div class="zcate" @click.stop="handle_push('shangchao')">
          <div
            :class="[index.includes('shangchao') && 'on', 'zcate_check']"
          ></div>
          <div class="zcate_name">商店超市</div>
        </div>
        <div class="zcate" @click.stop="handle_push('canyin')">
          <div :class="[index.includes('canyin') && 'on', 'zcate_check']"></div>
          <div class="zcate_name">餐饮美食</div>
        </div>
        <div class="zcate" @click.stop="handle_push('xuexiao')">
          <div
            :class="[index.includes('xuexiao') && 'on', 'zcate_check']"
          ></div>
          <div class="zcate_name">学校</div>
        </div>
        <div class="zcate" @click.stop="handle_push('kuaidi')">
          <div :class="[index.includes('kuaidi') && 'on', 'zcate_check']"></div>
          <div class="zcate_name">快递驿站</div>
        </div>
        <div class="zcate" @click.stop="handle_push('wenhua')">
          <div :class="[index.includes('wenhua') && 'on', 'zcate_check']"></div>
          <div class="zcate_name">文化</div>
        </div>
        <div class="zcate" @click.stop="handle_push('wushui')">
          <div :class="[index.includes('wushui') && 'on', 'zcate_check']"></div>
          <div class="zcate_name">污水处理</div>
        </div>
        <div class="zcate" @click.stop="handle_push('yanglao')">
          <div
            :class="[index.includes('yanglao') && 'on', 'zcate_check']"
          ></div>
          <div class="zcate_name">养老服务</div>
        </div>
        <div class="zcate" @click.stop="handle_push('caitan')">
          <div :class="[index.includes('caitan') && 'on', 'zcate_check']"></div>
          <div class="zcate_name">菜摊</div>
        </div>
        <div class="zcate" @click.stop="handle_push('yiliao')">
          <div :class="[index.includes('yiliao') && 'on', 'zcate_check']"></div>
          <div class="zcate_name">医疗</div>
        </div>
        <div class="zcate" @click.stop="handle_push('nongzi')">
          <div :class="[index.includes('nongzi') && 'on', 'zcate_check']"></div>
          <div class="zcate_name">农资</div>
        </div>
        <div class="zcate" @click.stop="handle_push('dianshang')">
          <div
            :class="[index.includes('dianshang') && 'on', 'zcate_check']"
          ></div>
          <div class="zcate_name">电商</div>
        </div>
      </div> -->
    </div>
    <div
      :class="[onover == 4 && 'on', 'zbtnx']"
      @click="
        $parent.poptype = 0;
        changeonover(4, onover == 4);
      "
    >
      <!-- 乡村经营 -->一个梦
      <div :class="['zcatebox']" v-if="onover == 4" @click.stop="">
        <!-- <div class="zcate" @click.stop="handle_push('dsh')">
          <div :class="[index.includes('dsh') && 'on', 'zcate_check']"></div>
          <div class="zcate_name">电商户</div>
        </div> -->
        <div
          class="zcate"
          :class="index.includes('cyxw') && 'on'"
          @click.stop="
            $parent.poptype = false;
            $parent.show = false;
            handle_push('cyxw');
          "
        >
          <div style="position:relative" :class="[index.includes('cyxw') && 'on', 'zcate_check']">
            <div class="zcate_name">产业兴旺</div>
            <small-btn2></small-btn2>
          </div>
        </div>
        <div
          class="zcate"
          :class="index.includes('styj') && 'on'"
          @click.stop="
            $parent.poptype = false;
            $parent.show = false;
            handle_push('styj');
          "
        >
          <div style="position:relative" :class="[index.includes('styj') && 'on', 'zcate_check']">
            <div class="zcate_name">生态宜居</div>
            <small-btn></small-btn>
          </div>
        </div>
        <div
          class="zcate"
          :class="index.includes('xfwm') && 'on'"
          @click.stop="
            $parent.show = true;
            $parent.poptype = 16;
            handle_push('xfwm');
          "
        >
          <div :class="[index.includes('xfwm') && 'on', 'zcate_check']">
            <div class="zcate_name">乡风文明</div>
          </div>
        </div>
        <div
          class="zcate"
          :class="index.includes('shfy') && 'on'"
          @click.stop="
            $parent.show = true;
            $parent.poptype = 18;
            handle_push('shfy');
          "
        >
          <div :class="[index.includes('shfy') && 'on', 'zcate_check']">
            <div class="zcate_name">生活富裕</div>
          </div>
        </div>
        <div
          class="zcate"
          :class="index.includes('zlyx') && 'on'"
          @click.stop="
            $parent.show = true;
            $parent.poptype = 17;
            handle_push('zlyx');
          "
        >
          <div :class="[index.includes('zlyx') && 'on', 'zcate_check']">
            <div class="zcate_name">治理有效</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Event from "../../utils/event";
import { get_type, getWarming, suishoupai } from "../../api/index";
//beijingtupian
import yimianqi from "../../assets/yimianqi2.png";
// import yimianqi from "../../assets/yimianqi.png";
import yimiantu from "../../assets/bj111.png";
import yigemeng from "../../assets/ygm.png";
import yizhangwang from "../../assets/yzw.png";



import coor from "../../assets/dyhh.png";
import di from "../../assets/dbz.png";
import bian from "../../assets/byz.png";
import can from "../../assets/cjrqz.png";
import jiankong from "../../assets/jkz.png";
import jiance from "../../assets/lv1.png";

import shangchao from "../../assets/sdcs.png";
import kuaidi from "../../assets/kdyz.png";
import yiliao from "../../assets/yl.png";
import yanglao from "../../assets/ylfw.png";
import xuexiao from "../../assets/xx.png";
import wenhua from "../../assets/wh.png";
import wushui from "../../assets/wscl.png";
import canyin from "../../assets/mscy.png";
import nongzi from "../../assets/nz.png";
import dianshang from "../../assets/dsgw.png";
import caitan from "../../assets/ct.png";
import dsh from "../../assets/dshz.png";
import gaizao from "../../assets/gjz.png";
import qixiang from "../../assets/szz.png";
import shuiwei from "../../assets/swz.png";
import yujing from "../../assets/yujing.png";
import pai from "../../assets/yujing.png";
import cgb from "../../assets/cgb.png";

import dyds from "../../assets/dyds.png";

import fuwudata from "../../data.json";

import smallBtn from '../../components/pop15.vue'
import smallBtn2 from '../../components/btnpop15.vue'

export default {
  props: ["jc", "jk", "addrx", "zz", "sw", "circle"],
  components:{
    smallBtn,smallBtn2
  },
  data() {
    return {
      Event,
      onover: false,
      num: 0,
      index: [
        /* "yujing", "pai" */
      ],
      img: {
        coor,
        di,
        bian,
        can,
        jiankong,
        jiance,
        shangchao,
        kuaidi,
        yiliao,
        yanglao,
        xuexiao,
        wenhua,
        wushui,
        canyin,
        nongzi,
        dianshang,
        caitan,
        dsh,
        gaizao,
        qixiang,
        shuiwei,
        yujing,
        pai,
        dyds,
        cgb,
      },
      data: {},
    };
  },
  mounted() {
    if(this.$route.query.type){
      if(this.$route.query.type==2){
        this.$parent.show=true;
        this.$parent.poptype = 20;
      }
      if(this.$route.query.type==4){
        this.$parent.poptype = 0;
      }
      if(this.$route.query.type==0){
        this.$parent.show=false;
        this.$parent.poptype = 0;
      }
      this.onover = this.$route.query.type||false;
      this.changeonover(Number(this.$route.query.type),false)
    }
  },
  async created() {
    
    Event.$on("changeSmy", (v) => {
      this.handle_push(v, true);
    });
    console.time();
    let { result } = await get_type();
    let bbb = await suishoupai();

    let aaa = await getWarming();
    let coorCircle = this.circle;
    //置顶
    this.data = result;

    this.data.coor = coorCircle.filter((el) => {
      return Number(el.is_party_member) == 2;
    });
    this.data.yujing = aaa.result;
    this.data.jiankong = this.jk;
    this.data.qixiang = this.jc;
    this.data.gaizao = this.zz;
    this.data.shuiwei = this.sw || [];
    console.log(this.sw, 888888);
    bbb.result = bbb.result.map((el) => {
      el.name = el.maincontent;
      return el;
    });
    this.data.shuiwei = this.data.shuiwei.filter((el) =>
      aaa.result.every((item) => item.id != el.id)
    );
    this.data.pai = bbb.result;
    console.log(this.data.shuiwei);
    fuwudata.data.forEach((el) => {
      if (this.data[el.typename]) {
        this.data[el.typename].push({
          name: el.name,
          lon: el.position[0],
          lat: el.position[1],
        });
      } else {
        this.data[el.typename] = [
          {
            name: el.name,
            lon: el.position[0],
            lat: el.position[1],
          },
        ];
      }
    });
    this.data.dsh = this.addrx.filter((el) => {
      return el.lon;
    });
    this.handle_click();
  },
  watch: {
    index() {
      this.handle_click();
    },
    jc() {
      this.data.jiance = this.jc;
      this.handle_click();
    },
    jk() {
      this.data.jiankong = this.jk;
      this.handle_click();
    },
    addr() {
      console.log("addr");
      fuwudata.data.forEach((el) => {
        if (this.data[el.typename]) {
          this.data[el.typename].push({
            name: el.name,
            lon: el.position[0],
            lat: el.position[1],
          });
        } else {
          this.data[el.typename] = [
            {
              name: el.name,
              lon: el.position[0],
              lat: el.position[1],
            },
          ];
        }
      });
    },
  },
  methods: {
    changeonover(v, b) {
      this.index = [];
      document.querySelector('.mainbg').style.backgroundImage=`url(${yimiantu})`
      if(v===0 && !b){
        console.log(document.querySelector('.mainbg'),'qqqqqqqqqqqqqqqqqqqq')
        document.querySelector('.mainbg').style.backgroundImage=`url(${yimianqi})`
      }
      if(v==2 && !b){
        console.log(document.querySelector('.mainbg'),'qqqqqqqqqqqqqqqqqqqq')
        document.querySelector('.mainbg').style.backgroundImage=`url(${yizhangwang})`
      }
      if(v==4 && !b){
        console.log(document.querySelector('.mainbg'),'qqqqqqqqqqqqqqqqqqqq')
        document.querySelector('.mainbg').style.backgroundImage=`url(${yigemeng})`
      }
      if (v == 3) {
        this.onover = false;
        document.querySelector(".abc").style.display = `none`;
        return;
      }
      if (v == 2 && b) {
        console.log("xxxxxxxxxxxxxxxx", this.$parent.poptype);
        this.$parent.poptype = 0;
      }
      if (b) {
        this.onover = !b;
        document.querySelector(".abc").style.display = `none`;
      } else {
        this.onover = v;
        console.log(this.onover,'oooooooooooooooooooooooo')
        document.querySelector(".abc").style.display = `block`;
        document.querySelector(".abc").style.left = `calc(${v} * 20% )`;
      }

      console.log(`clac(${v} * 20% )`);

      this.$forceUpdate();
    },
    handle_push(str, isb) {
      console.log(str);

      if (
        ["cyxw", "styj", "xfwm", "shfy", "zlyx", "dyfs",'coor','dsfz'].includes(str) &&
        !isb
      ) {
        if (this.index.includes(str)) {
          this.index.splice(this.index.indexOf(str), 1);
          this.$parent.poptype = false;
          if (str == "styj" || str == "cyxw" || str=="dsfz") {
            Event.$emit("showSymbolBtn", false,str);
          }
        } else {
          if (str == "styj"||str == "cyxw" || str=="dsfz") {
            Event.$emit("showSymbolBtn", true,str);
          } else {
            Event.$emit("showSymbolBtn", false,'');
          }
          this.index = [str];
        }
      } else {
        if (this.index.includes(str)) {
          this.index.splice(this.index.indexOf(str), 1);
          this.$parent.show = false;
        } else {
          this.index.push(str);
        }
        console.log(this.index, 22222);
      }

      console.log(this.onover[0], 8888888888);
      if (this.index.includes("coor")) {
        this.$parent.show=false;
        this.$parent.poptype=0;
      } 
    },
    handle_click() {
      console.log(this.index, 7777);
      this.$store.commit(
        "SET_LIST",
        this.index
          .map((index) => {
            console.log(this.data, 22222);

            return (this.data[index] || [])
              .map((item) => {
                console.log(index, 1111111111111111);
                if (item.lon) {
                  return {
                    img:
                      item.is_party_member == "2" && item.is_commerce == "2"
                        ? this.img["dyds"]
                        : this.img[index],
                    position: [item.lon, item.lat],
                    type:
                      item.is_party_member == "2" && item.is_commerce == "2"
                        ? "coorDS"
                        : index,
                    name: item.name,
                    info: item,
                  };
                } else {
                  return null;
                }
              })
              .filter((item) => item);
          })
          .flat(1)
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.zbtnbox {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: space-around;
  .abc {
    content: "";
    width: 20%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0%;
    display: none;
    transition: left 400ms ease;
    background: url(../../assets/btnbyc.png) no-repeat bottom center/100%
      height(45);
  }
  .zbtnx {
    flex: 1;
    z-index: 1000;
    opacity: 1;
    text-align: center;
    font-size: 0.2rem;
    line-height: height(70);
    color: #106fd1;
    cursor: pointer;
    &.on {
      color: #fff;
    }
    .zcatebox {
      position: absolute;
      // padding: height(15) 0.15rem;
      width: 100%;
      height: height(34);
      // background: rgba(0, 16, 37, 0.6);
      border-radius: 5px;
      bottom: height(40);
      top: height(-64);
      right: 0;
      display: flex;
      justify-content: flex-end;
      padding: 0 0.2rem;
      box-sizing: border-box;
      &.on {
        display: block;
      }
      .zcate {
        cursor: pointer;
        height: height(16);
        width: 100%;
        padding: 0.02rem;
        box-sizing: content-box;
        display: flex;
        width: 1.24rem;
        height: height(34);
        border: 1px solid #142b5f;
        border-radius: 0.06rem;
        color: #fff;
        margin-left: 0.54rem;
        opacity: 0.6;
        &.on {
          opacity: 1;
        }
        &:last-child {
          /* padding-top: 0; */
        }
        .zcate_check {
          // width: 16px;
          // height: 16px;
          // border: 1px solid #aaaaaa;
          background: rgba(20, 43, 95, 1);
          width: 100%;
          height: 100%;
          border-radius: 0.05rem;
          text-align: center;
          // &.on {
          //   background: url(../../assets/ticket.png) no-repeat center/100% 100%;
          // }
        }
        .zcate_name {
          flex: 1;
          font-size: 0.14rem;
          color: #fff;
          line-height: height(34);
          text-align: center;
        }
      }
    }
  }
}
</style>