<template>
  <div class="modal">
    <div :class="[obj.videoUrl==null&&'nonex','model']">
      <div class="close" @click="$parent.show=0,onchecked_3={}"></div>
      <!-- <video class="con"></video> -->
      <div class="gaibox">
        <div class="videoboxgai" v-if="obj.videoUrl">
          <div class="gaititle">视频展示</div>
          <div>
            <video src="" class="vgai">
            </video>
          </div>
        </div>
        <div class="infoboxgai">
          <div class="basicinfo">
            <div class="gaititle">基本信息</div>
            <div class="fontxxx">
              {{obj.desc}}
            </div>
          </div>
          <div class="picbox">
            <div class="gaititle">照片展示</div>
            <div class="con1">
              <img :src="urlx+item" v-for="(item,index) in obj.imglist"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props:['obj'],
  mounted() {
    this.player = flvjs.createPlayer({
            type: "flv",
            isLive: true,
            url: this.obj.videoUrl
        });
        this.player.attachMediaElement(document.querySelector(".vgai"));
        try {
            this.player.load();
            this.player.play();
        } catch (error) {
            /* console.log(error); */
        };
  },
}
</script>
<style lang="scss" scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.9);
  z-index: 1000;
  .model {
    width: 14.3rem;
    height: height(900);
    background: #00082b;
    border: 2px solid #0b3ba0;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    z-index: 1000;
    padding: 0 0.63rem;
    padding-top: height(60);
    &.nonex{
      width: 9.3rem;
    }
  }
  .title {
    line-height: height(88);
    font-size: 0.26rem;
    color: #fff;
    text-align: center;
  }

  .con {
    width: 13rem;
    height: height(730);
    opacity: 1;
    background: #000;
  }

  .close {
    width: 0.32rem;
    height: 0.32rem;
    background: url(../../../assets/gb6.png);
    background-size: 100% 100%;
    position: absolute;
    bottom: -0.52rem;
    left: 50%;
    transform: translateX(-0.16rem);
    cursor: pointer;
  }
}
.gaibox{
  display: flex;
  width: 100%;
  height: 100%;
}
.videoboxgai{
  margin-right: 0.7rem;
}
.gaititle{
  font-size: 0.28rem;
  color: #fff;
  font-weight: 700;
  line-height: 1;
}
.vgai{
  margin-top: height(27);
  width: 5rem;
  height: height(703);
  background:#000 url(../../../assets/loading.gif) no-repeat center center /auto auto;
}
.fontxxx{
  font-size:0.2rem ;
  color: #fff;
  height: height(145);
  line-height: 1.5;
  margin-top: height(27);
}
.con1 {
  margin-top: height(20);
  width: 100%;
  height: height(450);
  overflow: auto;
    img{
      width: 100%;
    }
  }
  .picbox{
    margin-top: height(60);
  }
</style>