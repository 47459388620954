<template>
  <div class="left_bottom">
    <div class="title">白牛村环境监测</div>
    <v-switch :switchflag.sync='switchxx'></v-switch>
    <div class="box">
      <div class="name">水质</div>
      <div class="list">
        <div>水位：--</div>
        <div>水质：--</div>
        <div>PH值：--</div>
      </div>
      <div class="name">气象</div>
      <div class="list">
        <div>温度：--</div>
        <div>PM2.5：--</div>
        <div>湿度：--</div>
        <div>雨量：--</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return{
      switchxx:false
    }
  }
}
</script>
<style lang="scss" scoped>
.left_bottom {
  width: 4.5rem;
  height: height(340);
  background-color: #0b132d;
  border-radius: 0.1rem;
  position: absolute;
  top: height(710);
  left: 0.2rem;
  z-index: 100;
}
.box {
  padding: 0 0.2rem;
  padding-top: height(20);
  .name {
    font-size: 0.2rem;
    color: #fff;
    line-height: height(40);
    border-bottom: 1px solid #fff;
    margin-top: height(20);
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    padding-top: height(10);
    div {
      font-size: 0.16rem;
      line-height: height(35);
      color: #fff;
      width: 33%;
    }
    div:nth-child(3n + 2) {
      text-align: center;
    }
    div:nth-child(3n + 3) {
      text-align: right;
    }
  }
}
</style>