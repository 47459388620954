<template>
  <div>
    <div class="midbox">
      <div class="btk1 btk2">白牛村历年营销额</div>
      <div class="salebox">
        <div class="itembox">
          <div>2018年（亿元）</div>
          <div>{{ midTopData.before_last }}</div>
        </div>
        <div class="itembox">
          <div>2019年（亿元）</div>
          <div>{{ midTopData.last_year }}</div>
        </div>
        <div class="itembox">
          <div>2020年（亿元）</div>
          <div>{{ midTopData.this_year }}</div>
        </div>
      </div>
    </div>

    <v-box :show.sync="show"></v-box>

    <!-- 左 -->
    <div class="table1">
      <div class="btk1">白牛村电商企业：83家</div>
      <v-switch :switchflag.sync="switchxx"></v-switch>
      <div class="table">
        <div class="thead">
          <div>店铺名称</div>
          <div>营收额</div>
          <div>评分</div>
        </div>
        <div class="tbody tt1">
          <div
            class="data"
            v-for="item in shopList"
            @click="
              $parent.$refs.map.flyTo(item.lat,item.lon,111)
            "
          >
            <div>{{ item.name }}</div>
            <div>{{ item.turnover }}</div>
            <div>{{ item.score }}</div>
          </div>
          <div
            class="data"
            v-for="item in shopList"
            @click="
              $parent.$refs.map.flyTo(item.lat,item.lon,111)
            "
          >
            <div>{{ item.name }}</div>
            <div>{{ item.turnover }}</div>
            <div>{{ item.score }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="table2">
      <div class="btk1">山核桃历年放心指数</div>
      <div class="echart2">
        <v-echart :option="leftMid"></v-echart>
      </div>
    </div>
    <div class="table3">
      <div class="btk1">山核桃历年产量统计</div>
      <!-- <div class="Echarts3">
           <v-echart :option="option3"></v-echart>
          </div> -->
      <div class="echart3">
        <v-echart :option="leftBottom"></v-echart>
      </div>
    </div>

    <!-- 右 -->

    <div class="table4">
      <div class="btk1">农产品销量占比（去年）</div>
      <div class="echart4">
        <v-echart :option="rightTop" :act="true"></v-echart>
      </div>
    </div>
    <div class="table5">
      <div class="btk1">白牛村农产品销量数据</div>
      <div class="table">
        <div class="thead">
          <div>产品名称</div>
          <div>销量</div>
          <div>营收额</div>
        </div>
        <div class="tbody tt2">
          <div class="data" v-for="item in goodsList">
            <div>{{ item.keywords_name }}</div>
            <div>{{ item.sum_sales }}件</div>
            <div>{{ item.sum_turnover }}</div>
          </div>
          <div class="data" v-for="item in goodsList">
            <div>{{ item.keywords_name }}</div>
            <div>{{ item.sum_sales }}件</div>
            <div>{{ item.sum_turnover }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="table6">
      <div class="btk1">产品均价分析</div>
      <div class="echart6">
        <v-echart :option="rightBottom" ></v-echart>
      </div>
    </div>
  </div>
</template>


<script>
var colorList = [
  "#914ce5",
  "#42b1cc",
  "#ff55ac",
  "#0090ff",
  "#06d3c4",
  "#ffbc32",
  "#2ccc44",
  "#ff3976",
  "#6173d6",
  "#914ce5",
  "#42b1cc",
  "#ff55ac",
  "#0090ff",
  "#06d3c4",
  "#ffbc32",
  "#2ccc44",
  "#ff3976",
  "#6173d6",
  "#914ce5",
  "#42b1cc",
  "#ff55ac",
  "#0090ff",
  "#06d3c4",
  "#ffbc32",
  "#2ccc44",
  "#ff3976",
  "#6173d6",
  "#914ce5",
  "#42b1cc",
  "#ff55ac",
  "#0090ff",
  "#06d3c4",
  "#ffbc32",
  "#2ccc44",
  "#ff3976",
  "#6173d6",
  "#914ce5",
  "#42b1cc",
  "#ff55ac",
  "#0090ff",
  "#06d3c4",
  "#ffbc32",
  "#2ccc44",
  "#ff3976",
  "#6173d6",
  "#914ce5",
  "#42b1cc",
  "#ff55ac",
  "#0090ff",
  "#06d3c4",
  "#ffbc32",
  "#2ccc44",
  "#ff3976",
  "#6173d6",
  "#914ce5",
  "#42b1cc",
  "#ff55ac",
  "#0090ff",
  "#06d3c4",
  "#ffbc32",
  "#2ccc44",
  "#ff3976",
  "#6173d6",
  "#914ce5",
  "#42b1cc",
  "#ff55ac",
  "#0090ff",
  "#06d3c4",
  "#ffbc32",
  "#2ccc44",
  "#ff3976",
  "#6173d6",
  "#914ce5",
  "#42b1cc",
  "#ff55ac",
  "#0090ff",
  "#06d3c4",
  "#ffbc32",
  "#2ccc44",
  "#ff3976",
  "#6173d6",
  "#914ce5",
  "#42b1cc",
  "#ff55ac",
];
import { Shop } from "../../api/index";
import vBox from "../index/box/index";
import emicon from "../../assets/ds1.png"
export default {
  name: "chart1",
  components: { vBox },
  data() {
    return {
      shopList: [],
      goodsList: [],
      leftMid: null,
      leftBottom: null,
      rightTop: null,
      rightBottom: null,
      midTopData: {},
      show: false,
      switchxx: false,
    };
  },
  created() {
    this.getInfo();
  },
  mounted() {},
  methods: {
    getInfo() {
      Shop({}).then((res) => {
        console.log(res);
        this.midTopData = res.result.year_data;
        this.leftMid = this.left_mid(res.result.index_number);
        this.leftBottom = this.left_bottom(res.result.output);
        this.rightTop = this.right_top(res.result.category);
        this.rightBottom = this.right_bottom(res.result.price_compare);
        res.result.top_shop.forEach((el) => {
          el.turnover = parseInt(el.turnover) / 10000 + "万元";
        });
        this.$store.commit(
          "SET_LIST",
          res.result.top_shop
            .map((item) => {
              if (item.lon) {
                return {
                  type: 13,
                  id: 2,
                  name: item.name,
                  img:emicon,
                  position: [item.lon,item.lat],
                };
              } else {
                return null;
              }
            })
            .filter((item) => item)
        );
        this.shopList = res.result.top_shop;
        res.result.sales_data.forEach((el) => {
          el.sum_turnover = parseInt(el.sum_turnover) / 10000 + "万元";
        });
        this.goodsList = res.result.sales_data;
        //css 滚动1
        if (res.result.top_shop.length > 8) {
          var css = document.createElement("style");
          css.type = "text/css";
          css.innerHTML = `@keyframes rowup {
            0% {
                transform: translate3d(0, 0, 0);
            }
            100% {
                transform: translate3d(0, -${
                  res.result.top_shop.length * 39
                }px, 0);
            }
        }

        .tt1>div{
          animation: ${
            2000 * res.result.top_shop.length
          }ms rowup linear infinite normal;
        }`;
          document.head.appendChild(css);
        }
        //css 滚动2
        if (res.result.sales_data.length > 4) {
          var css = document.createElement("style");
          css.type = "text/css";
          css.innerHTML = `@keyframes rowup {
            0% {
                transform: translate3d(0, 0, 0);
            }
            100% {
                transform: translate3d(0, -${
                  (res.result.sales_data.length - 4) * 39
                }px, 0);
            }
        }
        .tt2>div{
          animation: ${
            2000 * res.result.sales_data.length
          }ms rowup linear infinite normal;
        }`;
          document.head.appendChild(css);
        }
        /* this.categroy = res.result.category;
      for (var i = 0; i < res.result.index_number.length; i++) {
        this.fangxinnum.dataY.push(res.result.index_number[i].collecttime_text);
        this.fangxinnum.dataX.push(
          parseFloat(res.result.index_number[i].index_number)
        );
      }
      this.saleData = res.result.sales_data;
      this.topShop = res.result.top_shop;
      this.eUnit = res.result.price_compare;
      this.enutd = res.result.output;
      this.yeardata.this_year = res.result.year_data.this_year;
      this.yeardata.last_year = res.result.year_data.last_year;
      this.yeardata.before_last = res.result.year_data.before_last;
      this.yeardata.total_shop = res.result.year_data.total_shop;
      this.yeardata.total_employment = res.result.year_data.total_employment; */
      });
    },
    left_mid(arr) {
      // 指定图表的配置项和数据
      const ydata = [];
      const xdata = [];
      arr.forEach((el) => {
        ydata.push(el.collecttime_text);
        xdata.push(parseFloat(el.index_number));
      });
      let obj = {
        grid: {
          left: "13%",
          top: 10,
          bottom: 20,
        },
        xAxis: {
          type: "value",
          splitLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
        },
        yAxis: {
          type: "category",
          inverse: true,
          axisLabel: {
            show: true,
            textStyle: {
              color: "#fff",
            },
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          data: ydata,
        },
        series: [
          {
            z: 2,
            name: "value",
            type: "bar",
            data: xdata,
            barCategoryGap: "50%",
            itemStyle: {
              normal: {
                barBorderRadius: [30, 30, 30, 30],
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  {
                    offset: 0,
                    color: "#0055fd",
                  },
                  {
                    offset: 1,
                    color: "#00aaff",
                  },
                ]),
              },
            },
            label: {
              show: true,
              position: "right",
              color: "#fff",
              fontSize: 14,
              offset: [10, 0],
            },
          },
        ],
      };
      return obj;
    },
    left_bottom(arr) {
      arr = arr.reverse();
      const ydata = [];
      const xdata = [];
      arr.forEach((el) => {
        ydata.push(el.value);
        xdata.push(el.date);
      });
      let obj = {
        grid: {
          left: "13%",
          top: "15%",
          bottom: "15%",
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: false,
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#fff",
            },
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
        },
        xAxis: {
          type: "category",
          inverse: true,
          axisLabel: {
            show: true,
            textStyle: {
              color: "#fff",
            },
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: true,
          },
          axisLine: {
            show: true,
          },
          data: xdata,
        },
        series: [
          {
            z: 2,
            name: "value",
            type: "bar",
            data: ydata,
            barCategoryGap: 60,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  {
                    offset: 0,
                    color: "#0055fd",
                  },
                  {
                    offset: 1,
                    color: "#00aaff",
                  },
                ]),
              },
            },
            label: {
              show: true,
              position: "top",
              formatter: function (param) {
                return param.value / 10000 + "万吨";
              },
              color: "#fff",
              fontSize: 12,
            },
          },
        ],
      };
      return obj;
    },
    right_top(arr) {
      /*  var sum = 0; */
      var legendData = [];
      arr.forEach((el) => {
        /*  sum = el.value + sum; */
        legendData.push(el.name);
      });
      /*  arr.forEach((el) => {
        el.scale = (el.value / sum).toFixed(1) * 100;
      }); */
      console.log(arr);
      var obj = {
        legend: {
          show: true,
          bottom: 10,
          itemGap: 20,
          z: 2,
          icon: "circle",
          textStyle: {
            color: "#fff",
          },
          data: legendData,
        },
        series: [
          {
            name: "",
            type: "pie",
            radius: ["40%", "55%"],
            top: "5%",
            itemStyle: {
              normal: {
                color: function (params) {
                  return colorList[params.dataIndex];
                },
              },
            },
            label: {
              normal: {
                position: "outside",
                padding: [0, -50, 25, -50],
                formatter: function (params) {
                  if (params.data.scale != 0)
                    return (
                      "{a|" +
                      params.data.name +
                      "}" +
                      "{b|" +
                      params.data.scale +
                      "%}"
                    );
                  else
                    return (
                      "{a|" +
                      params.data.name +
                      "}" +
                      "{b|" +
                      params.data.value +
                      "%}"
                    );
                },
                show: false,
                rich: {
                  a: {
                    color: "#fff",
                    padding: [0, 10, 0, 4],
                    fontSize: 12,
                  },
                  b: {
                    color: "#fff",
                    fontSize: 16,
                    fontWeight: "bold",
                  },
                },
              },
            },
            emphasis: {
              label: {
                show: true,
              },
              labelLine: {
                show: true,
                length: 0,
                length2: 70,
              },
            },
            labelLine: {
              show: false,
              length: 0,
              length2: 70,
            },
            data: arr,
          },
        ],
      };
      return obj;
    },
    right_bottom(arr) {
      let datalegend = [];
      let dataX = [];
      let dataSeries = [];
      for (var i = 0; i < arr.length; i++) {
        const ssdata = [];
        datalegend.push(arr[i][0].name);
        for (var j = 0; j < arr[i].length; j++) {
          ssdata.push(parseInt(arr[i][j].value));
        }
        const ssobj = {
          name: arr[i][0].name,
          type: "bar",
          itemStyle: {
            color: colorList[i],
          },
          data: ssdata,
        };
        dataSeries.push(ssobj);
      }
      for (var i = 0; i < arr[0].length; i++) {
        dataX.push(parseInt(arr[0][i].date));
      }
      let obj = {
        grid: {
          borderWidth: 0,
          top: 50,
          bottom: 20,
          textStyle: {
            color: "#fff",
          },
        },
        legend: {
          x: "10%",
          top: "5%",
          textStyle: {
            color: "#90979c",
          },
          data: datalegend,
        },
        calculable: true,
        xAxis: [
          {
            type: "category",
            axisLine: {
              lineStyle: {
                color: "rgba(204,187,225,0.5)",
              },
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            data: dataX,
          },
        ],

        yAxis: [
          {
            type: "value",
            splitLine: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                color: "rgba(204,187,225,0.5)",
              },
            },
          },
        ],
        series: dataSeries,
      };
      return obj;
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}
html {
  font-size: calc(100vw / 1920 * 100);
  /* 换算比例：除以100 */
}
.f-l {
  float: left;
}
.f-r {
  float: right;
}
.Echarts3 {
  margin-top: height(20);
  padding: 0 0.17rem height(12);
  height: height(196);
}
#table3 {
  width: 4.16rem;
  height: height(196);
}

.line {
  width: 5.56rem;
  height: height(2);
  margin: auto;
  background-image: linear-gradient(0deg, #0dccff 0%, #4760ff 100%);
  margin-bottom: height(22);
}
.table1 .table {
  margin-top: height(40);
}
.table1,
.table2,
.table3,
.table4,
.table5,
.table6,
.table7 {
  width: 4.5rem;
  height: height(450);
  background-color: #0b132d;
  border-radius: 0.1rem;
  overflow: hidden;
  position: absolute;
}
.table1 {
  top: height(110);
  left: 0.21rem;
}
.table2 {
  height: height(200);
  top: height(570);
  left: 0.21rem;
}
.table3 {
  height: height(269);
  top: height(781);
  left: 0.21rem;
}
.table4 {
  height: height(350);
  top: height(110);
  right: 0.2rem;
}
.table5 {
  height: height(280);
  top: height(470);
  right: 0.2rem;
}
.table5 > .btk1 {
  margin-bottom: height(19);
}
.table5 > .table {
  height: height(195);
}
.table6 {
  height: height(290);
  top: height(760);
  right: 0.2rem;
}
.btk1 {
  width: 4.5rem;
  height: height(40);
  background: url(../../assets/btk.png) no-repeat;
  background-size: 100%;
  margin: auto;
  color: #fff;
  font-size: 0.2rem;
  line-height: height(40);
  text-align: center;
  &.btk2 {
    background: url(../../assets/xxxx.png) no-repeat center/auto 0.4rem;
  }
}
.table {
  width: 4.4rem;
  height: height(351);
  overflow: hidden;
  box-sizing: border-box;
  margin: auto;
}
.table > .thead {
  height: height(39);
  background-color: #060d22;
  display: flex;
}
.table > .thead > div {
  font-size: 0.14rem;
  color: #549cff;
  line-height: height(38);
  text-align: center;
  border: solid 0.01rem #1e2953;
  flex-shrink: 0;
}
.table > .thead > div:nth-of-type(1),
.table > .tbody > .data > div:nth-of-type(1) {
  width: 2.16rem;
}
.table > .thead > div:nth-of-type(2),
.table > .tbody > .data > div:nth-of-type(2) {
  width: 1.09rem;
  border-left-width: 0;
  border-right-width: 0;
}
.table > .thead > div:nth-of-type(3),
.table > .tbody > .data > div:nth-of-type(3) {
  width: 1.1rem;
}
.table > .tbody {
  background-color: #0b132d;
  overflow-y: auto;
  height: height(312);
}
::-webkit-scrollbar {
  display: none;
}
.table > .tbody > .data {
  display: flex;
  height: height(39);
}
.table > .tbody > .data > div {
  font-size: 0.14rem;
  color: #fff;
  line-height: height(38);
  text-align: center;
  border: solid 0.01rem #1e2953;
  border-top-width: 0;
  flex-shrink: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.btnList {
  width: 7rem;
  height: height(81);
  background-color: #0b132d;
  border-radius: 0.1rem;
  margin: 0 auto height(29);
  padding: height(10) 0.1rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.btnList > .btn {
  width: 1.6rem;
  height: height(60);
  font-size: 0.18rem;
  line-height: height(60);
  color: #ffffff;
  background-color: #0e1b46;
  border-radius: 0.1rem;
  text-align: center;
  box-sizing: border-box;
}
.echart2 {
  height: height(160);
}
.echart3 {
  height: height(229);
}
.echart4 {
  height: height(310);
}
.echart6 {
  height: height(250);
}
.midbox {
  width: 5.7rem;
  height: height(130);
  position: absolute;
  top: height(110);
  left: 50%;
  transform: translateX(-50%);
  background-color: #0b132d;
  border-radius: 0.1rem;
}
.salebox {
  margin-top: height(13);
  display: flex;
  width: 100%;
  justify-content: space-around;
  color: #fff;
}
.itembox {
  display: flex;
  flex: 1;
  height: height(60);
  padding: height(4) 0;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  &:nth-child(1) {
    background: url(../../assets/qqqq.png) no-repeat right/auto 0.6rem;
  }
  div:nth-child(1) {
    font-size: 0.14rem;
  }
  div:nth-child(2) {
    font-size: 0.28rem;
    font-weight: bold;
  }
}
</style>
