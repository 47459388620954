<template>
  <div ref="china" :option="option" class="china"></div>
</template>
<script>
import option from "./option";
export default {
  data() {
    return {
      option: null,
      myChart: null,
    };
  },
  watch: {
    option() {
      this.myChart.setOption(this.option);
      this.myChart.dispatchAction({
        type: "showTip",
        seriesIndex: 0, // 显示第几个series
        dataIndex: 0, // 显示第几个数据
        position: "top",
      });
    },
  },
  mounted() {
    this.myChart = echarts.init(this.$refs.china);
    this.option = option;
    this.myChart.on('click',(params)=>{
      console.log(params,1231234231313)
      /* showTag(2,params.data) */
    })
    window.addEventListener("resize", () => {
      console.log("窗口改变");
      this.myChart.resize();
    });
  },
};
</script>
<style lang="scss" scoped>
.china {
  width: 100%;
  height: 100%;
}
</style>