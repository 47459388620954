<template>
  <div>
    <v-pop2 :info="info" v-if="info.type == 'di' || info.type == 'bian' || info.type == 'can'" />
    <v-pop3 :info="info" v-if="info.type == 'shuiwei'||(info.type =='yujing' && info.mold!=11) " />
    <v-pop4 :info="info" v-if="info.type == 'qixiang'" />
    <v-pop5 :info="info" v-if="info.type == 'dsh'" />
    <v-pop6 :info="info" v-if="info.type == 'gaizao'" />
    <v-pop7 :info="info" v-if="info.type == 'jiankong'" />
    <v-pop14 :info="info" v-if="info.type == 'coor' || info.type == 'coorDS'" />
    <v-pop9 :info="info" v-if="info.type == 'pai'" />
    <v-pop10 :info="info" v-if="info.type == 'yujing'&&info.mold==11" />
    <!-- <v-pop11></v-pop11> -->
  </div>
</template>
<script >
import vPop2 from "./pop2"
import vPop3 from "./pop3"
import vPop4 from "./pop4"
import vPop5 from "./pop5"
import vPop6 from "./pop6"
import vPop7 from "./pop7"
import vPop8 from "./pop8"
import vPop9 from "./pop9"
import vPop10 from "./pop10"
import vPop11 from "./pop11"
import vPop14 from "./pop14"
export default {
    props:["info"],
    components:{
        vPop2,
        vPop3,
        vPop4,
        vPop5,
        vPop6,
        vPop7,
        vPop8,
        vPop9,
        vPop10,
        vPop11,
        vPop14
    },
    created() {
      console.log(this.info)
    },
};
</script>
<style lang="scss">
</style>