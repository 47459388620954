<template>
  <div class="right_center">
    <div class="title">政务·最多跑一次</div>
    <div class="box">
        <div class="li">
        <div class="t1">在办事件</div>
        <div class="t2">{{rightCenter.zaiban}}</div>
      </div>
      <div class="li">
        <div class="t1">已办事件</div>
        <div class="t2">{{rightCenter.yiban}}</div>
      </div>
      <div class="li">
        <div class="t1">办结率</div>
        <!-- <div class="t2">{{rightCenter.zaiban}}</div> -->
        <div class="t2">100%</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props:['rightCenter']
}
</script>
<style lang="scss" scoped>
.right_center {
  width: 4.5rem;
  height: height(140);
  background-color: #0b132d;
  border-radius: 0.1rem;
  position: absolute;
  top: height(320);
  right: 0.2rem;
  z-index: 100;
}
.title {
  margin-bottom: height(24);
}
.box {
  display: flex;
  padding: 0 0.15rem;
}
.li {
  flex: 1;
  height: height(50);
  text-align: center;
  border-radius: 0.04rem;
  background-color: #091a51;
  margin: 0 0.05rem;
  margin-bottom: height(10);
  padding-top: height(7);
    .t1{
    font-size:0.12rem;
    line-height: height(12);
    color: #fff;
  }
  .t2{
    font-size:0.18rem;
    line-height: height(18);
    color: #42eb84;
    margin-top: height(10);
  }
}
</style>