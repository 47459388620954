<template>
  <div class="box" v-if="show">
    <div class="h1">地块信息</div>
    <div class="close" @click="handle_close"></div>
    <div class="list">
      <div class="li">地块名称：白牛村3000平米农家庭院转让</div>
      <div class="li">地块名称：白牛村3000平米农家庭院转让</div>
      <div class="li">地块名称：白牛村3000平米农家庭院转让</div>
      <div class="li">地块名称：白牛村3000平米农家庭院转让</div>
      <div class="li">地块名称：白牛村3000平米农家庭院转让</div>
      <div class="li">地块名称：白牛村3000平米农家庭院转让</div>
      <div class="li">地块名称：白牛村3000平米农家庭院转让</div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["show"],
  methods:{
      handle_close(){
          this.$emit("update:show",false)
      }
  }
};
</script>
<style lang="scss" scoped>
.box {
  width: 4rem;
  background-color: rgba(5, 11, 44, 0.8);
  box-shadow: 0px 0px 0.2rem 0px rgba(0, 0, 0, 0.8);
  border-radius: 0.2rem;
  position: fixed;
  z-index: 100;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: height(10) 0.3rem;
  .list{
  max-height: height(400);
  overflow: auto;
  }
  .h1 {
    font-size: 0.2rem;
    line-height: height(40);
    color: #fff;
    text-align: center;
    border-bottom: 1px solid #fff;
  }
  .li {
    font-size: 0.14rem;
    color: #fff;
    line-height: height(48);
    @include ws;
  }
  .close {
    width: 0.3rem;
    height: 0.3rem;
    background:url(../../../assets/gb.png);
    background-size: 100% 100%;
    position: absolute;
    top: -0.15rem;
    right: -0.15rem;
  }
}
</style>