<template>
  <div class="fixed_modal">
    <div class="pop">
      <div class="title">设备信息</div>
      <div class="tag">名称：{{info.name}}</div>
      <div class="tag">水深：{{info.info.level}}m</div>
      <div :class="[info.status=='工作中'&&'bai','tag warming']">{{info.name}}{{info.status}}</div>
      <div class="close_btn" @click="$parent.$parent.pop_info=null"></div>
    </div>
  </div>
</template>
<script >
export default {
  props:['info'],
  data() {
    return {
      option: {},
    };
  },
  components: {},
};
</script>
<style lang="scss" scoped>
.fixed_modal {
  width: 2.4rem;
  height: height(200);
  border: solid 1px #142b5f;
  box-sizing: content-box;
  border-radius: 0.1rem;
  padding: 0.05rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 10000;
  .pop {
    width: 100%;
    height: 100%;
    background: #001025;
    border-radius: 0.1rem;
    padding: 0 0.3rem;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.8),
      inset 0px 0px 30px 0px rgba(44, 87, 228, 0.5);
    .close_btn {
      width: 0.14rem;
      height: 0.14rem;
      background:url(../assets/close.png) no-repeat center center /auto 0.14rem;
      position: absolute;
      top: height(15);
      right: 0.15rem;
    }
    .tag {
      text-align: left;
      font-size: 0.14rem;
      line-height: height(36);
      color: #fff;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-left: 0.1rem;
      &.warming{
        background: url(../assets/warm.png)no-repeat left 0.1rem center/ 0.16rem auto;
        padding-left: 0.36rem;
        color: red;
      }
      &.bai{
          background:none;
          padding-left: 0.1rem;
          color: #fff;
      }
    }
    .title {
      font-size: 0.18rem;
      line-height: height(38);
      text-align: center;
      color: #fff;
      border-bottom: 1px solid rgba(255,255,255,0.3);
      margin-bottom: height(10);
      padding-top: height(10);

    }
  }
}
</style>