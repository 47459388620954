<template>
  <div ref="china" :option="option" class="china"></div>
</template>
<script>
import option from "./option";
export default {
  data() {
    return {
      option:null,
      myChart: null,
    };
  },
  watch: {
    option() {
      this.myChart.setOption(this.option);
    },
  },
  mounted() {
    this.option=option
    this.myChart = echarts.init(this.$refs.china);
    this.myChart.on('click',(params)=>{
    console.log(params,415645646)
    /*  window.showTag(2,JSON.) */
    if(params.componentSubType=="effectScatter"){
      showTag(2,params.data)
    }   
    })
    /* window.addEventListener("resize",()=>{
        console.log("窗口改变")
        this.myChart.resize();
    }) */
  },
  methods: {
   
  },
};
</script>
<style lang="scss" scoped>
.china{
    width: 100%;
    height: 100%;
}
</style>