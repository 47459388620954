<template>
  <div class="bg-box">
    <div class="form-box">
      <div
        class="delBtn zhuanquan"
        @click="
          $parent.$parent.show = false;
          stopplay();
        "
      ></div>
      <div class="f-title">白牛概述介绍</div>
      <div class="c-title mb-21">白牛历史介绍及宣传视频</div>
      <div class="mb-21">
        <div class="flex videohead">
          <div :class="[videotype==1&&'on','v_title']" @click="videotype=1">白牛视频宣传介绍</div>
          <div :class="[videotype==2&&'on','v_title']" @click="videotype=2">山核桃宣传视频</div>
        </div>
        <div class="video-box">
          <video
            id="myvideo"
            src="http://127.0.0.1/mp4/bainiu.mp4"
            controls
            autoplay
            loop
            v-if="videotype==1"
          ></video>
           <video
            id="myvideo"
            src="http://127.0.0.1/mp4/shanhetao.mp4"
            controls
            autoplay
            loop
            v-if="videotype==2"
          ></video>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["party", "partysort", "scoresort", "echartdata", "desc"],
  data() {
    return {
      cunoption: null,
      videotype:1
    };
  },
  mounted() {
   
  },
  watch: {
    
  },
  methods: {
    /* stopplay(){
      let myVideo = document.getElementById('myvideo');
      myVideo.load();
      myVideo.pause();
    }, */
    setcunoption() {
      var xData = ["A", "B", "C", "D", "E", "F", "G"];
      var lastYearData = [3, 20, 62, 34, 55, 65, 33];
      var thisYearData = [11, 38, 23, 39, 66, 66, 79];
      let legend = ["青年返乡", "就业人数"];
      var background = "#0e2147"; //背景
      let textColor = "#fff";
      let lineColor = "rgba(255,255,255,0.2)";
      let colors = [
        {
          borderColor: "rgba(227,161,96,1)",
          start: "rgba(227,161,96,0.8)",
          end: "rgba(227,161,96,0.3)",
        },
        {
          borderColor: "rgba(0,222,255,1)",
          start: "rgba(0,222,255,0.3)",
          end: "rgba(0,222,255,0.8)",
        },
      ];
      let borderData = [];
      let scale = 2;
      borderData = this.echartdata.arrx.map((item) => {
        return scale;
      });
      this.cunoption = {
        baseOption: {
          timeline: {
            show: false,
            top: 0,
            data: [],
          },
          legend: {
            top: "5%",
            right: "5%",
            itemWidth: 10,
            itemHeight: 10,
            // itemGap: 343,
            icon: "horizontal",
            textStyle: {
              color: "#ffffff",
              fontSize: 12,
            },
            data: legend,
          },
          grid: [
            {
              show: false,
              top: "60%",
              height: "37%",
            },
            {
              show: false,
              top: "10%",
              height: "0%",
            },
            {
              show: false,
              top: "23%",
              height: "37%",
            },
          ],
          yAxis: [
            {
              type: "value",
              inverse: true,
              axisLine: {
                show: false,
              },
              axisTick: {
                show: false,
              },
              position: "top",
              axisLabel: {
                show: true,
                color: textColor,
              },
              splitLine: {
                show: true,
                lineStyle: {
                  color: lineColor,
                },
              },
            },
            {
              gridIndex: 1,
              show: false,
            },
            {
              gridIndex: 2,
              axisLine: {
                show: false,
              },
              axisTick: {
                show: false,
              },
              position: "top",
              axisLabel: {
                show: true,
                color: textColor,
              },
              splitLine: {
                show: true,
                lineStyle: {
                  color: lineColor,
                },
              },
            },
          ],
          xAxis: [
            {
              type: "category",
              inverse: true,
              position: "right",
              axisLine: {
                show: true,
                lineStyle: {
                  color: lineColor,
                },
              },

              axisTick: {
                show: false,
              },
              axisLabel: {
                show: false,
              },
              data: this.echartdata.arrx,
            },
            {
              gridIndex: 1,
              type: "category",
              inverse: true,
              position: "left",
              show: false,
              axisLine: {
                show: false,
              },
              axisTick: {
                show: false,
              },
              axisLabel: {
                show: true,
                padding: [30, 0, 0, 0],
                textStyle: {
                  color: "#ffffff",
                  fontSize: 20,
                },
                align: "center",
              },
              data: this.echartdata.arrx.map(function (value) {
                return {
                  value: value,
                  textStyle: {
                    align: "center",
                  },
                };
              }),
            },
            {
              gridIndex: 2,
              type: "category",
              inverse: true,
              show: false,
              position: "",
              axisLine: {
                show: false,
                lineStyle: {
                  color: lineColor,
                },
              },
              axisTick: {
                show: false,
              },
              axisLabel: {
                show: false,
              },
              data: this.echartdata.arrx,
            },
          ],
          series: [],
        },
        options: [],
      };

      /* this.cunoption.baseOption.timeline.data.push(timeLineData[0]); */
      this.cunoption.options.push({
        series: [
          {
            name: "青年返乡",
            type: "bar",
            barWidth: 10,
            stack: "1",
            itemStyle: {
              normal: {
                color: "#9dbcff",
              },
            },
            label: {
              normal: {
                show: false,
              },
            },
            data: this.echartdata.arr4,
            animationEasing: "elasticOut",
          },
          {
            name: "就业人数",
            type: "bar",
            stack: "2",
            barWidth: 10,
            xAxisIndex: 2,
            yAxisIndex: 2,
            itemStyle: {
              normal: {
                color: "#3675ff",
              },
            },
            label: {
              normal: {
                show: false,
              },
            },
            data: this.echartdata.arr3,
            animationEasing: "elasticOut",
          },
        ],
      });
    },
    /* 
    scolltable() {
      if (this.partysort.length > 5) {
        console.log(123456);
        var css = document.createElement("style");
        css.type = "text/css";
        css.innerHTML = `@keyframes rowup {
            0% {
                transform: translate3d(0, 0, 0);
            }
            100% {
                transform: translate3d(0, -${
                  (this.partysort.length - 5) * 30
                }px, 0);
            }
        }

        .ttt2{
          animation: ${
            2000 * this.partysort.length
          }ms rowup linear infinite normal;
        }`;
        document.head.appendChild(css);
      }
      if (this.scoresort.length > 5) {
        console.log(123456);
        var css2 = document.createElement("style");
        css2.type = "text/css";
        css2.innerHTML = `@keyframes rowup2 {
            0% {
                transform: translate3d(0, 0, 0);
            }
            100% {
                transform: translate3d(0, -${
                  (this.scoresort.length - 5) * 30
                }px, 0);
            }
        }

        .tttt2{
          animation: ${
            2000 * this.scoresort.length
          }ms rowup2 linear infinite normal;
        }`;
        document.head.appendChild(css2);
      }
    }, */
  },
};
</script>

<style lang="scss" scoped>
div {
  box-sizing: border-box;
  line-height: 1;
}
.flex {
  display: flex;
}
.mb-21 {
  margin-bottom: height(21);
}
.mb-49 {
  margin-bottom: height(39);
}
.bg-box {
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  .form-box {
    padding: 0.35rem 0.5rem 0 0.55rem;
    width: 13.1rem;
    height: 9.7rem;
    opacity: 1;
    border-radius: 0.11rem;
    position: relative;
    overflow: hidden;
    background: url(../../assets/jcbj.png) no-repeat center center / cover;
    // background: url($url+'pop1Bj.png') no-repeat center center /cover;
    .delBtn {
      position: absolute;
      width: 0.4rem;
      height: 0.4rem;
      background: url(../../assets/close.png) no-repeat center center / auto
        0.2rem;
      top: 0.15rem;
      right: 0.25rem;
    }
    .f-title {
      font-size: 0.24rem;
      text-align: center;
      color: #85a4e8;
      margin-bottom: height(50);
      font-weight: 500;
    }
    .c-title {
      font-size: 0.18rem;
      font-weight: 500;
      color: #ffffff;
      display: flex;
      align-items: center;

      &:before {
        display: block;
        content: "";
        width: 0.04rem;
        height: 0.16rem;
        background: #66dc95;
        border-radius: 2px;
        margin-right: 0.09rem;
      }
    }
    .video-box {
      width: 100%;
      height: height(675);
      video {
        width: 100%;
        height: 100%;
      }
    }

    .f-charts {
      display: flex;
      flex-wrap: wrap;
      .f-chart {
        flex: 1;
        .left {
          width: 0.54rem;
          padding-right: 0.2rem;
          padding-left: 0.1rem;
          height: height(70);
          color: #fff;
          font-size: 0.12rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: height(190);
          div {
            white-space: nowrap;
            margin: height(6) 0;
          }
        }
        .right {
          margin-top: height(20);
          .partysort {
            width: 1.7rem;
            height: auto;
            &.vl {
              width: 2.6rem;
            }
            .partycate {
              display: flex;
              height: height(30);
              text-align: center;

              &.headx {
                background: #0a2157;
              }
              .p_num {
                width: 33%;
                color: #fff;
                font-size: 0.12rem;
                font-weight: 600;
                line-height: height(30);
              }
              .p_name {
                width: 33%;
                color: #fff;
                font-size: 0.12rem;
                font-weight: 600;
                line-height: height(30);
              }
              .p_score {
                width: 33%;
                color: #fff;
                font-size: 0.12rem;
                font-weight: 600;
                line-height: height(30);
              }
            }
          }
          .item_c {
            vertical-align: top;
            width: 1.49rem;
            height: height(70);
            display: inline-block;
            background: #001025;
            border: 1px solid #142b5f;
            border-radius: 7px;
            margin-right: 0.1rem;
            margin-bottom: height(20);
            &:nth-child(4) {
              margin-bottom: 0;
            }
            &:nth-child(5) {
              margin-bottom: 0;
            }
            &:nth-child(6) {
              margin-bottom: 0;
            }
            padding: height(12) 0;
            .item_c_title {
              font-size: 0.12rem;
              color: #fff;
              text-align: center;
              font-weight: bold;
              margin: 0 auto;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-between;
            }
            .item_c_number {
              font-weight: 700;
              margin-top: height(10);
              font-size: 0.26rem;
              text-align: center;
              color: #66dc95;
            }
          }
        }
        &:nth-of-type(1) {
          margin-right: 0.43rem;
        }
      }
    }
  }
}
.contenta {
  display: flex;
}
.scolltablebox {
  height: height(150);
  overflow: hidden;
}
.cunminechart {
  width: 100%;
  padding-top: height(20);
  height: height(350);
}
.intro-box {
  width: 100%;
  height: height(93);
  overflow: auto;
  font-size: 0.14rem;
  font-weight: 500;
  color: #ffffff;
  line-height: height(24);
  overflow: hidden;
  .intro-box-title {
    font-size: 0.18rem;
    font-weight: bold;
    margin-bottom: height(10);
  }
}
.videohead{
  margin-top: height(28);
  width: 100%;
  height: height(34);
  line-height: height(34);
  margin-bottom: height(30);
  .v_title{
    flex: 1;
    cursor: pointer;
    text-align: center;
    position: relative;
    color: #999999;
    font-weight: 700;
    &.on{
      color: #fff;
      &::after{
      content: "";
      width: 100%;
      height: 1px;
      position: absolute;
      background: rgba(23,69,195,0.70);
      left:50%;
      bottom: 0;
      transform: translateX(-50%);
      }
    }
  }
}
</style>
